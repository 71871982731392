import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function ElectionComparisonElectionsList(props) {
  const ref = useRef();
  const { t } = useTranslation();
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const election = props.electionList
    .filter((item) => item.type === props.type && item.year == props.year)
    .shift();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        setToggleDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className="tour_compare_dropdown col-span-12 flex items-stretch gap-3 relative"
    >
      {props.remove && (
        <button
          onClick={() => props.removeElectionButton(props.id)}
          className="text-gray-700 shadow-md border bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 rounded-md px-4 py-2.5 inline-flex items-center justify-between"
        >
          <FaTrash />
        </button>
      )}
      <button
        onClick={() => setToggleDropdown(!toggleDropdown)}
        className="text-gray-700 font-bold shadow-md border w-full bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 rounded-md px-4 py-2.5 inline-flex items-center justify-between"
      >
        {props.selectedElection
          ? `${props.selectedElection.name} - ${
              props.selectedElection.year
            } - ${props.selectedElectionRound.name}${
              props.displayMunicipalityName()
                ? ` - ${props.displayMunicipalityName()}`
                : ""
            }`
          : `${election.name} - ${election.year} - ${
              election.election_rounds[0]?.name
            }${
              props.displayMunicipalityName()
                ? ` - ${props.displayMunicipalityName()}`
                : ""
            }`}
        {!toggleDropdown ? <FaChevronDown /> : <FaChevronUp />}
      </button>
      <div
        className={`${
          toggleDropdown
            ? "max-h-72 h-fit border border-gray-200"
            : "max-h-0 h-72"
        } absolute transition-max-height duration-500 top-12 mt-1 z-50 w-full bg-white rounded-lg shadow-lg overflow-auto`}
      >
        <ul>
          {props.electionTypes?.map((type) => (
            <li key={type}>
              <p className="font-bold px-4 py-2 bg-red-700 text-white">
                {t(type)}
              </p>
              <ul>
                {props.electionList
                  ?.filter((election) => election.type === type)
                  .map(
                    (item) =>
                      item.election_rounds.length > 0 && (
                        <li key={item.id}>
                          <p className="bg-gray-200 text-red-700 font-bold py-1 pl-4">
                            {item.year}
                          </p>
                          <ul>
                            {item.election_rounds.map((electionRound) => (
                              <li key={electionRound.id}>
                                <div
                                  onClick={() => {
                                    props.electionParam(item);
                                    props.electionRoundParam(electionRound);
                                    setToggleDropdown(false);
                                  }}
                                  className="block cursor-pointer py-2 pl-7 pr-4 text-gray-700 hover:bg-gray-100"
                                >
                                  {item.name} - {electionRound.name}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )
                  )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ElectionComparisonElectionsList;
