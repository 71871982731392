import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef } from "react";

import "./Map.css";

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MyMapComponent(props) {
  const ref = useRef();

  const { municipality, address, addressNumber, location } = props;

  useEffect(() => {
    const DEFAULT_MAP_COORDINATES = { lat: 42, lng: 21.4 };

    const map = new window.google.maps.Map(ref.current, {
      center: DEFAULT_MAP_COORDINATES,
      zoom: 18,
      disableDefaultUI: true,
    });

    const infowindow = new window.google.maps.InfoWindow();

    const request = {
      query: `${location} ${municipality} ${address} ${addressNumber}`,
      fields: ["name", "geometry"],
    };
    const service = new window.google.maps.places.PlacesService(map);

    service.findPlaceFromQuery(request, (results, status) => {
      if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
      ) {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[i]);
        }
        map.setCenter(results[0].geometry.location);
      }
    });

    function createMarker(place) {
      if (!place.geometry || !place.geometry.location) return;

      const marker = new window.google.maps.Marker({
        map,
        position: place.geometry.location,
      });

      window.google.maps.event.addListener(marker, "click", () => {
        infowindow.setContent(place.name || "");
        infowindow.open(map);
      });
    }

    new window.google.maps.Marker({
      position: DEFAULT_MAP_COORDINATES,
      map: map,
    });
  }, [municipality, address, addressNumber, location]);

  return <div ref={ref} className={`map`} />;
}

export default function ReactMap(props) {
  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
      <MyMapComponent
        municipality={props.municipality}
        address={props.address}
        location={props.location}
        addressNumber={props.addressNumber}
      />
    </Wrapper>
  );
}
