import axios from "axios";
import { useState, useCallback, useRef, useEffect } from "react";
import useIsMounted from "./useIsMounted";

export const useHttp = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const activeHttpRequest = useRef([]);

  const mounted = useIsMounted();

  const sendRequest = useCallback(
    async (
      url,
      locale,
      type,
      year = null,
      electionRound = null,
      constituency = null,
      municipality = null,
      pollingStation = null,
      city = null
    ) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequest.current.push(httpAbortCtrl);

      try {
        const { data: responseData, message: errorMessage } = await axios.get(
          url,
          {
            signal: httpAbortCtrl.signal,
            params: {
              locale: locale,
              election_type: type,
              election_year: year,
              election_round_id: electionRound,
              constituency_id: constituency,
              municipality_id: municipality,
              polling_station_id: pollingStation,
              city_id: city,
            },
          }
        );

        activeHttpRequest.current = activeHttpRequest.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );
        if (mounted()) {
          if (errorMessage) {
            setError(new Error(responseData.message));
          }
          setIsLoading(false);
          return responseData;
        }

        return null;
      } catch (err) {
        if (mounted()) {
          setIsLoading(false);
          setError(err.message);
          throw err;
        }
        return null;
      }
    },
    [mounted]
  );

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    return () => {
      activeHttpRequest.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
