import { FaChevronLeft, FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useNavigate } from "react-router-dom";
import { useHttp } from "../../../hooks/useHttp";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ElectionComparisonTable from "./ElectionComparisonTable";

function ElectionComparisonMap(props) {
  const { t } = useTranslation();
  const {
    isLoading: isLoadingAreas,
    sendRequest: sendRequestAreas,
    clearError: clearErrorAreas,
  } = useHttp();
  const {
    isLoading: isLoadingElectionAreas,
    sendRequest: sendRequestElectionAreas,
    clearError: clearErrorElectionAreas,
  } = useHttp();

  const [areas, setAreas] = useState();
  const [electionAreas, setElectionAreas] = useState();
  const [searchAreasStatistic, setSearchAreasStatistic] = useState(() => "");
  const firstRender = useRef(true);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const {
    id,
    city,
    constituency,
    electionRound,
    locale,
    municipality,
    pollingStation,
    type,
    year,
    dataType,
    params,
    items,
    mapBackHandler,
    setMunicipalityPollingStations,
    displayMunicipalityName,
  } = props;

  const AreasUrl = `${process.env.REACT_APP_API_URL}/${dataType}/areas`;
  const electionRoundAreasUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-areas`;

  var data = areas;

  data?.forEach((el) => {
    el.path = el.border_path;
    el.value = el.id;
    el.color = el.party_color ?? "#e1e1e1";
  });

  const options = {
    chart: {
      backgroundColor: "transparent",
    },

    title: {
      text: "",
    },

    legend: {
      enabled: false,
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        theme: {
          fill: "#f1f1f1",
          "stroke-width": 0,
          stroke: "silver",
          r: 8,
          states: {
            hover: {
              fill: "#e5e5e5",
            },
            select: {
              stroke: "#039",
              fill: "red",
            },
          },
        },
        style: { position: "absolute", top: "0", left: "0" },
        verticalAlign: "left",
      },
    },

    tooltip: {
      useHTML: true,
      whiteSpace: "normal",
      backgroundColor: "white",
      borderRadius: 10,
      formatter: function () {
        return [
          '<div class="text-center"><p class="px-3 my-2" style="font-size: 16px;"><u><b>' +
            this.point?.name +
            "</b></u></p></div>",
        ].concat(
          this.point?.political_parties
            ? this.point.political_parties.map(function (politicalParty) {
                if (type === "parliamentary") {
                  return (
                    '<div class="flex items-center px-3 my-2">' +
                    politicalParty.political_party +
                    ": <b>" +
                    Number(politicalParty.votes).toLocaleString("de-DE") +
                    "</b><br></div>"
                  );
                } else if (type === "referendum") {
                  return (
                    '<div class="flex items-center px-3 my-2">' +
                    politicalParty.candidate_name +
                    ": <b>" +
                    Number(politicalParty.votes).toLocaleString("de-DE") +
                    "</b><br></div>"
                  );
                } else {
                  if (type === "local") {
                    if (municipality) {
                      return (
                        '<div class="flex items-center px-3 my-2">' +
                        politicalParty.political_party +
                        " - " +
                        politicalParty.candidate_name +
                        ": <b>" +
                        Number(politicalParty.votes).toLocaleString("de-DE") +
                        "</b><br></div>"
                      );
                    }
                    return (
                      '<div class="flex items-center px-3 my-2">' +
                      politicalParty.political_party +
                      ": <b>" +
                      Number(politicalParty.votes).toLocaleString("de-DE") +
                      "</b><br></div>"
                    );
                  } else {
                    return (
                      '<div class="flex items-center px-3 my-2">' +
                      politicalParty.political_party +
                      " - " +
                      politicalParty.candidate_name +
                      ": <b>" +
                      Number(politicalParty.votes).toLocaleString("de-DE") +
                      "</b><br></div>"
                    );
                  }
                }
              })
            : ""
        );
      },
      shared: true,
    },

    series: [
      {
        type: "map",
        name: "North Macedonia",
        joinBy: "id",
        data: data,
        cursor: "pointer",
        borderColor: "silver",
        states: {
          hover: {
            borderColor: "gray",
          },
        },
        events: {
          click: function (e) {
            if (params.has("municipality" + id)) {
              setMunicipalityPollingStations(e.point.id);
              return;
            } else {
              setMunicipalityPollingStations(null);
            }
            const array = [];

            params.set("electionType" + id, type);
            params.set("electionYear" + id, year);
            params.set("electionRound" + id, electionRound);

            if (type === "parliamentary") {
              if (constituency) {
                params.set("constituency" + id, constituency);
                params.set("municipality" + id, e.point.id);

                items.forEach((item, index) => {
                  const dataType = "dataType" + index + "=" + item["dataType"];
                  const electionType =
                    index === id
                      ? "electionType" +
                        index +
                        "=" +
                        params.get("electionType" + id)
                      : item["electionType"]
                      ? "electionType" + index + "=" + item["electionType"]
                      : null;
                  const electionYear =
                    index === id
                      ? "electionYear" +
                        index +
                        "=" +
                        params.get("electionYear" + id)
                      : item["electionYear"]
                      ? "electionYear" + index + "=" + item["electionYear"]
                      : null;
                  const electionRound =
                    index === id
                      ? "electionRound" +
                        index +
                        "=" +
                        params.get("electionRound" + id)
                      : item["electionRound"]
                      ? "electionRound" + index + "=" + item["electionRound"]
                      : null;
                  const constituency =
                    index === id
                      ? "constituency" +
                        index +
                        "=" +
                        params.get("constituency" + id)
                      : item["constituency"]
                      ? "constituency" + index + "=" + item["constituency"]
                      : null;
                  const municipality =
                    index === id
                      ? "municipality" +
                        index +
                        "=" +
                        params.get("municipality" + id)
                      : item["municipality"]
                      ? "municipality" + index + "=" + item["municipality"]
                      : null;
                  const city =
                    item["city"] && "city" + index + "=" + item["city"];

                  array.push(
                    dataType,
                    electionType,
                    electionYear,
                    electionRound,
                    constituency,
                    municipality,
                    city
                  );
                });
              } else {
                params.set("constituency" + id, e.point.id);

                items.forEach((item, index) => {
                  const dataType = "dataType" + index + "=" + item["dataType"];
                  const electionType =
                    index === id
                      ? "electionType" +
                        index +
                        "=" +
                        params.get("electionType" + id)
                      : item["electionType"]
                      ? "electionType" + index + "=" + item["electionType"]
                      : null;
                  const electionYear =
                    index === id
                      ? "electionYear" +
                        index +
                        "=" +
                        params.get("electionYear" + id)
                      : item["electionYear"]
                      ? "electionYear" + index + "=" + item["electionYear"]
                      : null;
                  const electionRound =
                    index === id
                      ? "electionRound" +
                        index +
                        "=" +
                        params.get("electionRound" + id)
                      : item["electionRound"]
                      ? "electionRound" + index + "=" + item["electionRound"]
                      : null;
                  const constituency =
                    index === id
                      ? "constituency" +
                        index +
                        "=" +
                        params.get("constituency" + id)
                      : item["constituency"]
                      ? "constituency" + index + "=" + item["constituency"]
                      : null;
                  const municipality =
                    item["municipality"] &&
                    "municipality" + index + "=" + item["municipality"];
                  const city =
                    item["city"] && "city" + index + "=" + item["city"];

                  array.push(
                    dataType,
                    electionType,
                    electionYear,
                    electionRound,
                    constituency,
                    municipality,
                    city
                  );
                });
              }
            } else {
              if (city) {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/compare?dataType=" +
                  dataType +
                  "&electionType=" +
                  type +
                  "&electionYear=" +
                  year +
                  "&electionRound=" +
                  electionRound +
                  "&city=" +
                  city +
                  "&municipality=" +
                  e.point.id);
              } else {
                params.set("municipality" + id, e.point.id);

                items.forEach((item, index) => {
                  const dataType = "dataType" + index + "=" + item["dataType"];
                  const electionType =
                    index === id
                      ? "electionType" +
                        index +
                        "=" +
                        params.get("electionType" + id)
                      : item["electionType"]
                      ? "electionType" + index + "=" + item["electionType"]
                      : null;
                  const electionYear =
                    index === id
                      ? "electionYear" +
                        index +
                        "=" +
                        params.get("electionYear" + id)
                      : item["electionYear"]
                      ? "electionYear" + index + "=" + item["electionYear"]
                      : null;
                  const electionRound =
                    index === id
                      ? "electionRound" +
                        index +
                        "=" +
                        params.get("electionRound" + id)
                      : item["electionRound"]
                      ? "electionRound" + index + "=" + item["electionRound"]
                      : null;
                  const constituency =
                    item["constituency"] &&
                    "constituency" + index + "=" + item["constituency"];
                  const municipality =
                    index === id
                      ? "municipality" +
                        index +
                        "=" +
                        params.get("municipality" + id)
                      : item["municipality"]
                      ? "municipality" + index + "=" + item["municipality"]
                      : null;
                  const city =
                    item["city"] && "city" + index + "=" + item["city"];

                  array.push(
                    dataType,
                    electionType,
                    electionYear,
                    electionRound,
                    constituency,
                    constituency,
                    municipality,
                    city
                  );
                });
              }
            }

            const filteredArray = array.filter(function (el) {
              return el != null;
            });

            const baseUrl = "/compare?";
            const fullUrl = baseUrl.concat(
              filteredArray.toString().replaceAll(",", "&")
            );

            navigate(fullUrl);
          },
        },
      },
    ],

    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    const getElectionAreas = async () => {
      const electionAreasData = await sendRequestElectionAreas(
        electionRoundAreasUrl,
        i18n.language,
        params.has("electionType" + id)
          ? params.get("electionType" + id)
          : type,
        params.has("electionYear" + id)
          ? params.get("electionYear" + id)
          : year,
        params.has("electionRound" + id)
          ? params.get("electionRound" + id)
          : electionRound,
        params.has("constituency" + id)
          ? params.get("constituency" + id)
          : constituency,
        params.has("municipality" + id)
          ? params.get("municipality" + id)
          : municipality,
        params.has("pollingStation" + id)
          ? params.get("pollingStation" + id)
          : pollingStation,
        city
      );
      setElectionAreas(electionAreasData);
    };

    getElectionAreas();
    if (firstRender.current) {
      firstRender.current = false;
    }

    return () => {
      clearErrorElectionAreas();
    };
  }, [
    city,
    clearErrorElectionAreas,
    constituency,
    electionRound,
    electionRoundAreasUrl,
    i18n.language,
    id,
    municipality,
    params,
    pollingStation,
    sendRequestElectionAreas,
    type,
    year,
  ]);

  useEffect(() => {
    const getAreas = async () => {
      const areasData = await sendRequestAreas(
        AreasUrl,
        locale,
        type,
        year,
        electionRound,
        constituency,
        municipality,
        null,
        city
      );
      setAreas(areasData);
    };
    getAreas();
    return () => {
      clearErrorAreas();
    };
  }, [
    AreasUrl,
    city,
    clearErrorAreas,
    constituency,
    electionRound,
    locale,
    municipality,
    sendRequestAreas,
    type,
    year,
  ]);

  const filteredAreas = electionAreas?.filter((item) =>
    item.name?.toLowerCase().includes(searchAreasStatistic.toLowerCase())
  );

  return (
    <div className="col-span-12 md:col-span-8">
      {isLoadingAreas && !areas && !municipality && (
        <div className="grid col-span-12 place-items-center h-full ">
          <AiOutlineLoading3Quarters className="animate-spin w-8 h-8" />
        </div>
      )}
      {!isLoadingAreas && areas && !municipality && (
        <div className="grid col-span-12 h-full ">
          <HighchartsReact
            containerProps={{ className: "h-[500px]" }}
            options={options}
            constructorType={"mapChart"}
            highcharts={Highcharts}
          />
        </div>
      )}
      {((props.type === "parliamentary" && props.constituency) ||
        (props.type !== "parliamentary" &&
          props.params.has("municipality" + props.id))) && (
        <div className="grid grid-cols-12 mb-3">
          {displayMunicipalityName() ? (
            <div className="col-span-2 text-gray-700 font-bold">
              {displayMunicipalityName()}
            </div>
          ) : null}
          <div className="col-span-9">
            <div className="items-center text-gray-500">
              <div
                className={`w-full md:w-1/2 xl:w-full h-8 transition-width duration-500 flex items-center rounded-full overflow-hidden bg-gray-100`}
              >
                <FaSearch className="bg-gray-100 mx-3" />
                <input
                  type="text"
                  placeholder={t("search_placeholder")}
                  onChange={(event) => {
                    setSearchAreasStatistic(event.target.value);
                  }}
                  className={`w-full bg-inherit focus:outline-none`}
                />
              </div>
            </div>
          </div>
          <div className="col-span-1 ml-auto">
            <button
              onClick={() => {
                setMunicipalityPollingStations(null);
                mapBackHandler(id);
              }}
              className="bg-gray-100 px-3 py-2 rounded"
            >
              <FaChevronLeft />
            </button>
          </div>
        </div>
      )}
      {!isLoadingElectionAreas && electionAreas && municipality && (
        <ElectionComparisonTable
          data={filteredAreas}
          tableHead={[
            "polling_station",
            "total_registered_voters",
            "voted",
            "total_votes_invalid",
          ]}
        />
      )}
    </div>
  );
}

export default ElectionComparisonMap;
