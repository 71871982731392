import React, { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Container from "../../components/Container";
import LocalePicker from "../../components/LocalePicker";
import "./Navbar.css";

function Navbar(props) {
  const { t, i18n } = useTranslation();
  const [showLinks, setShowLinks] = useState(() => false);

  const location = useLocation();

  const electoralBodiesActiveNavLinkCondition =
    location.pathname === "/electoral-bodies/sec" ||
    location.pathname === "/electoral-bodies/eb-electoral-board" ||
    location.pathname ===
      "/electoral-bodies/mec-municipality-election-commission";

  if (!location.pathname.startsWith('/pollingStations') && localStorage.getItem("municipality")) {
    localStorage.removeItem("municipality")
  }

  return (
    <div className="nav bg-white sticky top-0 w-full z-50 shadow-md">
      <Container>
        <nav className="flex items-center relative">
          <Link to="/election">
            <img
              src={"/sec-logo-" + i18n.language + ".svg"}
              className="order-1 md:order-none w-28 md:w-32"
              alt={t("state_election_commission")}
            />
          </Link>
          <button
            className="order-3 my-2 md:order-none md:hidden text-2xl relative md:ml-auto transition duration-300 p-2 md:my-0 rounded-full cursor-pointer text-red-700 hover:bg-gray-100"
            onClick={() => setShowLinks(!showLinks)}
          >
            {showLinks ? <FaTimes /> : <FaBars />}
          </button>
          <div
            className={
              showLinks
                ? "max-h-screen h-screen md:h-fit md:max-h-fit transition-max-height duration-500 ml-auto md:flex fixed top-14 md:top-0 left-0 md:relative bg-white w-full md:w-auto overflow-hidden shadow-md md:shadow-none"
                : "max-h-0 h-screen md:h-fit md:max-h-fit transition-max-height duration-500 ml-auto overflow-hidden fixed top-14 md:top-0 left-0 md:relative bg-white w-full md:w-auto shadow-md md:shadow-none md:block"
            }
          >
            <ul className="flex flex-col space-y-2 md:space-y-0 md:flex-row font-medium text-red-700 mt-4 md:mt-0">
              <NavLink
                to="/election"
                className="tour_elections_nav_link navLink mx-3 md:mx-2 transition duration-300"
                onClick={() => {
                  setShowLinks(false);
                }}
              >
                <li className="px-4 md:px-0 py-3 font-bold">
                  {t("header.election")}
                </li>
              </NavLink>
              <NavLink
                to="/compare"
                className="tour_compare_nav_link navLink mx-3 md:mx-2 transition duration-300"
                onClick={() => {
                  setShowLinks(false);
                }}
              >
                <li className="px-4 md:px-0 py-3 font-bold">
                  {t("header.election_comparison")}
                </li>
              </NavLink>
              <NavLink
                to="/electoral-bodies/sec"
                className={`tour_electoral_bodies_nav_link navLink mx-3 md:mx-2 transition duration-300 ${
                  electoralBodiesActiveNavLinkCondition && "active"
                }`}
                onClick={() => {
                  setShowLinks(false);
                }}
              >
                <li className="px-4 md:px-0 py-3 font-bold">
                  {t("header.electoral_bodies")}
                </li>
              </NavLink>
              <NavLink
                to="/pollingStations"
                className="tour_polling_stations_nav_link navLink mx-3 md:mx-2 transition duration-300"
                onClick={() => {
                  setShowLinks(false);
                }}
              >
                <li className="px-4 md:px-0 py-3 font-bold">
                  {t("header.polling_stations")}
                </li>
              </NavLink>
              <NavLink
                to="/electoral-roll"
                className="tour_electoral_roll_nav_link navLink mx-3 md:mx-2 transition duration-300"
                onClick={() => {
                  setShowLinks(false);
                }}
              >
                <li className="px-4 md:px-0 py-3 font-bold">
                  {t("header.electoral_roll")}
                </li>
              </NavLink>
            </ul>
          </div>
          <LocalePicker locales={props.locales} />
        </nav>
      </Container>
    </div>
  );
}

export default Navbar;
