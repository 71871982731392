import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {FaMinus, FaPlus} from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ElectionTypeListItem from "./ElectionTypeListItem";

function ElectionTypeItem(props) {
  const [toggleElections, setToggleElections] = useState(true);

  const [listItemHeight, setListItemHeight] = useState(null);
  const listItemRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    setListItemHeight(listItemRef.current.clientHeight);
  }, [props.elections]);

  const onToggleElectionsButton = () => {
    setToggleElections((prevState) => !prevState);
  };

  const elections = props.elections?.filter((item) => item.type === props.type);

  function compare(a, b) {
    if (a.year < b.year) {
      return 1;
    }
    if (a.year > b.year) {
      return -1;
    }
    return 0;
  }

  elections?.sort(compare);

  let isEmpty;
  if (elections?.length === 0) {
    isEmpty = true;
  }else{
    isEmpty = false;
  }

  return (
    <div
      className={`rounded-xl border bg-gray-50 border-gray-100 overflow-hidden w-full my-8 ${isEmpty && "hidden"}`}
    >
      <button
        className="px-4 md:px-5 flex items-center text-gray-500 justify-between w-full py-3 md:py-4 font-medium"
        onClick={onToggleElectionsButton}
      >
        <span className="font-extrabold xs-text-lg sm:text-xl md:text-2xl mr-4">{t(props.type)}</span>
        {toggleElections ? (
          <div className=" top-3 left-4">
            <FaMinus className={"animate-rotateMinus"} />
          </div>
        ) : (
          <div className=" top-3 left-4">
            <FaPlus className={`animate-rotatePlus`} />
          </div>
        )}
      </button>

      {props.loading && (
        <AiOutlineLoading3Quarters className="self-center animate-spin w-8 h-8" />
      )}

      <div
        style={
          !props.loading
            ? { height: toggleElections ? listItemHeight : 0 }
            : { height: listItemHeight }
        }
        className="transition-height px-4 md:px-5 h-fit duration-500"
      >
        <ul
          ref={listItemRef}
          className={`grid gap-4 pt-3 pb-4 md:pb-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3`}
        >
          {elections?.map((item) => {
            return (
              <li key={item.id}>
                <ElectionTypeListItem
                  type={item.type}
                  name={item.name}
                  year={item.year}
                  dataType="results"
                  electionRound={
                    item.election_rounds.length > 0
                      ? item.election_rounds[0].id
                      : null
                  }
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ElectionTypeItem;
