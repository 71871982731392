import { useTranslation } from "react-i18next";
import ElectoralBodiesPieChart from "../misc/ElectoralBodiesPieChart";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

function ElectionBoardListPollinStationList({
  isOpen,
  index,
  pollingStation,
  setPollingStationOpen,
}) {
  const { t } = useTranslation();

  const [shouldRender, setRender] = useState(isOpen);

  useEffect(() => {
    if (isOpen) setRender(true);
  }, [isOpen]);

  const onAnimationEnd = () => {
    if (!isOpen) setRender(false);
  };

  const pluck = (arr, key) => arr.map(i => i[key]);
  const pluckGender = pluck(pollingStation, 'gender')
  const pluckEthnicGroup = pluck(pollingStation, 'ethnic_group')

  const positions = ["president", "vice-president", "member", "deputy-member"];

  return (
    <div className="bg-gray-100 border bg-opacity-70 rounded-xl col-span-12 mx-2">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <button
            onClick={() => {
              if (isOpen) {
                setPollingStationOpen(-1);
                return;
              }
              setPollingStationOpen(index);
            }}
            className="p-3 md:p-4 w-full text-sm md:text-base font-bold text-gray-600 flex items-center justify-between"
          >
            <h1>{`${t("polling_station")} ${index}`}</h1>
            {!isOpen ? (
              <FaChevronDown className="transition duration-500 rotate-0" />
            ) : (
              <FaChevronDown className="transition duration-500 rotate-180" />
            )}
          </button>
        </div>
      </div>
      {shouldRender && (
        <div
          className={`${
            isOpen ? "animate-slideDown" : "animate-slideUp"
          } col-12`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className="overflow-x-scroll border-t-2">
            <table className="bg-gray-50 w-full text-sm md:text-base rounded-xl">
              <thead className="bg-gray-200 bg-opacity-60">
                <tr>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.name")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.gender")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.ethnic_group")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.position")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.appointed")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.active_from")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.active_to")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {positions.map((position) =>
                  pollingStation?.map(
                    (member) =>
                      position === member.position && (
                        <tr key={member.id}>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              <b>{member.name}</b>
                            </p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        <b>{memberReplaced.name}</b>
                                      </p>
                                    )
                                )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {member.gender
                                ? t("electoral-bodies." + member.gender)
                                : ""}
                            </p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        {memberReplaced.gender
                                          ? t(
                                              "electoral-bodies." +
                                                memberReplaced.gender
                                            )
                                          : ""}
                                      </p>
                                    )
                                )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {member.ethnic_group
                                ? t("electoral-bodies." + member.ethnic_group, {
                                    count: 1,
                                  })
                                : ""}
                            </p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        {memberReplaced.ethnic_group
                                          ? t(
                                              "electoral-bodies." +
                                                memberReplaced.ethnic_group
                                            )
                                          : ""}
                                      </p>
                                    )
                                )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {member.position
                                ? t("electoral-bodies." + member.position)
                                : ""}
                            </p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        {memberReplaced.position
                                          ? t(
                                              "electoral-bodies." +
                                                memberReplaced.position
                                            )
                                          : ""}
                                      </p>
                                    )
                                )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>{member.appointed ? member.appointed : ""}</p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        {memberReplaced.appointed
                                          ? memberReplaced.appointed
                                          : ""}
                                      </p>
                                    )
                                )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {new Date(
                                member.mandate_valid_from
                              ).toLocaleDateString("en-GB")}
                            </p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        {new Date(
                                          memberReplaced.mandate_valid_from
                                        ).toLocaleDateString("en-GB")}
                                      </p>
                                    )
                                )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {new Date(
                                member.mandate_valid_to
                              ).toLocaleDateString("en-GB")}
                            </p>
                            {pollingStation.membersReplaced &&
                              Object.values(
                                pollingStation.membersReplaced
                              )?.map((pollingStation) =>
                                pollingStation.members?.map(
                                  (memberReplaced) =>
                                    memberReplaced &&
                                    member.id ===
                                      memberReplaced.replaced_by && (
                                      <p
                                        key={memberReplaced.id}
                                        className="text-gray-400"
                                      >
                                        {new Date(
                                          memberReplaced.mandate_valid_to
                                        ).toLocaleDateString("en-GB")}
                                      </p>
                                    )
                                )
                              )}
                          </td>
                        </tr>
                      )
                  )
                )}
              </tbody>
            </table>
          </div>
          {
            (pluckGender.every(element => element !== '') ||
            pluckEthnicGroup.every(element => element !== '')) &&
              <ElectoralBodiesPieChart
                  data={pollingStation.members}
                  description={t("electoral-bodies.electoral_board_subtitle")}
              />
          }
        </div>
      )}
    </div>
  );
}

export default ElectionBoardListPollinStationList;
