import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../utils/helpers/Table";
import ExportExcel from '../../components/ExportExcel';
import axios from "axios";
import LoaderPageTitle from "../../components/loaders/LoaderPageTitle";
import Dropdown from "../../components/Dropdown";
import ItemsPerPageDropdown from "../../components/ItemsPerPageDropdown";

const PollingStationsTable = (props) => {
    const pollingStationsUrl = `${process.env.REACT_APP_API_URL}/polling-station-information/list`;
    const pollingStationsExportUrl = `${process.env.REACT_APP_API_URL}/polling-station/export`;
    const electoralRollMunicipalitiesListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-municipalities`;
    const electoralRollPopulatedAreasListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-populated-areas`;
    const electoralRollPollingStationsListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-polling-stations`;

    const { t, i18n } = useTranslation();
    const ref = useRef();

    const [pollingStationsData, setPollingStationsData] = useState([]);
    const [populatedArea, setPopulatedArea] = useState('');
    const [populatedAreaList, setPopulatedAreaList] = useState([]);
    const [pollingStation, setPollingStation] = useState('');
    const [pollingStationList, setPollingStationList] = useState([]);
    const [municipalityList, setMunicipalityList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [toggleDropdown, setToggleDropdown] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const municipalitiesResponse = await axios.get(electoralRollMunicipalitiesListUrl, { params: { locale: i18n.language } });
                setMunicipalityList(municipalitiesResponse.data);

                const populatedAreasResponse = await axios.get(electoralRollPopulatedAreasListUrl, { params: { locale: i18n.language, municipality: props.municipalityClicked } });
                setPopulatedAreaList(populatedAreasResponse.data);

                const pollingStationsResponse = await axios.get(electoralRollPollingStationsListUrl, { params: { locale: i18n.language, municipality: props.municipalityClicked, populated_area: populatedArea } });
                setPollingStationList(pollingStationsResponse.data);

                const pollingStationsDataResponse = await axios.get(pollingStationsUrl, { params: { locale: i18n.language, municipality: props.municipalityClicked, populated_area: populatedArea, polling_station: pollingStation, page: currentPage, limit: itemsPerPage } });
                setPollingStationsData(pollingStationsDataResponse.data);
            } catch (error) {
                // Handle errors
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [electoralRollMunicipalitiesListUrl, electoralRollPopulatedAreasListUrl, electoralRollPollingStationsListUrl, pollingStationsUrl, i18n.language, props.municipalityClicked, populatedArea, pollingStation, currentPage, itemsPerPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref.current?.contains(event.target)) {
                setToggleDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    function onSelectMunicipality(item) {
        localStorage.setItem("municipality", item)
        props.setMunicipalityClicked(localStorage.getItem("municipality"));
        setPopulatedArea('');
        setPollingStation('');
    }

    function onSelectPopulatedArea(item) {
        setPopulatedArea(item);
        setPollingStation('')
    }

    function onSelectPollingStation(item) {
        setPollingStation(item);
    }

    function setClearFilter() {
        props.setMunicipalityClicked('');
        localStorage.removeItem("municipality");
        setPopulatedArea('');
        setPollingStation('');
    }

    return (
        <main className="overflow-y-auto bg-white mt-12 mb-8 p-4 rounded-xl">
            <div className="grid grid-cols-12 mb-6 gap-5">
                {!municipalityList.municipalities ?
                    <div className="col-span-4">
                        <LoaderPageTitle/>
                    </div>
                    :
                    <Dropdown
                        titleConcatenate={false}
                        type="municipality"
                        titleSection={t("search_by_municipality")}
                        items={municipalityList.municipalities ?? []}
                        onSelect={onSelectMunicipality}
                        selectedItem={props.municipalityClicked ?? ''}
                    />
                }
                {!populatedAreaList.populatedAreas ?
                    <div className="col-span-4">
                        <LoaderPageTitle/>
                    </div>
                    :
                    <Dropdown
                        titleConcatenate={false}
                        type="populated_area"
                        titleSection={t("search_by_populated_area")}
                        items={populatedAreaList.populatedAreas ?? []}
                        onSelect={onSelectPopulatedArea}
                        selectedItem={populatedArea}
                    />
                }
                {!pollingStationList.pollingStations ?
                    <div className="col-span-4">
                        <LoaderPageTitle/>
                    </div>
                    :
                    <Dropdown
                        titleConcatenate={false}
                        type="polling_station"
                        titleSection={t("search_by_polling_station")}
                        items={pollingStationList.pollingStations ?? []}
                        onSelect={onSelectPollingStation}
                        selectedItem={pollingStation}
                    />
                }
                {(props.municipalityClicked || populatedArea || pollingStation) &&
                    <div className="col-span-12 md:col-span-3 relative md:my-4">
                        <button className="bg-red-700 shadow-md text-white rounded-lg h-10 w-full md:w-10" onClick={setClearFilter}>X</button>
                    </div>
                }
            </div>
            {pollingStationsData && (<div className="flex justify-end gap-4 mb-6">
                <ExportExcel
                    url={pollingStationsExportUrl}
                    locale={i18n.language}
                    type=''
                    year=''
                    electionRound=''
                />
                <ItemsPerPageDropdown
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    toggleDropdown={toggleDropdown}
                    setToggleDropdown={setToggleDropdown}
                />
            </div>)}
            <Table
                data={pollingStationsData}
                itemsPerPage={itemsPerPage}
                page={paginate}
                currentPage={currentPage}
                tableHead={["header.polling_stations", "municipality", "populated_area", "location", "address"]}
                tour
                tourClassName={'tour_polling_stations_first'}
            />
        </main>
    );
};

export default PollingStationsTable;
