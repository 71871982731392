import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import LoaderTable from "../loaders/LoaderTable";
import SectionTitle from "../SectionTitle";
import ReferendumResults from "./ReferendumResults";
import RoundedBox from "../RoundedBox";
import PoliticalParties from "./PoliticalParties";
import { useHttp } from "../../hooks/useHttp";

function PoliticalPartiesResultsSection(props) {
  const { t } = useTranslation();
  const {
    locale,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    dataType,
  } = props;

  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [electionPoliticalParties, setElectionPoliticalParties] = useState();

  const electionRoundPoliticalPartiesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-political-parties`;

  const presidentialElection2019FirstRound = [
      {
        "candidate_name_mk": "Стево Пендаровски",
        "candidate_name_sq": "Stevo Pendarovski",
        "candidate_name_en": "Stevo Pendarovski",
        "type": "person",
        "party_color": "#4f6196",
        "img_path": "https://admin-ep.sec.mk/storage/app/media/political-parties/sdsm.png",
        "total_party_votes": "322581",
        "percentage": "42.81",
        "political_party_name_mk": "СДСМ",
        "political_party_name_sq": "LSDM",
        "political_party_name_en": "SDSM"
      },
      {
        "candidate_name_mk": "Гордана Силјановска-Давкова",
        "candidate_name_sq": "Gordana Siljanovska-Davkova",
        "candidate_name_en": "Gordana Siljanovska-Davkova",
        "type": "person",
        "party_color": "#e74c3c",
        "img_path": "https://admin-ep.sec.mk/storage/app/media/political-parties/vmro-dpmne.png",
        "total_party_votes": "318341",
        "percentage": "42.25",
        "political_party_name_mk": "ВМРО-ДПМНЕ",
        "political_party_name_sq": "VMRO-DPMNE",
        "political_party_name_en": "VMRO-DPMNE"
      },
      {
        "candidate_name_mk": "Блерим Река",
        "candidate_name_sq": "Blerim Reka",
        "candidate_name_en": "Blerim Reka",
        "type": "person",
        "party_color": "#9b59b6",
        "img_path": "https://admin-ep.sec.mk/storage/app/media/political-parties/alijansa-za-albancite-i-besa.png",
        "total_party_votes": "79888",
        "percentage": "10.60",
        "political_party_name_mk": "АЛИЈАНСА ЗА АЛБАНЦИТЕ И ДВИЖЕЊЕ БЕСА",
        "political_party_name_sq": "Aleanca për Shqiptarët dhe Lëvizja BESA",
        "political_party_name_eb": "ALLIANCE FOR ALBANIANS AND THE BESA MOVEMENT"
      }
  ]

  const presidentialElection2019SecondRound = [
      {
        "candidate_name": "Стево Пендаровски",
        "type": "person",
        "party_color": "#4f6196",
        "img_path": "https://admin-ep.sec.mk/storage/app/media/political-parties/sdsm.png",
        "total_party_votes": "435656",
        "percentage": "51.65",
        "political_party_name": "СДСМ"
      },
      {
        "candidate_name": "Гордана Силјановска-Давкова",
        "type": "person",
        "party_color": "#e74c3c",
        "img_path": "https://admin-ep.sec.mk/storage/app/media/political-parties/vmro-dpmne.png",
        "total_party_votes": "377346",
        "percentage": "44.75",
        "political_party_name": "ВМРО-ДПМНЕ"
      }
  ]

  useEffect(() => {
    const getElectionPoliticalParties = async () => {
      const electionPoliticalPartiesData = await sendRequest(
        electionRoundPoliticalPartiesUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        pollingStation ?? null,
        city ?? null
      );
      setElectionPoliticalParties(electionPoliticalPartiesData);
    };
    dataType === "results" && getElectionPoliticalParties();

    return () => {
      clearError();
    };
  }, [
    locale,
    dataType,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    sendRequest,
    electionRoundPoliticalPartiesUrl,
    clearError,
  ]);

  if (dataType === "results" && !electionPoliticalParties && !presidentialElection2019FirstRound && !presidentialElection2019SecondRound) return null;

  if (dataType === "results" && !electionPoliticalParties && presidentialElection2019FirstRound && presidentialElection2019SecondRound) return (
      <div className="mt-8 mb-16">
        {isLoading && !error ? (
            <LoaderTable />
        ) : (
            <div>
              <SectionTitle title={t("results_political_parties")} />
              <RoundedBox>
                <PoliticalParties locale={locale} type={type} items={electionRound === "27" ? presidentialElection2019FirstRound : presidentialElection2019SecondRound} />
              </RoundedBox>
            </div>
        )}
      </div>
  );

  return (
    <div className="mt-8 mb-16">
      {isLoading && !error ? (
        <LoaderTable />
      ) : type === "referendum" ? (
        <div>
          <SectionTitle title={t("results")} />
          <ReferendumResults items={electionPoliticalParties} />
        </div>
      ) : (
        <div>
          <SectionTitle title={t("results_political_parties")} />
          <RoundedBox>
            <PoliticalParties locale={locale} type={type} items={type === "presidential" && year === "2019" ? electionRound === "27" ? presidentialElection2019FirstRound : presidentialElection2019SecondRound : electionPoliticalParties} />
          </RoundedBox>
        </div>
      )}
    </div>
  );
}
export default PoliticalPartiesResultsSection;
