import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FaPeopleArrows, FaChevronDown, FaChevronUp } from "react-icons/fa";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import RoundedBox from "../../components/RoundedBox";
import ElectoralRollTable from "./electoralRollTable/ElectoralRollTable";
import ExportExcel from "../../components/ExportExcel";
import LoaderTable from "../../components/loaders/LoaderTable";
import {Link} from "react-router-dom";
import axios from "axios";
import Dropdown from "../../components/Dropdown";
import LoaderPageTitle from "../../components/loaders/LoaderPageTitle";
import ItemsPerPageDropdown from "../../components/ItemsPerPageDropdown";

function ElectoralRollPage() {
  const { t, i18n } = useTranslation();
  const electoralRollListUrl = `${process.env.REACT_APP_API_URL}/electoral-roll/list`;
  const electoralRollExportUrl = `${process.env.REACT_APP_API_URL}/electoral-roll/export`;
  const electoralRollPollingStationsListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-polling-stations`;
  const electoralRollPopulatedAreasListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-populated-areas`;
  const electoralRollMunicipalitiesListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-municipalities`;

  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [electoralRollItems, setElectoralRollItems] = useState();
  const [pollingStation, setPollingStation] = useState('');
  const [populatedArea, setPopulatedArea] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [pollingStationList, setPollingStationList] = useState([]);
  const [populatedAreaList, setPopulatedAreaList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
      setLoading(true);
      axios
          .get(electoralRollListUrl, {
              params: {
                  locale: i18n.language,
                  municipality: municipality,
                  populated_area: populatedArea,
                  polling_station: pollingStation,
                  page: currentPage,
                  limit: itemsPerPage
              },
          })
          .then((response) => {
              setElectoralRollItems(response.data);
              if (setLoading) {
                  setLoading(false);
              }
          })
          .catch((error) => {
              setLoading(false);
              setElectoralRollItems(null);
          });

      axios
          .get(electoralRollMunicipalitiesListUrl, {
              params: {
                  locale: i18n.language,
              },
          })
          .then((response) => {
              setMunicipalityList(response.data);
          })
          .catch((error) => {
              setMunicipalityList(null);
          });

      axios
          .get(electoralRollPopulatedAreasListUrl, {
              params: {
                  locale: i18n.language,
                  municipality: municipality,
              },
          })
          .then((response) => {
              setPopulatedAreaList(response.data);
          })
          .catch((error) => {
              setPopulatedAreaList(null);
          });
      axios
          .get(electoralRollPollingStationsListUrl, {
              params: {
                  locale: i18n.language,
                  municipality: municipality,
                  populated_area: populatedArea,
              },
          })
          .then((response) => {
              setPollingStationList(response.data);
          })
          .catch((error) => {
              setPollingStationList(null);
          });
  }, [electoralRollListUrl, electoralRollMunicipalitiesListUrl, electoralRollPopulatedAreasListUrl, electoralRollPollingStationsListUrl, i18n.language, pollingStation, populatedArea, municipality, currentPage, itemsPerPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current?.contains(event.target)) {
        setToggleDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const importance_of_data_Label = Array.from(
    new Set(electoralRollItems?.items.map((item) => item.date))
  );

  function onSelectMunicipality(item) {
      setMunicipality(item);
      setPopulatedArea('');
      setPollingStation('');
      setCurrentPage(1);
  }

  function onSelectPopulatedArea(item) {
      setPopulatedArea(item);
      setPollingStation('')
      setCurrentPage(1);
  }

  function onSelectPollingStation(item) {
      setPollingStation(item);
      setCurrentPage(1);
  }

  function setClearFilter() {
      setMunicipality('');
      setPopulatedArea('');
      setPollingStation('');
  }

  const setPage = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Helmet>
        <title>{t("header.electoral_roll")}</title>
      </Helmet>
      <Container>
        <div>
            <PageTitle title={t("header.electoral_roll")}/>
            <div className="grid grid-cols-1 mt-12 md:mt-0 md:my-4">
              <div className="col-span-1 mb-3 flex justify-end">
                <Link to={"/electoral-roll/comparsion"} className="text-white bg-red-700 hover:text-red-700 hover:bg-white border border-red-700 text-2xl font-bold p-2 rounded-md w-full md:w-auto flex items-center justify-center transition duration-300">
                  <FaPeopleArrows />
                  <p className="ml-2 text-sm">{t("header.electoral_roll_comparison")}</p>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-12 my-8 gap-5">
                {!municipalityList.municipalities ?
                    <div className="col-span-3">
                        <LoaderPageTitle/>
                    </div>
                    :
                    <Dropdown
                        titleConcatenate={false}
                        type="municipality"
                        titleSection={t("search_by_municipality")}
                        items={municipalityList.municipalities ?? []}
                        onSelect={onSelectMunicipality}
                        selectedItem={municipality}
                    />
                }
                {!populatedAreaList.populatedAreas ?
                    <div className="col-span-3">
                        <LoaderPageTitle/>
                    </div>
                    :
                    <Dropdown
                        titleConcatenate={false}
                        type="populated_area"
                        titleSection={t("search_by_populated_area")}
                        items={populatedAreaList.populatedAreas ?? []}
                        onSelect={onSelectPopulatedArea}
                        selectedItem={populatedArea}
                    />
                }
                {!pollingStationList.pollingStations ?
                    <div className="col-span-3">
                        <LoaderPageTitle/>
                    </div>
                    :
                    <Dropdown
                        titleConcatenate={false}
                        type="polling_station"
                        titleSection={t("search_by_polling_station")}
                        items={pollingStationList.pollingStations ?? []}
                        onSelect={onSelectPollingStation}
                        selectedItem={pollingStation}
                    />
                }
                {(municipality || populatedArea || pollingStation) &&
                    <div className="col-span-12 md:col-span-3 relative md:my-4">
                        <button className="bg-red-700 shadow-md text-white rounded-lg h-10 w-full md:w-10" onClick={setClearFilter}>X</button>
                    </div>
                }
            </div>

          {loading ?
              <div className="mb-12">
                <LoaderTable />
              </div>
              :
              <div className="mb-14 shadow-xl">
                <RoundedBox tour tourClassName={"tour_electoral_roll_table"}>
                  {electoralRollItems?.electoral_roll?.items && (
                    <div className="flex justify-end mb-4 gap-4">
                        <ExportExcel
                          url={electoralRollExportUrl}
                          locale={i18n.language}
                          type=""
                          year=""
                          electionRound=""
                        />
                        <ItemsPerPageDropdown
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            toggleDropdown={toggleDropdown}
                            setToggleDropdown={setToggleDropdown}
                        />
                    </div>
                  )}
                  <div className="flex items-end justify-between w-full mb-6">
                    <p className="font-extrabold text-gray-700">
                      <span>{t("importance_of_data")} : </span>
                      {importance_of_data_Label.map((dataLabel, index) => (
                        <span key={index}>{dataLabel}</span>
                      ))}
                    </p>
                  </div>
                  <ElectoralRollTable
                    data={electoralRollItems?.items}
                    totalItems={electoralRollItems?.totalItems}
                    totalVoters={electoralRollItems?.total}
                    totalMaleVoters={electoralRollItems?.total_male}
                    totalFemaleVoters={electoralRollItems?.total_female}
                    itemsPerPage={itemsPerPage}
                    page={setPage}
                    currentPage={currentPage}
                    tableHead={[
                      "polling_station",
                      "populated_area",
                      "municipality",
                      "female_voters",
                      "male_voters",
                      "total_registered_voters",
                    ]}
                    tour
                    tourClassName={"tour_electoral_roll_totals"}
                  />
                </RoundedBox>
            </div>
          }
        </div>
      </Container>
    </div>
  );
}

export default ElectoralRollPage;
