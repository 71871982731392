import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import ElectoralBodiesPieChart from "../misc/ElectoralBodiesPieChart";
import { useEffect, useState } from "react";

function MunicipalityElectionCommisionListItem({
  municipality,
  setMunicipalityOpen,
  index,
  municipalityOpen,
  positions,
}) {
  const { t } = useTranslation();

  const isOpen = index === municipalityOpen;
  const [shouldRender, setRender] = useState(isOpen);

  useEffect(() => {
    if (isOpen) setRender(true);
  }, [isOpen]);

  const onAnimationEnd = () => {
    if (!isOpen) setRender(false);
  };

  return (
    <div className="bg-gray-100 border bg-opacity-70 rounded-xl col-span-12">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <button
            onClick={() => {
              if (isOpen) {
                setMunicipalityOpen(-1);
                return;
              }
              setMunicipalityOpen(index);
            }}
            className="p-3 md:p-4 w-full text-sm md:text-base font-bold text-gray-600 flex items-center justify-between"
          >
            {municipality?.municipality_name}
            {!isOpen ? (
              <FaChevronDown className="transition duration-500 rotate-0" />
            ) : (
              <FaChevronDown className="transition duration-500 rotate-180" />
            )}
          </button>
        </div>
      </div>
      {shouldRender && (
        <div
          className={`${
            isOpen ? "animate-slideDown" : "animate-slideUp"
          } col-12`}
          onAnimationEnd={onAnimationEnd}
        >
          <div className="overflow-x-scroll border-t-2">
            <table className="bg-gray-50 w-full text-sm md:text-base rounded-xl">
              <thead className="bg-gray-200 bg-opacity-60">
                <tr>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.name")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.gender")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.ethnic_group")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.position")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.appointed")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.active_from")}
                  </th>
                  <th className="text-left whitespace-nowrap p-4">
                    {t("electoral-bodies.active_to")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {positions.map((position) =>
                  municipality?.members?.map(
                    (member) =>
                      position === member.position && (
                        <tr key={member.id}>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              <b>{member.name}</b>
                            </p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          <b>{memberReplaced.name}</b>
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {member.gender
                                ? t("electoral-bodies." + member.gender)
                                : ""}
                            </p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          {memberReplaced.gender
                                            ? t(
                                                "electoral-bodies." +
                                                  memberReplaced.gender
                                              )
                                            : ""}
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {member.ethnic_group
                                ? t("electoral-bodies." + member.ethnic_group, {
                                    count: 1,
                                  })
                                : ""}
                            </p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          {memberReplaced.ethnic_group
                                            ? t(
                                                "electoral-bodies." +
                                                  memberReplaced.ethnic_group
                                              )
                                            : ""}
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {member.position
                                ? t("electoral-bodies." + member.position)
                                : ""}
                            </p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          {memberReplaced.position
                                            ? t(
                                                "electoral-bodies." +
                                                  memberReplaced.position
                                              )
                                            : ""}
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>{member.appointed ? member.appointed : ""}</p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          {memberReplaced.appointed
                                            ? memberReplaced.appointed
                                            : ""}
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {new Date(
                                member.mandate_valid_from
                              ).toLocaleDateString("en-GB")}
                            </p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          {new Date(
                                            memberReplaced.mandate_valid_from
                                          ).toLocaleDateString("en-GB")}
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                          <td className="whitespace-nowrap p-4">
                            <p>
                              {new Date(
                                member.mandate_valid_to
                              ).toLocaleDateString("en-GB")}
                            </p>
                            {municipality.membersReplaced &&
                              Object.values(municipality.membersReplaced)?.map(
                                (municipality) =>
                                  municipality.members?.map(
                                    (memberReplaced) =>
                                      memberReplaced &&
                                      member.id ===
                                        memberReplaced.replaced_by && (
                                        <p
                                          key={memberReplaced.id}
                                          className="text-gray-400"
                                        >
                                          {new Date(
                                            memberReplaced.mandate_valid_to
                                          ).toLocaleDateString("en-GB")}
                                        </p>
                                      )
                                  )
                              )}
                          </td>
                        </tr>
                      )
                  )
                )}
              </tbody>
            </table>
          </div>
          <ElectoralBodiesPieChart
            data={municipality.members}
            description={t(
              "electoral-bodies.municipality_electoral_board_subtitle"
            )}
          />
        </div>
      )}
    </div>
  );
}

export default MunicipalityElectionCommisionListItem;
