import { useParams } from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from "highcharts-react-official";
import LoaderTable from "../loaders/LoaderTable";
import ItemSeries from 'highcharts/modules/item-series';
import { useHttp } from "../../hooks/useHttp";
import { IoAlertCircleSharp } from "react-icons/io5";

function CandidatesListMandates() {
    ItemSeries(Highcharts);
    let { dataType, type, year, electionRound, constituency, municipality } = useParams();
    const { t, i18n } = useTranslation();

  const { isLoading, error, sendRequest, clearError } = useHttp();


    const [electionPoliticalPartiesListMandates, setElectionPoliticalPartiesListMandates] = useState()

    const electionRoundPoliticalPartiesListMandatesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-political-parties-list-mandates`;

    

    useEffect(() => {
        const getElectionPoliticalPartiesListMandates = async() => {

            const electionPolicitalPartiesMandatesData = await sendRequest(electionRoundPoliticalPartiesListMandatesUrl,i18n.language,
                type,
                year,
                electionRound,
                constituency,
                municipality);
            setElectionPoliticalPartiesListMandates(electionPolicitalPartiesMandatesData);
        }
        dataType === 'results' && getElectionPoliticalPartiesListMandates();
        return ()=>{
            clearError();
        }
    }, [clearError, constituency, dataType, electionRound, electionRoundPoliticalPartiesListMandatesUrl, i18n.language, municipality, sendRequest, type, year])

    if (dataType === 'results' && !electionPoliticalPartiesListMandates) return null;

    const MandatesList = [];

    electionPoliticalPartiesListMandates?.map(
        item => item.political_parties?.map(
            politicalPartyItem => politicalPartyItem.candidates_list?.map(
                candidateItem => MandatesList.push(
                    {
                        'name': politicalPartyItem.political_party_name,
                        'candidate_name': candidateItem.name,
                        'constituency': item.constituency_name,
                        'color': politicalPartyItem.party_color,
                        'y': 1,
                    }
                )
            )
        )
    )
  

  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  MandatesList.sort(compare);

  const mandatesListOptions = {
    chart: {
      type: "item",
      height: 80 + "%",
    },

    title: {
      text: "",
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      headerFormat: "",
      pointFormat:
        "<b>{point.constituency}</b><br><br>{point.candidate_name} | {point.name}",
      borderRadius: 20,
      backgroundColor: "white",
      padding: 15,
      style: {
        fontSize: 16,
      },
    },

    series: [
      {
        data: MandatesList,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },

        center: ["50%", "75%"],
        size: "130%",
        startAngle: -100,
        endAngle: 100,
        innerSize: "20%",
      },
    ],
  };

  return isLoading && !error ? (
    <LoaderTable />
  ) : (
    <div className="mt-2 mb-16 relative">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <div
            className={`overflow-hidden bg-white border-gray-100 rounded-box rounded-lg`}
          >
            <p className="block flex items-center font-bold justify-between w-full p-4 text-gray-500 text-left">
              {type === "parliamentary"
                ? t("parliamentary_mandates")
                : t("municipal_council")}
            </p>
            <div>
              <div
                className={`grid grid-cols-12 gap-x-3 border-t px-4 transition-opacity duration-500`}
              >
                <div className="col-span-12 md:col-start-4 md:col-span-6">
                  {MandatesList.length === 0 && <div className="col-span-12 text-center py-8">
                    <IoAlertCircleSharp className="scale-[2.5] mx-auto" />
                    <p className="mt-4 text-stone-500">{t("no-data-found")}</p>
                  </div>}
                  {MandatesList.length !== 0 && <HighchartsReact
                    options={mandatesListOptions}
                    highcharts={Highcharts}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidatesListMandates;
