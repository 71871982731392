import Container from "../../components/Container";
import { useTranslation } from 'react-i18next';
import {FaFacebookF, FaTwitter, FaYoutube} from "react-icons/fa";

function Footer() {
    const { t } = useTranslation();

    return(
        <div className="bg-black bg-opacity-80 text-white text-xs py-10 md:py-16">
            <Container>
                <div className="grid grid-cols-12 gap-2 md:gap-5 items-center">
                    <div className="col-span-12 md:col-span-3">
                        <p className="mb-0 text-center md:text-left">
                            { t('footer.copyright') + " " + new Date().getFullYear() + " | " + t('state_election_commission') }
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-7 my-6 md:my-0">
                        <p className="mb-0 text-center md:text-left">{ t('footer.description') }</p>
                    </div>
                    <div className="col-span-6 col-start-4 sm:col-span-4 sm:col-start-5 md:col-span-2">
                        <div className="grid grid-cols-3 gap-3">
                            <div className="col-span-1 mx-auto">
                                <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank">
                                    <div className="w-9 h-9 rounded-full flex bg-blue-600 hover:bg-blue-500 transition duration-300">
                                        <FaFacebookF className="m-auto text-lg" />
                                    </div>
                                </a>
                            </div>
                            <div className="col-span-1 mx-auto">
                                <a href={process.env.REACT_APP_TWITTER_URL} target="_blank">
                                    <div className="w-9 h-9 rounded-full flex bg-cyan-400 hover:bg-cyan-300 transition duration-300">
                                        <FaTwitter className="m-auto text-lg" />
                                    </div>
                                </a>
                            </div>
                            <div className="col-span-1 mx-auto">
                                <a href={process.env.REACT_APP_YOUTUBE_URL} target="_blank">
                                    <div className="w-9 h-9 rounded-full flex bg-red-700 hover:bg-red-600 transition duration-300">
                                        <FaYoutube className="m-auto text-lg" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer;