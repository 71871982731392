import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ElectionComparisonTable = ({ data, tableHead}) => {
    const { t } = useTranslation();

    return (
        <div className="border overflow-hidden bg-white shadow-md rounded-xl relative">
            <div style={{overflowY: 'auto', height: '456px'}}>
            <div className="grid grid-cols-4 text-sm sticky top-0 left-0 right-0 bg-gray-100 px-4 py-3">
                {tableHead.map((item, index) => {
                    return (
                        <div key={index} className="col-span-1">
                            {t(item)}
                        </div>
                    )
                })}
            </div>
            {data?.map((el, index) => {
                return (
                    <div key={index} className="grid grid-cols-4 text-sm px-4 py-2 border-b">
                        <div className="col-span-1">{el.name}</div>
                        <div className="col-span-1">{el.total_registered_voters}</div>
                        <div className="col-span-1">{el.total_votes_valid}</div>
                        <div className="col-span-1">{el.total_votes_invalid}</div>
                    </div>
                )
            })}
            </div>
        </div>

    );
};

export default ElectionComparisonTable;
