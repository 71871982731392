import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import LoaderTable from "../loaders/LoaderTable";
import SectionTitle from "../SectionTitle";
import RoundedBox from "../RoundedBox";
import AreasStatistics from "./AreasStatistics";
import MobileAreasStatistics from "./mobile/MobileAreasStatistics";
import { useHttp } from "../../hooks/useHttp";
import { useParams } from "react-router";

function AreasStatisticsSection(props) {
  const { t } = useTranslation();

  const {
    city,
    constituency,
    dataType,
    electionRound,
    locale,
    municipality,
    pollingStation,
    type,
    year,
    titles,
    link,
  } = props;

  const { isLoading, error, sendRequest, clearError } = useHttp();

  const [electionAreasStatistic, setElectionAreasStatistic] = useState();

  const electionRoundAreasStatisticsUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-areas`;

  const { constituency: constituencyParams, municipality: municipalityParams } =
    useParams();
  let title = t("");
  switch (type) {
    case ("local" || "presidental" || "referendum"):
      if (municipalityParams) {
        title = t("polling_stations_areas_statistic");
        break;
      }
      title = t("municipalities_areas_statistic");
      break;
    case "parliamentary":
      if (constituencyParams) {
        if (municipalityParams) {
          title = t("polling_stations_areas_statistic");
          break;
        }
        title = t("municipalities_areas_statistic");
        break;
      }
      title = t("constituencies_areas_statistic");
      break;
    default:
      break;
  }


  useEffect(() => {
    const getElectionAreasStatistics = async () => {
      const electionRoundAreas = await sendRequest(
        electionRoundAreasStatisticsUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        pollingStation ?? null,
        city ?? null
      );
      setElectionAreasStatistic(electionRoundAreas);
    };
    (dataType === "results" || dataType === "turnout") &&
      getElectionAreasStatistics();
    return () => {
      clearError();
    };
  }, [
    clearError,
    electionRoundAreasStatisticsUrl,
    city,
    constituency,
    dataType,
    electionRound,
    locale,
    municipality,
    pollingStation,
    type,
    year,
    sendRequest,
  ]);

  if (
    (dataType === "results" || dataType === "turnout") &&
    !electionAreasStatistic
  )
    return null;

  return (
    <div className="mt-8 mb-16">
      {isLoading && !error ? (
        <LoaderTable />
      ) : (
        <div>
          <SectionTitle title={title} />
          <div className="hidden md:block">
            <RoundedBox>
              <AreasStatistics
                dataType={dataType}
                type={type}
                titles={titles}
                items={electionAreasStatistic}
                constituency={constituency ?? null}
                municipality={municipality ?? null}
                pollingStation={pollingStation ?? null}
                city={city ?? null}
                link={link}
              />
            </RoundedBox>
          </div>
          <div className="md:hidden">
            <MobileAreasStatistics
              dataType={dataType}
              type={type}
              titles={titles}
              items={electionAreasStatistic}
              constituency={constituency ?? null}
              municipality={municipality ?? null}
              pollingStation={pollingStation ?? null}
              city={city ?? null}
              link={link}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AreasStatisticsSection;
