import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import axios from "axios";
import LoaderPageTitle from "../../../components/loaders/LoaderPageTitle";
import ElectoralBodiesFilter from "../misc/ElectoralBodiesFilter";
import LoaderTable from "../../../components/loaders/LoaderTable";
import MunicipalityElectionCommisionListItem from "./MunicipalityElectionCommissionListItem";
import RoundedBox from "../../../components/RoundedBox";
import Pagination from "../../../components/Pagination";
import SearchBar from "../../../components/SearchBar";
import ElectoralBodiesContentHeader from "../misc/ElectoralBodiesContentHeader";
import useSearchQueryParam from "../../../hooks/useSearchQueryParam";
import { getSearchQueryParam } from "../../../utils/searchQuery";
import { useLocation } from "react-router";
import { IoAlertCircleSharp } from "react-icons/io5";

function MunicipalityElectionCommisionList(props) {
  const { t, i18n } = useTranslation();

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [loading, setLoading] = useState(true);
  const [electoralBodiesList, setElectoralBodiesList] = useState([]);
  const [mandatesList, setMandatesList] = useState(null);
  const [mandateIndex, setMandateIndex] = useState(null);
  const [municipalityOpen, setMunicipalityOpen] = useState(-1);

  const location = useLocation();

  const [filterSearch, setFilterSearch] = useState(
    getSearchQueryParam(location.search, "keyword") ?? ""
  );

  useSearchQueryParam("keyword", filterSearch);

  const debouncedFilterSearch = useRef(
    debounce(onFilterInputChange, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedFilterSearch.cancel();
    };
  }, [debouncedFilterSearch]);

  function onFilterInputChange(event) {
    setFilterSearch(event.target.value);
  }

  const electoralBodiesUrl = `${process.env.REACT_APP_API_URL}/electoral-bodies`;
  const mandatesUrl = `${process.env.REACT_APP_API_URL}/mandates`;

  useEffect(() => {
    setLoading(true);
    axios
        .get(mandatesUrl, {
          params: {
            members_type: "mec-municipality-election-commission",
          },
        })
        .then((response) => {
          setMandatesList(response.data);
        })
        .catch((error) => {
          setMandatesList(null);
        });

    axios
      .get(electoralBodiesUrl, {
        params: {
          locale: i18n.language,
          members_type: "mec-municipality-election-commission",
          keyword: filterSearch.toLowerCase(),
          mandate: mandateIndex,
          page: currentPage,
        },
      })
      .then((response) => {
        setElectoralBodiesList(response.data);
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setElectoralBodiesList(null);
      });
  }, [
    i18n.language,
    mandatesUrl,
    electoralBodiesUrl,
    filterSearch,
    mandateIndex,
    currentPage,
  ]);

  const setMandateHandler = (mandate) => {
    setMandateIndex(mandate);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const ElectoralBodiesExportUrl = `${process.env.REACT_APP_API_URL}/electoral-bodies-export?locale=${i18n.language}&members_type=mec-municipality-election-commission&mandate_id=${electoralBodiesList?.mandate_id}`;

  const positions = ["president", "vice-president", "member", "deputy-member"];

  return (
    <div className="grid grid-cols-12 gap-5">
      <div className="col-span-12">
        <ElectoralBodiesFilter
            mandatesList={mandatesList}
            setMandate={setMandateHandler}
        />
      </div>
      <div className="col-span-12 mt-5">
        <SearchBar
            defaultValue={filterSearch}
            className="mr-auto ml-0 tour_electoral_bodies_eb_electoral_board_search_bar"
            searchEvent={debouncedFilterSearch}
        />
      </div>
      <div className="col-span-12 mb-16 rounded">
        <RoundedBox>
          {loading ? (
            <LoaderTable />
          ) : electoralBodiesList?.mandate_list_items?.totalItems > 0 ? (
            <>
              <ElectoralBodiesContentHeader
                mandateValidFrom={electoralBodiesList?.mandate_valid_from}
                mandateValidTo={electoralBodiesList?.mandate_valid_to}
                exportURL={ElectoralBodiesExportUrl}
              />
              <div className="flex flex-col gap-4">
                {electoralBodiesList?.mandate_list_items.items.map(
                  (municipality, index) => {
                    return (
                      <MunicipalityElectionCommisionListItem
                        index={index}
                        municipality={municipality}
                        positions={positions}
                        municipalityOpen={municipalityOpen}
                        setMunicipalityOpen={setMunicipalityOpen}
                        key={index}
                      />
                    );
                  }
                )}
              </div>
            </>
          ) : (
            <div className="col-span-12 text-center py-8">
              <IoAlertCircleSharp className="scale-[2.5] mx-auto" />
              <p className="mt-4 text-stone-500">{t("no-data-found")}</p>
            </div>
          )}

          {electoralBodiesList ? (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={electoralBodiesList.mandate_list_items?.totalItems}
              currentPage={currentPage}
              paginate={paginate}
              indexFirst={indexOfFirstItem}
              indexLast={indexOfLastItem}
            />
          ) : null}
        </RoundedBox>
      </div>
    </div>
  );
}

export default MunicipalityElectionCommisionList;
