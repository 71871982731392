import './RoundedBox.css';

function RoundedBox(props) {
    return(
        <div className="grid grid-cols-1">
            <div className={`${props.tour ? props.tourClassName : null} col-span-1 rounded-box rounded-xl p-5`}>
                {props.children}
            </div>
        </div>
    )
}

export default RoundedBox;