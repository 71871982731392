import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../../hooks/useHttp";

function PieChartResults(props) {
  const { t } = useTranslation();
  const {
    locale,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    dataType,
  } = props;

  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [electionPoliticalParties, setElectionPoliticalParties] = useState();
  const electionRoundPoliticalPartiesUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-political-parties`;

  useEffect(() => {
    const getElectionPoliticalParties = async () => {
      const electionPoliticalPartiesData = await sendRequest(
        electionRoundPoliticalPartiesUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        pollingStation ?? null,
        city ?? null
      );
      setElectionPoliticalParties(electionPoliticalPartiesData);
    };

    dataType === "results" && getElectionPoliticalParties();

    return () => {
      clearError();
    };
  }, [
    locale,
    dataType,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    sendRequest,
    electionRoundPoliticalPartiesUrl,
    clearError,
  ]);

  // Once we got the detailed data for presidential election 2019 we can remove this code
  const presidentialElection2019FirstRound = [
    {
      candidate_name: "Стево Пендаровски",
      type: "person",
      party_color: "#4f6196",
      total_party_votes: "322581",
      percentage: "42.81",
      political_party_name: "СДСМ",
    },
    {
      candidate_name: "Гордана Силјановска-Давкова",
      type: "person",
      party_color: "#e74c3c",
      total_party_votes: "318341",
      percentage: "42.25",
      political_party_name: "ВМРО-ДПМНЕ",
    },
    {
      candidate_name: "Блерим Река",
      type: "person",
      party_color: "#9b59b6",
      total_party_votes: "79888",
      percentage: "10.60",
      political_party_name: "АЛИЈАНСА ЗА АЛБАНЦИТЕ И ДВИЖЕЊЕ БЕСА",
    },
  ];

  const presidentialElection2019SecondRound = [
    {
      candidate_name: "Стево Пендаровски",
      type: "person",
      party_color: "#4f6196",
      total_party_votes: "435656",
      percentage: "51.65",
      political_party_name: "СДСМ",
    },
    {
      candidate_name: "Гордана Силјановска-Давкова",
      type: "person",
      party_color: "#e74c3c",
      total_party_votes: "377346",
      percentage: "44.75",
      political_party_name: "ВМРО-ДПМНЕ",
    },
  ];

  if (
    dataType === "results" &&
    !electionPoliticalParties &&
    !presidentialElection2019FirstRound &&
    !presidentialElection2019SecondRound
  )
    return null;

  electionPoliticalParties?.forEach((el) => {
    if (type === "referendum") {
      el.name = el.candidate_name;
      el.color = el.candidate_name === t("yes") ? "#10b981" : "#ef4444";
    } else {
      if (type === "presidential") {
        el.name = el.political_party_name + " - " + el.candidate_name;
      } else if (type === "local") {
        if (municipality) {
          el.name = el.political_party_name + " - " + el.candidate_name;
        } else {
          el.name = el.political_party_name;
        }
      } else {
        el.name = el.political_party_name;
      }
      el.color = el.party_color;
    }
    el.y = Number(el.percentage);
  });

  // Once we got the detailed data for presidential election 2019 we can remove this code
  presidentialElection2019FirstRound.forEach((el) => {
    el.name = el.political_party_name + " - " + el.candidate_name;
    el.color = el.party_color;
    el.y = Number(el.percentage);
  });

  presidentialElection2019SecondRound.forEach((el) => {
    el.name = el.political_party_name + " - " + el.candidate_name;
    el.color = el.party_color;
    el.y = Number(el.percentage);
  });

  const pieOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      backgroundColor: "transparent",
    },
    title: {
      text: t("pie_chart_results_title"),
      style: {
        fontWeight: "bold",
        fontSize: 25,
      },
    },
    tooltip: {
      headerFormat: "",
      borderRadius: 10,
      backgroundColor: "white",
      pointFormat: "{point.name}: <b>{point.y:.2f}%</b>",
      shadow: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.y:.2f} %",
        },
      },
    },
    series: [
      {
        data:
          type === "presidential" && year === "2019"
            ? electionRound === "27"
              ? presidentialElection2019FirstRound
              : presidentialElection2019SecondRound
            : electionPoliticalParties,
        innerSize: "50%",
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return isLoading && !error ? (
    ""
  ) : (
    <HighchartsReact options={pieOptions} highcharts={Highcharts} />
  );
}

export default PieChartResults;
