import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function SearchBar({ className, searchEvent, defaultValue }) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center text-gray-500 mb-5">
      <div
        className={`md:w-1/2 lg:w-1/3 h-8 w-full transition-width duration-500 flex items-center rounded-full overflow-hidden ml-auto bg-gray-100 ${className}`}
      >
        <FaSearch className="bg-gray-100 mx-3" />
        <input
          type="text"
          placeholder={t("search_placeholder")}
          defaultValue={defaultValue}
          onChange={searchEvent}
          className={`w-full bg-inherit focus:outline-none`}
        />
      </div>
    </div>
  );
}

export default SearchBar;
