import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../../hooks/useHttp";

function PieChartTurnout(props) {
  const { t } = useTranslation();

  const {
    locale,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    dataType,
  } = props;

  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [turnoutStatistic, setTurnoutStatistic] = useState();
  const TurnoutStatisticUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-statistic`;

  useEffect(() => {
    const getTurnoutStatistic = async () => {
      const turnoutStatiscticsData = await sendRequest(
        TurnoutStatisticUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        pollingStation ?? null,
        city ?? null
      );
      setTurnoutStatistic(turnoutStatiscticsData);
    };
    dataType === "turnout" && getTurnoutStatistic();
    return () => {
      clearError();
    };
  }, [
    locale,
    dataType,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    sendRequest,
    TurnoutStatisticUrl,
    clearError,
  ]);

  // Once we got the detailed data for presidential election 2019 we can remove this code
  const statisticColumnsValuesFirstRound = {
    total_registered_voters: 1808131,
    total_turnout: 753520,
    total_votes_valid: 720810,
    total_votes_invalid: 32696,
    total_municipalities: 80,
    total_polling_stations: 3480,
    total_turnout_percentage: 41.67,
  };

  const statisticColumnsValuesSecondRound = {
    total_registered_voters: 1808131,
    total_turnout: 843508,
    total_votes_valid: 813101,
    total_votes_invalid: 30407,
    total_municipalities: 80,
    total_polling_stations: 3480,
    total_turnout_percentage: 46.65,
  };

  if (
    !turnoutStatistic &&
    !statisticColumnsValuesFirstRound &&
    !statisticColumnsValuesSecondRound
  )
    return null;

  var data = [];

  const voted = {
    name: t("voted"),
    total_turnout:
      !turnoutStatistic &&
      statisticColumnsValuesFirstRound &&
      statisticColumnsValuesSecondRound
        ? electionRound === "27"
          ? statisticColumnsValuesFirstRound["total_turnout"]
          : statisticColumnsValuesSecondRound["total_turnout"]
        : turnoutStatistic.total_turnout,
    y:
      !turnoutStatistic &&
      statisticColumnsValuesFirstRound &&
      statisticColumnsValuesSecondRound
        ? electionRound === "27"
          ? statisticColumnsValuesFirstRound["total_turnout_percentage"]
          : statisticColumnsValuesSecondRound["total_turnout_percentage"]
        : turnoutStatistic.total_turnout_percentage,
    color: "#4f7388",
  };

  const notVoted = {
    name: t("not_voted"),
    total_turnout:
      !turnoutStatistic &&
      statisticColumnsValuesFirstRound &&
      statisticColumnsValuesSecondRound
        ? electionRound === "27"
          ? statisticColumnsValuesFirstRound["total_registered_voters"] -
            statisticColumnsValuesFirstRound["total_turnout"]
          : statisticColumnsValuesSecondRound["total_registered_voters"] -
            statisticColumnsValuesSecondRound["total_turnout"]
        : turnoutStatistic.total_registered_voters -
          turnoutStatistic.total_turnout,
    y:
      100 - !turnoutStatistic &&
      statisticColumnsValuesFirstRound &&
      statisticColumnsValuesSecondRound
        ? electionRound === "27"
          ? statisticColumnsValuesFirstRound["total_turnout_percentage"]
          : statisticColumnsValuesSecondRound["total_turnout_percentage"]
        : turnoutStatistic.total_turnout_percentage,
    color: "#e3e3e3",
  };

  data.push(voted);
  data.push(notVoted);

  const pieOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      backgroundColor: "transparent",
    },
    title: {
      text: t("pie_chart_turnout_title"),

      align: "center",
      style: {
        fontWeight: "bold",
        fontSize: 25,
      },
      y: 7,
    },
    tooltip: {
      useHTML: true,
      whiteSpace: "normal",
      backgroundColor: "white",
      borderRadius: 10,
      formatter: function () {
        return (
          '<div class="text-center"><p class="px-3 my-2" style="font-size: 16px;"><u><b>' +
          this.point.name +
          "</b></u></p></div>" +
          '<div class="flex items-center px-3 my-2">' +
          t("percentage") +
          ": <b>" +
          this.point.y +
          "%</b><br></div>" +
          '<div class="flex items-center px-3 my-2">' +
          t("number_of_voters") +
          ": <b>" +
          this.point.total_turnout.toLocaleString("de-DE") +
          "</b><br></div>"
        );
      },
      shared: true,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: data,
        innerSize: "50%",
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return isLoading && !error ? (
    ""
  ) : (
    <HighchartsReact options={pieOptions} highcharts={Highcharts} />
  );
}

export default PieChartTurnout;
