import { HashLink as Link } from "react-router-hash-link";
import React from "react";
import {FaArrowRight} from "react-icons/fa";

function ElectionTypeListItem(props) {
  return (
    <Link
      to={
        props.electionRound
          ? "/election/" +
            props.dataType +
            "/electionType/" +
            props.type +
            "/electionYear/" +
            props.year +
            "/electionRound/" +
            props.electionRound
          : "/"
      }
    >
      <div className="relative hover:shadow-xl bg-white shadow text-red-700 px-3 py-2 overflow-hidden sm:px-4 border border-gray-100 sm:py-3 rounded-md hover:bg-white hover:text-red-700 transition duration-300">
          <p className="font-extralight text-xl md:text-3xl">{props.year}</p>
          <p className="font-bold text-sm mr-12">{props.name}</p>
          <div className="absolute bg-red-700 h-28 w-14 md:h-32 md:w-16 z-10 rounded-l-full right-0 top-1/2 transform -translate-y-1/2"/>
          <FaArrowRight className={"absolute top-1/2 z-20 transform text-white -translate-y-1/2 right-3 md:right-4 text-xl md:text-2xl"} />
      </div>
    </Link>
  );
}

export default ElectionTypeListItem;
