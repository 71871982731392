import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { useTranslation } from "react-i18next";

const ElectoralBodiesPieChart = (props) => {
  const { data: pieChartData, description } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  if (!pieChartData) {
    return null;
  }

  let data;
  data = Object.values(pieChartData);

  const genderData = Array.isArray(data) && data?.map((data) => data.gender);
  const ethnicicityData =
    Array.isArray(data) && data?.map((data) => data.ethnic_group);

  let genderPercent = {};
  let ethnicicityPercent = {};

  Array.from(genderData).forEach((el) => {
    if (!genderPercent[el]) {
      return (genderPercent[el] =
        (genderData.filter((ob) => ob === el).length * 100) /
        genderData.length);
    }
  });

  Array.from(ethnicicityData).forEach((el) => {
    if (!ethnicicityPercent[el]) {
      return (ethnicicityPercent[el] =
        (ethnicicityData.filter((ob) => ob === el).length * 100) /
        ethnicicityData.length);
    }
  });

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: `${t("electoral-bodies.gender")} / ${t(
        "electoral-bodies.ethnic_group"
      )}`,
      style: {
        fontWeight: "bold",
        fontSize: 25,
      },
    },
    subtitle: {
      text: `${description}`,
      style: {
        fontWeight: "lighter",
        fontSize: 12,
      },
    },
    tooltip: {
      pointFormat: "",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        name: t("electoral-bodies.gender"),
        colorByPoint: true,
        size: width <= 768 ? "50%" : "80%",
        center: ["25%", "50%"],
        data: [
          {
            name: t("electoral-bodies.male"),
            y: genderPercent.male,
            sliced: true,
            selected: true,
            color: " #2980B9",
          },
          {
            name: t("electoral-bodies.female"),
            y: genderPercent.female,
            sliced: true,
            selected: true,
            color: "#EF4444",
          },
        ],
      },
      {
        name: t("electoral-bodies.ethnic_group"),
        colorByPoint: true,
        size: width <= 768 ? "50%" : "80%",
        center: ["75%", "50%"],
        data: [
          {
            name: t("electoral-bodies.macedonian", {
              count: ethnicicityPercent.macedonian,
            }),
            y: ethnicicityPercent.macedonian,
            sliced: true,
            selected: true,
          },
          {
            name: t("electoral-bodies.albanian", {
              count: ethnicicityPercent.albanian,
            }),
            y: ethnicicityPercent.albanian,
            sliced: true,
            selected: true,
          },
          {
            name: t("electoral-bodies.turkish", {
              count: ethnicicityPercent.turkish,
            }),
            y: ethnicicityPercent.turkish,
            sliced: true,
            selected: true,
          },
          {
            name: t("electoral-bodies.serbian", {
              count: ethnicicityPercent.serbian,
            }),
            y: ethnicicityPercent.serbian,
            sliced: true,
            selected: true,
          },
          {
            name: t("electoral-bodies.bosniak", {
              count: ethnicicityPercent.bosniak,
            }),
            y: ethnicicityPercent.bosniak,
            sliced: true,
            selected: true,
          },
          {
            name: t("electoral-bodies.vlach", {
              count: ethnicicityPercent.vlach,
            }),
            y: ethnicicityPercent.vlach,
            sliced: true,
            selected: true,
          },
          {
            name: t("electoral-bodies.romani", {
              count: ethnicicityPercent.romani,
            }),
            y: ethnicicityPercent.romani,
            sliced: true,
            selected: true,
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div className="grid grid-cols-12 mt-4">
      <div className="col-span-12">
        <HighchartsReact options={options} highcharts={Highcharts} />
      </div>
    </div>
  );
};

export default ElectoralBodiesPieChart;
