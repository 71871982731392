import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function Survey (props) {

    const {t} = useTranslation();
    const [showSurvey, setShowSurvey] = useState(false);
    const [showSurveyBtn, setShowSurveyBtn] = useState(true);
    const [showSurveyContent, setShowSurveyContent] = useState(true);
    const [surveySubmitted, setSurveySubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [surveyData, setSurveyData] = useState({});
    const [surveyErrors, setSurveyErrors] = useState({});
    const [surveySuggestion, setSurveySuggestion] = useState('');

    const SURVEY_URL = `${process.env.REACT_APP_API_URL}/survey/store`;
    const COOKIE_NAME = 'survey_saved';

    const qa = {
        'questions': [
            {
                'question': t('survey.question_compare'),
                'id': 'compareRadio',
                'name': 'grade_comparison',
            },
            {
                'question': t('survey.question_info_members'),
                'id': 'infoMembersRadio',
                'name': 'grade_info_members',
            },
            {
                'question': t('survey.question_info_elections'),
                'id': 'infoElectionsRadio',
                'name': 'grade_info_elections',
            },
            {
                'question': t('survey.question_info_results_and_participants'),
                'id': 'infoResultsAndParticipantsRadio',
                'name': 'grade_info_results_and_participants',
            },
            {
                'question': t('survey.question_design'),
                'id': 'designRadio',
                'name': 'grade_design',
            },
            {
                'question': t('survey.question_functionality'),
                'id': 'functionalityRadio',
                'name': 'grade_functionality',
            }
        ],
        'answers': [
            t('survey.answer_1'),
            t('survey.answer_2'),
            t('survey.answer_3'),
            t('survey.answer_4'),
            t('survey.answer_5')
        ],
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const isSurveyAvailable = !Boolean(getCookie(COOKIE_NAME));

    useEffect(() => {
        setShowSurveyBtn(isSurveyAvailable);
    }, []);

    if (!isSurveyAvailable) return <></>;

    return (
        <>
            {showSurveyBtn ?
                <>
                    <button
                        className='surveyBtn'
                        onClick={() => {
                            setShowSurvey(true);
                            setShowSurveyBtn(false);
                        }}
                        data-label={t('survey.title')}
                    >
                        <img src={'/check.png'}/>
                    </button>
                </>
                : null
            }
            <div
                className={`fixed hidden inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full ${showSurvey ? 'modal-visible' : ''}`}
                id='survey-modal'
            >
                <div
                    className="relative top-20 mx-auto p-5 border w-1/3 shadow-lg rounded-md bg-white"
                >
                    {showSurveyContent ?
                        <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {t('survey.title')}
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => {
                                    setShowSurvey(false);
                                    setShowSurveyBtn(true);
                                }}
                            >
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div> : null
                    }
                    <div className="mt-3">
                        <div className={`${surveySubmitted ? 'block' : 'hidden'} text-center`}>
                            <div
                                className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                            >
                                <svg
                                    className="h-6 w-6 text-green-600"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-3">
                                {t('survey.success')}
                            </h3>
                        </div>
                        {showSurveyContent ?
                            <form className="survey-content">
                                {qa.questions.map((question, questionIndex) => {
                                    return (
                                        <div key={questionIndex} id={"step" + questionIndex} className="step required">
                                            <div className="mt-2 px-7 py-3">
                                                <label className="text-gray-700">{questionIndex + 1 + '. ' + question.question}</label>
                                                {qa.answers.map((answer, answerIndex) => {
                                                    return (
                                                        <label
                                                            key={questionIndex + '-' + answerIndex}
                                                            htmlFor={question.id + answerIndex}
                                                            className="inline-flex items-center p-3 w-full bg-gray-100 mt-3"
                                                        >
                                                            <input
                                                                id={question.id + answerIndex}
                                                                name={question.name}
                                                                type="radio"
                                                                value={answerIndex + 1}
                                                                onChange={() => setSurveyData({
                                                                    ...surveyData,
                                                                    [question.name]: answerIndex + 1
                                                                })}
                                                            />
                                                            <span className="ml-2">{answer}</span>
                                                        </label>
                                                    )
                                                })}
                                                {surveyErrors.hasOwnProperty(question.name) ?
                                                    surveyErrors[question.name].map((errorMessage, errorMessageIndex) => {
                                                        return (
                                                            <span key={errorMessageIndex} className="validation text-red-700 block mt-2">{errorMessage}</span>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div id="step3" className="step">
                                    <div className="mt-2 px-7 py-3">
                                        <label className="text-gray-700" htmlFor="suggestions">{qa.questions.length + 1}. {t('survey.suggestions')}:</label>
                                        <textarea
                                            name="suggestions"
                                            id="suggestions"
                                            className="mt-1 block w-full rounded-md border-black shadow-sm"
                                            rows="5"
                                            onChange={(e) => {
                                                setSurveySuggestion(e.target.value)
                                                setSurveyData({...surveyData, suggestion: e.target.value});
                                            }}
                                            value={surveySuggestion}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center px-7 py-3">
                                    <button
                                        type="button"
                                        id="cancel-btn"
                                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-base font-bold rounded-md shadow-sm focus:outline-none"
                                        onClick={() => {
                                            setShowSurvey(false);
                                            setShowSurveyBtn(true);
                                        }}
                                    >
                                        {t('survey.cancel')}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={isLoading}
                                        className="px-4 py-2 bg-gray-800 hover:bg-gray-900 text-white text-base font-bold rounded-md shadow-sm focus:outline-none"
                                        onClick={async () => {

                                            setIsLoading(true);

                                            fetch(SURVEY_URL, {
                                                method: 'post',
                                                body: JSON.stringify(surveyData)
                                            }).then(response => {
                                                setIsLoading(false);
                                                response.json().then(responseJson => {
                                                    if (response.status === 400) {
                                                        setSurveyErrors(responseJson);
                                                        return;
                                                    }
                                                    setSurveySubmitted(true);
                                                    setShowSurveyContent(false);
                                                    setTimeout(() => {
                                                        setShowSurvey(false);
                                                    }, 2000);
                                                    setShowSurveyBtn(false);

                                                    let cookieDate = new Date;
                                                    cookieDate.setFullYear(cookieDate.getFullYear() + 5);
                                                    document.cookie = 'survey_saved=true; expires=' + cookieDate.toUTCString();
                                                })
                                            }).catch(error => {
                                                setIsLoading(false);
                                            })
                                        }}
                                    >
                                        {isLoading ?
                                            <svg role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#000000"/>
                                            </svg>
                                        : null }
                                        {t('survey.submit')}
                                    </button>
                                </div>
                            </form>
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Survey;