import React, {useState} from "react";
import Container from "../../components/Container";

import PollingStationsTable from "./PollingStationsTable";
import PollingStationsMap from "./PollingStationsMap";
import PageTitle from "../../components/PageTitle";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

const PollingStations = (props) => {
  const { t } = useTranslation();

  const [municipalityClicked, setMunicipalityClicked] = useState(localStorage.getItem("municipality") ?? '');

  return (
      <Container>
          <React.Fragment>
              <Helmet>
                  <title>{t("header.polling_stations")} {municipalityClicked ? ' - ' + municipalityClicked : ''}</title>
              </Helmet>
              <PageTitle title={`${t("header.polling_stations")}  ${municipalityClicked ? ' - ' + municipalityClicked : ''}`}/>
              <PollingStationsMap
                  municipalityClicked={municipalityClicked}
                  setMunicipalityClicked={setMunicipalityClicked}
              />
              <PollingStationsTable
                  municipalityClicked={municipalityClicked}
                  setMunicipalityClicked={setMunicipalityClicked}
               />
          </React.Fragment>
      </Container>
  );
};

export default PollingStations;
