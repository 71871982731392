import React from "react";
import { useTranslation } from "react-i18next";
import RoundedBox from "../../../components/RoundedBox";

const ElectionComparisonOtherTab = ({ statisticsData }) => {
  const { t } = useTranslation();

  return (
    <div className="col-span-12 text-gray-900 text-sm ">
      <RoundedBox>
        <div className="flex justify-between mb-2">
          <div className="font-bold">{t("total_registered_voters")}</div>
          <div className="font-normal">
            {statisticsData.total_registered_voters.toLocaleString("de-DE")}
          </div>
        </div>
        <div className="flex justify-between mb-2">
          <div className="font-bold">{t("total_turnout")}</div>
          <div className="font-normal">
            {statisticsData.total_turnout.toLocaleString("de-DE")}
          </div>
        </div>
        <div className="flex justify-between mb-2">
          <div className="font-bold">{t("total_votes_valid")}</div>
          <div className="font-normal">
            {statisticsData.total_votes_valid.toLocaleString("de-DE")}
          </div>
        </div>
        <div className="flex justify-between mb-2">
          <div className="font-bold">{t("total_votes_invalid")}</div>
          <div className="font-normal">
            {statisticsData.total_votes_invalid.toLocaleString("de-DE")}
          </div>
        </div>
        {statisticsData.total_municipalities && (
          <div className="flex justify-between mb-2">
            <div className="font-bold">{t("total_municipalities")}</div>
            <div className="font-normal">
              {statisticsData.total_municipalities.toLocaleString("de-DE")}
            </div>
          </div>
        )}
        <div className="flex justify-between mb-2">
          <div className="font-bold">{t("total_polling_stations")}</div>
          <div className="font-normal">
            {statisticsData.total_polling_stations.toLocaleString("de-DE")}
          </div>
        </div>
      </RoundedBox>
    </div>
  );
};

export default ElectionComparisonOtherTab;
