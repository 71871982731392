import { useTranslation } from "react-i18next";
import { FaFileExcel } from "react-icons/fa";
import { apiHandleDownloadExcel } from "../../../utils/apiRequest";

function ElectoralBodiesContentHeader({
  mandateValidFrom,
  mandateValidTo,
  exportURL,
}) {
  const { t } = useTranslation();

  const mandateHeader =
    mandateValidFrom && mandateValidTo
      ? `${new Date(mandateValidFrom).toLocaleDateString("en-GB")} - ${new Date(
          mandateValidTo
        ).toLocaleDateString("en-GB")}`
      : "";

  return (
    <div className="grid grid-cols-12 gap-5 items-center mb-8">
      <div className="col-span-12 md:col-span-6">
        <p className="font-bold flex items-center justify-between text-sm md:text-base w-full text-gray-600">
          {mandateHeader}
        </p>
      </div>
      <div className="col-span-12 md:col-span-6">
        <button
          className="text-sm md:text-base text-green-600 ml-auto hover:text-white bg-white hover:bg-green-600 transition duration-300 font-medium border border-green-600 px-2 py-1 w-full md:w-fit flex items-center justify-center rounded"
          onClick={() =>
            apiHandleDownloadExcel(
              exportURL,
              t("electoral-bodies.mec-municipality-election-commission") +
                "-" +
                mandateValidFrom +
                "-" +
                mandateValidTo
            )
          }
        >
          <FaFileExcel className="text-xl mr-2" />
          {t("export_excel")}
        </button>
      </div>
    </div>
  );
}

export default ElectoralBodiesContentHeader;
