import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../../hooks/useHttp";
import ElectionComparisonOtherTab from "./ElectionComparisonOtherTab";
import ElectionComparisonResultTab from "./ElectionComparisonResultTab";

function ElectionComparisonPoliticalParties(props) {
  const [statisticsData, setStatisticsData] = useState();
  const [tabOpened, setTabOpened] = useState("results");
  const { isLoading, error, sendRequest, clearError } = useHttp();
  const { i18n, t } = useTranslation();

  const {
    id,
    params,
    selectedElection,
    selectedElectionRound,
    electionPoliticalParties,
    electionList,
  } = props;

  const electionRoundAreasStatisticsUrl = `${process.env.REACT_APP_API_URL}/results/election-round-statistic`;

  useEffect(() => {
    const getElectionStatistics = async () => {
      const electionStatisticsData = await sendRequest(
        electionRoundAreasStatisticsUrl,
        i18n.language,
        selectedElection ? selectedElection.type : electionList[2].type,
        selectedElection ? selectedElection.year : electionList[2].year,
        selectedElection
          ? selectedElectionRound.id
          : electionList[2].election_rounds[0].id,
        params.get("constituency" + id),
        params.get("municipality" + id),
        params.get("pollingStation" + id)
      );
      setStatisticsData(electionStatisticsData);
    };

    getElectionStatistics();

    return () => {
      clearError();
    };
  }, [
    clearError,
    electionList,
    electionRoundAreasStatisticsUrl,
    i18n.language,
    id,
    params,
    selectedElection,
    selectedElectionRound,
    sendRequest,
  ]);

  const tabs = ["results", "other"];

  return (
    <div
      style={{ height: "500px" }}
      className="col-span-12 md:col-span-4 overflow-auto border bg-white shadow-md text-sm rounded-xl p-4"
    >
      <div className="grid grid-cols-12">
        <ul className="flex gap-[1rem] h-[2.2rem] col-span-12 mb-4">
          {tabs.map((tab) => {
            return (
              <li
                className={`grow basis-0 cursor-pointer ${
                  tab === tabOpened
                    ? "bg-red-700 text-white rounded"
                    : "bg-gray-700 bg-opacity-10 text-red-700 "
                } font-bold text-center py-2 rounded-xl`}
                key={tab}
                onClick={() => {
                  setTabOpened(tab);
                }}
              >
                {t(tab)}
              </li>
            );
          })}
        </ul>
        {tabOpened === "results" && (
          <ElectionComparisonResultTab
            selectedElection={selectedElection}
            electionPoliticalParties={electionPoliticalParties}
          />
        )}
        {tabOpened === "other" && !isLoading && !error && (
          <ElectionComparisonOtherTab statisticsData={statisticsData} />
        )}
      </div>
    </div>
  );
}

export default ElectionComparisonPoliticalParties;
