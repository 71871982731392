import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoaderTable from "../../../components/loaders/LoaderTable";
import ElectoralBodiesPieChart from "../misc/ElectoralBodiesPieChart";
import LoaderPageTitle from "../../../components/loaders/LoaderPageTitle";
import ElectoralBodiesFilter from "../misc/ElectoralBodiesFilter";
import RoundedBox from "../../../components/RoundedBox";
import ElectoralBodiesContentHeader from "../misc/ElectoralBodiesContentHeader";

function StateElectionCommision(props) {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [electoralBodiesList, setElectoralBodiesList] = useState(null);
  const [mandatesList, setMandatesList] = useState(null);
  const [mandateIndex, setMandateIndex] = useState(null);

  const positions = ["president", "vice-president", "member", "deputy-member"];

  const electoralBodiesUrl = `${process.env.REACT_APP_API_URL}/electoral-bodies`;
  const mandatesUrl = `${process.env.REACT_APP_API_URL}/mandates`;

  useEffect(() => {
    setLoading(true);
    axios
        .get(mandatesUrl, {
          params: {
            members_type: "sec",
          },
        })
        .then((response) => {
          setMandatesList(response.data);
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setMandatesList(null);
        });

    axios
      .get(electoralBodiesUrl, {
        params: {
          locale: i18n.language,
          members_type: "sec",
          mandate: mandateIndex,
        },
      })
      .then((response) => {
        setElectoralBodiesList(response.data);
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setElectoralBodiesList(null);
      });
  }, [i18n.language, mandatesUrl, electoralBodiesUrl, mandateIndex]);

  const setMandateHandler = (mandate) => {
    setMandateIndex(mandate);
  };

  const ElectoralBodiesExportUrl = `${process.env.REACT_APP_API_URL}/electoral-bodies-export?locale=${i18n.language}&members_type=sec&mandate_id=${electoralBodiesList?.mandate_id}`;

  return (
    <div className="pt-5">
      <div className="col-span-12">
        {loading ? (
            <LoaderPageTitle />
        ) : mandatesList ? (
            <ElectoralBodiesFilter
                mandatesList={mandatesList}
                setMandate={setMandateHandler}
            />
        ) : null}
      </div>

      <div className="col-span-12 mt-8 mb-16 rounded">
        <RoundedBox>
      {loading ? (
        <LoaderTable />
      ) : (
        <>
          <ElectoralBodiesContentHeader
              mandateValidFrom={electoralBodiesList?.mandate_valid_from}
              mandateValidTo={electoralBodiesList?.mandate_valid_to}
              exportURL={ElectoralBodiesExportUrl}
          />
        <table className="border bg-gray-50 w-full text-sm md:text-base overflow-hidden rounded-xl">
          <thead className="bg-gray-200 bg-opacity-60">
            <tr>
              <th className="text-left whitespace-nowrap p-4">
                {t("electoral-bodies.name")}
              </th>
              <th className="text-left whitespace-nowrap p-4">
                {t("electoral-bodies.gender")}
              </th>
              <th className="text-left whitespace-nowrap p-4">
                {t("electoral-bodies.ethnic_group")}
              </th>
              <th className="text-left whitespace-nowrap p-4">
                {t("electoral-bodies.position")}
              </th>
              <th className="text-left whitespace-nowrap p-4">
                {t("electoral-bodies.active_from")}
              </th>
              <th className="text-left whitespace-nowrap p-4">
                {t("electoral-bodies.active_to")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {positions?.map((position) =>
              electoralBodiesList?.members?.map(
                (member) =>
                  position === member.position && (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap p-4">
                        <p>
                          <b>{member.name}</b>
                        </p>
                        {electoralBodiesList.members_replaced?.map(
                          (memberReplaced) =>
                            memberReplaced &&
                            member.id === memberReplaced.replaced_by && (
                              <p
                                key={memberReplaced.id}
                                className="text-gray-400"
                              >
                                <b>{memberReplaced.name}</b>
                              </p>
                            )
                        )}
                      </td>
                      <td className="whitespace-nowrap p-4">
                        <p>
                          {member.gender
                            ? t("electoral-bodies." + member.gender)
                            : ""}
                        </p>
                        {electoralBodiesList.members_replaced?.map(
                          (memberReplaced) =>
                            memberReplaced &&
                            member.id === memberReplaced.replaced_by && (
                              <p
                                key={memberReplaced.id}
                                className="text-gray-400"
                              >
                                {member.gender
                                  ? t("electoral-bodies." + member.gender)
                                  : ""}
                              </p>
                            )
                        )}
                      </td>
                      <td className="whitespace-nowrap p-4">
                        <p>
                          {member.ethnic_group
                            ? t("electoral-bodies." + member.ethnic_group, {
                                count: 1,
                              })
                            : ""}
                        </p>
                        {electoralBodiesList.members_replaced?.map(
                          (memberReplaced) =>
                            memberReplaced &&
                            member.id === memberReplaced.replaced_by && (
                              <p
                                key={memberReplaced.id}
                                className="text-gray-400"
                              >
                                {memberReplaced.ethnic_group
                                  ? t(
                                      "electoral-bodies." +
                                        memberReplaced.ethnic_group,
                                      { count: 1 }
                                    )
                                  : ""}
                              </p>
                            )
                        )}
                      </td>
                      <td className="whitespace-nowrap p-4">
                        <p>
                          {member.position
                            ? t("electoral-bodies." + member.position)
                            : ""}
                        </p>
                        {electoralBodiesList.members_replaced?.map(
                          (memberReplaced) =>
                            memberReplaced &&
                            member.id === memberReplaced.replaced_by && (
                              <p
                                key={memberReplaced.id}
                                className="text-gray-400"
                              >
                                {memberReplaced
                                  ? t(
                                      "electoral-bodies." +
                                        memberReplaced.position
                                    )
                                  : ""}
                              </p>
                            )
                        )}
                      </td>
                      <td className="whitespace-nowrap p-4">
                        <p>
                          {new Date(
                            member.mandate_valid_from
                          ).toLocaleDateString("en-GB")}
                        </p>
                        {electoralBodiesList.members_replaced?.map(
                          (memberReplaced) =>
                            memberReplaced &&
                            member.id === memberReplaced.replaced_by && (
                              <p
                                key={memberReplaced.id}
                                className="text-gray-400"
                              >
                                {new Date(
                                  memberReplaced.mandate_valid_from
                                ).toLocaleDateString("en-GB")}
                              </p>
                            )
                        )}
                      </td>
                      <td className="whitespace-nowrap p-4">
                        <p>
                          {new Date(member.mandate_valid_to).toLocaleDateString(
                            "en-GB"
                          )}
                        </p>
                        {electoralBodiesList.members_replaced?.map(
                          (memberReplaced) =>
                            memberReplaced &&
                            member.id === memberReplaced.replaced_by && (
                              <p
                                key={memberReplaced.id}
                                className="text-gray-400"
                              >
                                {new Date(
                                  memberReplaced.mandate_valid_to
                                ).toLocaleDateString("en-GB")}
                              </p>
                            )
                        )}
                      </td>
                    </tr>
                  )
              )
            )}
          </tbody>
        </table>
        </>
      )}
          </RoundedBox>
      </div>
      {electoralBodiesList && (
        <ElectoralBodiesPieChart
          data={electoralBodiesList.members}
          description={t("electoral-bodies.state_election_commission_subtitle")}
        />
      )}
    </div>
  );
}

export default StateElectionCommision;
