import axios from 'axios'

export const apiRequest = (
    // setError,
    url,
    setState = null,
    setLoading = null,
    locale,
    type,
    year = null,
    electionRound = null,
    constituency = null,
    municipality = null,
    pollingStation = null,
    city = null

) => {
    if (setLoading) {
        setLoading(true);
    }
    axios.get( url, {
        params: {
            locale: locale,
            election_type: type,
            election_year: year,
            election_round_id: electionRound,
            constituency_id: constituency,
            municipality_id: municipality,
            polling_station_id: pollingStation,
            city_id: city
        },
    }).then(response => {
        setState(response.data)
        if (setLoading) {
            setLoading(false)
        }
    }).catch((error) => {
        setLoading(false)
        // if (error.response.status !== 202) {
        //     console.log(error.response.data.error.message);
        // }
    })
}

export const apiHandleDownload = (fileUrl, filename) => {
    axios.get(fileUrl, {
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename+".pdf");
        document.body.appendChild(link);
        link.click();
    })
}

export const apiHandleDownloadExcel = (fileUrl, filename) => {
    axios.get(fileUrl, {
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename+".xls");
        document.body.appendChild(link);
        link.click();
    })
}