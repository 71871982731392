import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import { Outlet } from "react-router";
import ElectoralBodiesTabs from "./tabs/ElectoralBodiesTabs";

function ElectoralBodies() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t("header.electoral_bodies")}</title>
      </Helmet>
      <Container>
        <PageTitle title={t("header.electoral_bodies")} />
        <ElectoralBodiesTabs />
        <Outlet />
      </Container>
    </div>
  );
}

export default ElectoralBodies;
