import React, { useState } from "react";
import MapChartResults from "./charts/results/MapChartResults";
import PieChartResults from "./charts/results/PieChartResults";
import ColumnChart from "./charts/ColumnChart";
import { FaChartBar, FaChartPie } from "react-icons/fa";
import MapChartTurnout from "./charts/turnout/MapChartTurnout";
import PieChartTurnout from "./charts/turnout/PieChartTurnout";
import { useTranslation } from "react-i18next";

function Charts(props) {
  const { t } = useTranslation();
  const [pieChartShow, setPieChartShow] = useState(() => true);
  const [columnChartShow, setColumnChartShow] = useState(
    () => !!props.municipality
  );

  return (
    <div>
      <div className="grid grid-cols-1 mt-8">
        <div className="col-span-1 mb-3 flex justify-end">
          <button
            className={`${
              pieChartShow
                ? "text-white bg-red-700"
                : "bg-gray-200 text-gray-700 hover:text-black"
            } tour_election_statistics text-2xl px-2 py-1 rounded-md w-full sm:w-auto flex items-center justify-center transition duration-300`}
            onClick={() => setPieChartShow(!pieChartShow)}
          >
            <FaChartPie />
            <p className="ml-2 text-sm">{t("pie_chart_results_title")}</p>
          </button>
        </div>
        <div className="col-span-1 flex justify-end">
          {props.dataType === "results" && (
            <button
              className={`${
                columnChartShow
                  ? "text-white bg-red-700"
                  : "bg-gray-200 text-gray-700 hover:text-black"
              } text-2xl px-2 py-1 rounded-md w-full sm:w-auto flex items-center justify-center  transition duration-300`}
              onClick={() => setColumnChartShow(!columnChartShow)}
            >
              <FaChartBar />
              <p className="ml-2 text-sm">{t("column_chart_results_title")}</p>
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5 mt-8 mb-16 items-center">
        {!props.municipality && props.dataType === "results" && (
          <div
            className={`${
              columnChartShow || pieChartShow
                ? "md:col-span-7"
                : "md:col-start-3 md:col-span-8"
            } col-span-12`}
          >
            <MapChartResults
              locale={props.locale}
              dataType={props.dataType}
              type={props.type}
              year={props.year}
              electionRound={props.electionRound}
              constituency={props.constituency ?? null}
              municipality={props.municipality ?? null}
              city={props.city ?? null}
            />
          </div>
        )}
        {!props.pollingStation && props.dataType === "turnout" && (
          <div
            className={`${
              pieChartShow ? "md:col-span-7" : "md:col-start-3 md:col-span-8"
            } col-span-12`}
          >
            <MapChartTurnout
              locale={props.locale}
              dataType={props.dataType}
              type={props.type}
              year={props.year}
              electionRound={props.electionRound}
              constituency={props.constituency ?? null}
              municipality={props.municipality ?? null}
              city={props.city ?? null}
            />
          </div>
        )}
        {(columnChartShow || pieChartShow) && (
          <div
            className={`${
              columnChartShow || pieChartShow
                ? props.municipality
                  ? ""
                  : "md:col-span-5"
                : ""
            } col-span-12`}
          >
            <div className="grid grid-cols-12 gap-8 items-center">
              {props.dataType === "results" && pieChartShow && (
                <div
                  className={`${
                    props.municipality
                      ? "col-span-12 md:col-span-6"
                      : "col-span-12"
                  }`}
                >
                  <PieChartResults
                    locale={props.locale}
                    dataType={props.dataType}
                    type={props.type}
                    year={props.year}
                    electionRound={props.electionRound}
                    constituency={props.constituency ?? null}
                    municipality={props.municipality ?? null}
                    pollingStation={props.pollingStation ?? null}
                    city={props.city ?? null}
                  />
                </div>
              )}
              {props.dataType === "turnout" && pieChartShow && (
                <div
                  className={`${
                    props.municipality
                      ? "col-span-12 md:col-span-6"
                      : "col-span-12"
                  }`}
                >
                  <PieChartTurnout
                    locale={props.locale}
                    dataType={props.dataType}
                    type={props.type}
                    year={props.year}
                    electionRound={props.electionRound}
                    constituency={props.constituency ?? null}
                    municipality={props.municipality ?? null}
                    pollingStation={props.pollingStation ?? null}
                    city={props.city ?? null}
                  />
                </div>
              )}
              {props.dataType === "results" && columnChartShow && (
                <div
                  className={`${
                    props.municipality
                      ? "col-span-12 md:col-span-6"
                      : "col-span-12 mt-10 md:mt-0"
                  }`}
                >
                  <ColumnChart
                    locale={props.locale}
                    dataType={props.dataType}
                    type={props.type}
                    year={props.year}
                    electionRound={props.electionRound}
                    constituency={props.constituency ?? null}
                    municipality={props.municipality ?? null}
                    pollingStation={props.pollingStation ?? null}
                    city={props.city ?? null}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Charts;
