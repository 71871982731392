import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Charts from "../../components/Charts";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import ExportExcel from "../../components/ExportExcel";
import SectionTitle from "../../components/SectionTitle";
import LoaderTabs from "../../components/loaders/LoaderTabs";
import LoaderButton from "../../components/loaders/LoaderButton";
import ElectionRoundsTabs from "../../components/ElectionRoundsTabs";
import LoaderPageTitle from "../../components/loaders/LoaderPageTitle";
import CapitalElectionLink from "../../components/CapitalElectionLink";
import SecondaryNavbarTabs from "../../layouts/navbar/SecondaryNavbarTabs";
import StatisticsSection from "../../components/statistics/StatisticsSection";
import CandidatesListMandates from "../../components/candidatesLists/CandidatesListMandates";
import PoliticalPartiesResultsSection from "../../components/politicalParties/PoliticalPartiesResultsSection";
import { useHttp } from "../../hooks/useHttp";

function ElectionCapitalMunicipalityPollingStation() {
  let {
    dataType,
    type,
    year,
    electionRound,
    city,
    municipality,
    pollingStation,
  } = useParams();
  const { t, i18n } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [election, setElection] = useState();
  const [
    electionAreasMunicipalityStatistic,
    setElectionAreasMunicipalityStatistic,
  ] = useState();

  const electionUrl = `${process.env.REACT_APP_API_URL}/election`;
  const electionRoundAreasMunicipalityStatisticsUrl = `${process.env.REACT_APP_API_URL}/results/election-round-areas`;
  const electionExportUrl = `${process.env.REACT_APP_API_URL}/election-export`;

  useEffect(() => {
    const getElection = async () => {
      const electionData = await sendRequest(
        electionUrl,
        i18n.language,
        type,
        year
      );
      setElection(electionData);
    };
    const getElectionAreasMunicipalityStatistics = async () => {
      const electionAreasMunicipalityStatisticsData = await sendRequest(
        electionRoundAreasMunicipalityStatisticsUrl,
        i18n.language,
        type,
        year,
        electionRound,
        null,
        municipality,
        null,
        city
      );
      setElectionAreasMunicipalityStatistic(
        electionAreasMunicipalityStatisticsData
      );
    };

    getElection();
    getElectionAreasMunicipalityStatistics();

    return () => {
      clearError();
    };
  }, [
    i18n.language,
    dataType,
    type,
    year,
    electionRound,
    city,
    municipality,
    pollingStation,
    sendRequest,
    electionUrl,
    electionRoundAreasMunicipalityStatisticsUrl,
    clearError,
  ]);

  if (!election) return null;
  if (!electionAreasMunicipalityStatistic) return null;

  const statisticColumnsTitles = [
    "total_registered_voters",
    "total_turnout",
    "total_votes_valid",
    "total_votes_invalid",
    dataType === "turnout" && "total_turnout_percentage",
  ];

  const filteredArea = electionAreasMunicipalityStatistic.filter(
    (area) => area.id === pollingStation
  );

  return (
    <div>
      <Helmet>
        <title>
          {filteredArea[0] &&
            `${t(dataType)} - ${election.name} - ${year} - ${t(
              "capital_city"
            )} - ${filteredArea[0].name}`}
        </title>
      </Helmet>
      <SecondaryNavbarTabs
        link={`electionType/${type}/electionYear/${year}/electionRound/${electionRound}/city/${city}/municipality/${municipality}/pollingStation/${pollingStation}`}
        dataType={dataType}
        type={type}
        year={year}
        electionRound={electionRound}
      />
      <CapitalElectionLink
        dataType={dataType}
        type={type}
        year={year}
        electionRound={electionRound}
      />
      <Container>
        {isLoading && !error ? (
          <LoaderPageTitle />
        ) : (
          <PageTitle
            title={
              filteredArea[0] &&
              `${election.name} - ${year} - ${t("capital_city")} - ${
                filteredArea[0].name
              }`
            }
            link={`/election/${dataType}/electionType/${type}/electionYear/${year}/electionRound/${electionRound}/city/${city}/municipality/${municipality}`}
          />
        )}

        {type !== "parliamentary" && type !== "referendum" ? (
          isLoading && !error ? (
            <LoaderTabs />
          ) : (
            <ElectionRoundsTabs
              dataType={dataType}
              type={type}
              year={year}
              electionRound={electionRound}
              city={city}
              municipality={municipality}
              pollingStation={pollingStation}
              items={election.election_rounds}
            />
          )
        ) : null}

        <Charts
          locale={i18n.language}
          dataType={dataType}
          type={type}
          year={year}
          electionRound={electionRound}
          municipality={municipality}
          pollingStation={pollingStation}
          city={city}
        />

        {dataType === "results" && (
          <div className="my-8">
            {isLoading && !error ? (
              <div className="flex justify-end">
                <LoaderButton />
              </div>
            ) : (
              <ExportExcel
                url={electionExportUrl}
                locale={i18n.language}
                type={type}
                year={year}
                electionRound={electionRound}
                city={city}
                municipality={municipality}
                pollingStation={pollingStation}
              />
            )}
          </div>
        )}

        {dataType === "results" && type === "referendum" && (
          <div className="mt-8 mb-16">
            {isLoading && !error ? (
              <LoaderPageTitle />
            ) : (
              <SectionTitle title={`"${election.referendum_question}"`} />
            )}
          </div>
        )}

        {dataType === "results" && (
          <PoliticalPartiesResultsSection
            dataType={dataType}
            locale={i18n.language}
            type={type}
            year={year}
            electionRound={electionRound}
            city={city}
            municipality={municipality}
            pollingStation={pollingStation}
          />
        )}

        {dataType === "results" && type === "local" && (
          <CandidatesListMandates />
        )}

        {(dataType === "results" || dataType === "turnout") && (
          <StatisticsSection
            dataType={dataType}
            locale={i18n.language}
            type={type}
            year={year}
            electionRound={electionRound}
            city={city}
            municipality={municipality}
            pollingStation={pollingStation}
            titles={statisticColumnsTitles}
          />
        )}
      </Container>
    </div>
  );
}

export default ElectionCapitalMunicipalityPollingStation;
