import {useRef} from "react";

function Dropdown({
  titleConcatenate,
  selectedItem,
  titleSection,
  items,
  onSelect,
  type
}) {
  const ref = useRef();

  return (
    <div ref={ref} className="col-span-12 md:col-span-3 relative md:my-4">
      <select value={selectedItem} id="items" className="text-gray-700 font-bold text-sm shadow-md w-full bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 rounded-md px-1 py-2" onChange={(event) => {
          onSelect(event.target.value);
      }}>
          <option value="" disabled>
              {titleSection}
          </option>
        {items.map((item) => (
            type === 'municipality' ?
                <option key={item.municipality} value={item.municipality}>
                    {titleConcatenate ? titleSection + "-" + item.municipality : item.municipality}
                </option>
                :
                type === 'populated_area' ?
                    <option key={item.populated_area} value={item.populated_area}>
                        {titleConcatenate ? titleSection + "-" + item.populated_area : item.populated_area}
                    </option>
                    :
                    type === 'polling_station' ?
                        <option key={item.name} value={item.name}>
                            {titleConcatenate ? titleSection + "-" + item.name : item.name}
                        </option>
                        :
                        <option key={item} value={item}>
                            {titleConcatenate ? titleSection + "-" + item : item}
                        </option>
        ))}
      </select>
    </div>
  );
}

export default Dropdown;
