import React, {useEffect, useRef, useState} from 'react';
import {FaGlobeEurope} from "react-icons/fa";
import {useTranslation} from "react-i18next";

function LocalePicker(props) {
    const ref = useRef();
    const { i18n } = useTranslation();
    const [showLocales, setShowLocales] = useState(() => false)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref.current.contains(event.target)) {
                setShowLocales(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className="tour_lang_nav_link ml-auto md:ml-3 order-2 md:order-none">
            <div className="text-red-700 hover:bg-gray-100 p-2  rounded-lg cursor-pointer flex items-center" onClick={() => setShowLocales(!showLocales)}>
                <p className="font-bold">{i18n.language.toUpperCase()}</p>
                <button className="order-2 md:order-none text-2xl relative transition duration-300 ml-2">
                    <FaGlobeEurope />
                </button>
            </div>
            <div className={`${showLocales ? "max-h-52" : "max-h-0"} transition-max-height duration-500 absolute top-full bg-white right-0 shadow-md overflow-hidden rounded-lg`}>
                {Object.keys(props.locales).map((locale) => (
                        <button key={locale} type="submit" className="font-normal text-red-700 hover:bg-gray-100 transition block duration-300 w-full text-left cursor-pointer py-2 px-5" style={{ fontWeight: i18n.language === locale && "bold", backgroundColor: i18n.language === locale && '#f3f4f6' }} onClick={() => {
                            i18n.changeLanguage(locale)
                                setShowLocales(false)
                        }}>
                            {props.locales[locale].nativeName}
                        </button>
                    )
                )}
            </div>
        </div>
    )
}

export default LocalePicker;