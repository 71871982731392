import { FaBoxTissue } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHttp } from "../../../hooks/useHttp";
import useIsMounted from "../../../hooks/useIsMounted";

function ElectionComparisonTableCells(props) {
  const { t, i18n } = useTranslation();
  const { sendRequest, clearError } = useHttp();
  const [electionRoundPoliticalParties, setElectionRoundPoliticalParties] =
    useState();
  const { id, items, dataType, areaId } = props;

  const mounted = useIsMounted();

  const electionRoundPoliticalPartiesUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-political-parties`;

  useEffect(() => {
    const getElectionAreas = async () => {
      if (
        items[0].electionType === "parliamentary" &&
        items[id].electionType === "parliamentary"
      ) {
        if (id === 0) {
          if (!items[id].constituency) {
            const electionRoundPoliticalPartiesData = await sendRequest(
              electionRoundPoliticalPartiesUrl,
              i18n.language,
              items[id].electionType,
              items[id].electionYear,
              items[id].electionRound,
              areaId,
              items[id].municipality,
              items[id].pollingStation,
              items[id].city
            );
            if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
          } else {
            if (items[id].municipality) {
              const electionRoundPoliticalPartiesData = await sendRequest(
                electionRoundPoliticalPartiesUrl,
                i18n.language,
                items[id].electionType,
                items[id].electionYear,
                items[id].electionRound,
                items[id].constituency,
                items[id].municipality,
                areaId,
                items[id].city
              );
                if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
            } else {
              const electionRoundPoliticalPartiesData = await sendRequest(
                electionRoundPoliticalPartiesUrl,
                i18n.language,
                items[id].electionType,
                items[id].electionYear,
                items[id].electionRound,
                items[id].constituency,
                areaId,
                items[id].pollingStation,
                items[id].city
              );
                if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
            }
          }
        } else {
          if (
            items[0].constituency &&
            items[id].constituency &&
            items[0].constituency === items[id].constituency
          ) {
            if (
              items[0].municipality &&
              items[id].municipality &&
              items[0].municipality === items[id].municipality
            ) {
              const electionRoundPoliticalPartiesData = await sendRequest(
                electionRoundPoliticalPartiesUrl,
                i18n.language,
                items[id].electionType,
                items[id].electionYear,
                items[id].electionRound,
                items[id].constituency,
                items[id].municipality,
                areaId,
                items[id].city
              );
                if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
            } else if (!items[0].municipality && !items[id].municipality) {
              const electionRoundPoliticalPartiesData = await sendRequest(
                electionRoundPoliticalPartiesUrl,
                i18n.language,
                items[id].electionType,
                items[id].electionYear,
                items[id].electionRound,
                items[id].constituency,
                areaId,
                items[id].pollingStation,
                items[id].city
              );
                if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
            }
          } else if (!items[0].constituency && !items[id].constituency) {
            const electionRoundPoliticalPartiesData = await sendRequest(
              electionRoundPoliticalPartiesUrl,
              i18n.language,
              items[id].electionType,
              items[id].electionYear,
              items[id].electionRound,
              areaId,
              items[id].municipality,
              items[id].pollingStation,
              items[id].city
            );
            if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
          }
        }
      } else if (
        items[0].electionType !== "parliamentary" &&
        items[id].electionType !== "parliamentary"
      ) {
        if (id === 0) {
          if (!items[id].municipality) {
            const electionRoundPoliticalPartiesData = await sendRequest(
              electionRoundPoliticalPartiesUrl,
              i18n.language,
              items[id].electionType,
              items[id].electionYear,
              items[id].electionRound,
              null,
              areaId,
              items[id].pollingStation,
              items[id].city
            );
            if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
          } else {
            const electionRoundPoliticalPartiesData = await sendRequest(
              electionRoundPoliticalPartiesUrl,
              i18n.language,
              items[id].electionType,
              items[id].electionYear,
              items[id].electionRound,
              null,
              items[id].municipality,
              areaId,
              items[id].city
            );
            if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
          }
        } else {
          if (
            !items[0].municipality &&
            !items[id].municipality &&
            items[0].municipality === items[id].municipality
          ) {
            const electionRoundPoliticalPartiesData = await sendRequest(
              electionRoundPoliticalPartiesUrl,
              i18n.language,
              items[id].electionType,
              items[id].electionYear,
              items[id].electionRound,
              null,
              areaId,
              items[id].pollingStation,
              items[id].city
            );
            if ( mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
          } else if (
            items[0].municipality &&
            items[id].municipality &&
            items[0].municipality === items[id].municipality
          ) {
            const electionRoundPoliticalPartiesData = await sendRequest(
              electionRoundPoliticalPartiesUrl,
              i18n.language,
              items[id].electionType,
              items[id].electionYear,
              items[id].electionRound,
              null,
              items[id].municipality,
              areaId,
              items[id].city
            );
            if (mounted())
              setElectionRoundPoliticalParties(
                electionRoundPoliticalPartiesData
              );
          }
        }
      }
    };
    getElectionAreas();

    return () => {
      clearError();
    };
  }, [areaId, clearError, electionRoundPoliticalPartiesUrl, i18n.language, id, items, mounted, sendRequest]);

  // if (!electionRoundPoliticalParties) return null;

  return (
    <td className="p-4">
      <div className="grid grid-cols-12 gap-6">
        {electionRoundPoliticalParties?.map((area, index) => (
          <div key={index} className="col-span-12">
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-12 mb-2 flex justify-between">
                {items[id].electionType !== "parliamentary" ? (
                  items[id].electionType === "local" ||
                  items[id].electionType === "presidential" ? (
                    <p>
                      {area.political_party_name} - {area.candidate_name}
                    </p>
                  ) : (
                    items[id].electionType === "referendum" && (
                      <p>{area.candidate_name}</p>
                    )
                  )
                ) : (
                  <p>{area.political_party_name}</p>
                )}
                <p className="flex items-center gap-2 ml-6">
                  <FaBoxTissue className="text-gray-400" />
                  {area.total_party_votes}
                </p>
              </div>
              <div className="col-span-12 flex items-center">
                <div className="relative h-4 mr-6 w-full bg-gray-200 rounded overflow-hidden">
                  <div
                    className={`${
                      area.candidate_name === t("yes")
                        ? "bg-emerald-500"
                        : "bg-red-500"
                    } absolute h-full left-0`}
                    style={{
                      backgroundColor: area.party_color,
                      width: area.percentage + "%",
                    }}
                  />
                </div>
                <p>{area.percentage}%</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </td>
  );
}
export default ElectionComparisonTableCells;
