import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import ElectionComparisonTopSection from "./electionComparisonTopSection/ElectionComparisonTopSection";
import ElectionComparisonTableSection from "./electionComparisonTableSection/ElectionComparisonTableSection";
import { useHttp } from "../../hooks/useHttp";

function ElectionComparison() {
  const { i18n } = useTranslation();

  const electionUrl = `${process.env.REACT_APP_API_URL}/election`;
  const { isLoading,error, sendRequest, clearError } = useHttp();

  const params = new URLSearchParams(document.location.search);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [electionList, setElectionList] = useState();
  const [showAddElectionCompare, setShowAddElectionCompare] = useState(true);
  const [comparasionCheck, setComparasionCheck] = useState();

  const DEFAULT_SELECTED_ELECTION = {
      dataType: "results",
      electionType: electionList ? electionList[0].type : null,
      electionYear: electionList ? electionList[0].year : null,
      electionRound: electionList ? electionList[0].election_rounds[0].id : null,
      constituency: null,
      municipality: null,
      pollingStation: null,
      city: null,
    }
  const [addButtonClicked, setAddButtonClicked] = useState();

  const onAddButtonClicked = () => {
    setAddButtonClicked((prevState)=>[
      ...prevState,
      DEFAULT_SELECTED_ELECTION,
    ]);
  };

  const onRemoveButtonClicked = (index) => {
    const list = [...addButtonClicked];
    list.splice(index, 1);
    setAddButtonClicked(list);
  };

  const array = [];

  addButtonClicked?.forEach((item, index) => {
    item.dataType = params.has("dataType" + index)
      ? params.get("dataType" + index)
      : item.dataType;
    item.electionType = params.has("electionType" + index)
      ? params.get("electionType" + index)
      : item.electionType;
    item.electionYear = params.has("electionYear" + index)
      ? params.get("electionYear" + index)
      : item.electionYear;
    item.electionRound = params.has("electionRound" + index)
      ? params.get("electionRound" + index)
      : item.electionRound;
    item.constituency = params.get("constituency" + index);
    item.municipality = params.get("municipality" + index);
    item.pollingStation = params.has("pollingStation" + index)
      ? params.get("pollingStation" + index)
      : item.pollingStation;
    item.city = params.has("city" + index)
      ? params.get("city" + index)
      : item.city;

    const dataType = item.dataType
      ? "dataType" + index + "=" + item.dataType
      : null;
    const electionType = item.electionType
      ? "electionType" + index + "=" + item.electionType
      : null;
    const electionYear = item.electionYear
      ? "electionYear" + index + "=" + item.electionYear
      : null;
    const electionRound = item.electionRound
      ? "electionRound" + index + "=" + item.electionRound
      : null;
    const municipality = item.municipality
      ? "municipality" + index + "=" + item.municipality
      : null;
    const constituency = item.constituency
      ? "constituency" + index + "=" + item.constituency
      : null;
    const city = item.city ? "city" + index + "=" + item.city : null;

    array.push(
      dataType,
      electionType,
      electionYear,
      electionRound,
      constituency,
      municipality,
      city
    );
  });

  const filteredArray = array.filter(function (el) {
    return el != null;
  });

  const baseUrl = "/compare?";
  const fullUrl = baseUrl.concat(filteredArray.toString().replaceAll(",", "&"));

  useEffect(()=>{
  const getElectionList = async () => {
    const electionListData = await sendRequest(electionUrl, i18n.language);
    setElectionList(electionListData);

    setAddButtonClicked([{
      dataType: "results",
      electionType: params.has("electionType0") ? params.get("electionType0") : electionListData[0].type,
      electionYear: params.has("electionYear0") ? params.get("electionYear0") : electionListData[0].year,
      electionRound: params.has("electionRound0") ? params.get("electionRound0") : electionListData[0].election_rounds[0].id,
      constituency: params.has("constituency0") ? params.get("constituency0") : electionListData[0].constituency,
      municipality: params.has("municipality0") ? params.get("municipality0") : electionListData[0].municipality,
      pollingStation: params.has("pollingStation0") ? params.get("pollingStation0") : electionListData[0].pollingStation,
      city: params.has("city0") ? params.get("city0") : electionListData[0].city,
    },{
      dataType: "results",
      electionType: params.has("electionType1") ? params.get("electionType1") : electionListData[0].type,
      electionYear: params.has("electionYear1") ? params.get("electionYear1") : electionListData[0].year,
      electionRound: params.has("electionRound1") ? params.get("electionRound1") : electionListData[0].election_rounds[0].id,
      constituency: params.has("constituency1") ? params.get("constituency1") : electionListData[0].constituency,
      municipality: params.has("municipality1") ? params.get("municipality1") : electionListData[0].municipality,
      pollingStation: params.has("pollingStation1") ? params.get("pollingStation1") : electionListData[0].pollingStation,
      city: params.has("city1") ? params.get("city1") : electionListData[0].city,
    }])

  };

  getElectionList();
  
  return()=>{
    clearError();
  }
  },[clearError, electionUrl, i18n.language, sendRequest])

  useEffect(() => {
    navigate(fullUrl);
  }, [navigate, fullUrl]);

  const removeParam = (param) => params.delete(param); 

  useEffect(() => {
      let electionTypes = [];
      params.forEach((value,key)=>{
        if (key.match("electionType")){
          electionTypes.push(value);
        };
      })
      const mainType = electionTypes[0];
      let isValid = true;
      electionTypes.forEach((type)=>isValid = isValid && mainType === type)
      setComparasionCheck(isValid);
  }, [params])

  if (isLoading || error) return null;

  return (
    <Container>
      <PageTitle title={t("election_comparison")} />
      {showAddElectionCompare && (
        <div className="grid grid-cols-1 my-10">
          <div className="col-span-11">
            <button
              onClick={onAddButtonClicked}
              className="flex ml-auto items-center font-semibold text-white bg-red-700 hover:bg-red-800 px-3 py-2 rounded-full"
            >
              <FaPlus />
            </button>
          </div>
        </div>
      )}
      {addButtonClicked?.map((singleItem, index) => (
        <ElectionComparisonTopSection
          id={index}
          key={index}
          electionList={electionList}
          dataType={singleItem.dataType}
          type={singleItem.electionType}
          year={singleItem.electionYear}
          electionRound={singleItem.electionRound}
          constituency={singleItem.constituency}
          municipality={singleItem.municipality}
          pollingStation={singleItem.pollingStation}
          city={singleItem.city}
          removeElectionButton={onRemoveButtonClicked}
          removeParam={removeParam}
          remove={!(index === 0 || index === 1)}
          items={addButtonClicked}
          params={params}
          setShowAddElectionCompare={setShowAddElectionCompare}
        />
      ))}
      {comparasionCheck ? <ElectionComparisonTableSection
        items={addButtonClicked}
        params={params}
      />
      : 
      <div className=" text-center font-semibold px-3 py-2 text-2xl grid grid-cols-1 my-10"> 
        <div className="text-red-600 mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
          </svg>
        </div>
        <h1 className="text-red-600 mx-auto">
          {t('election_comparison_message')}
        </h1>
      </div>
      }
    </Container>
  );
}

export default ElectionComparison;
