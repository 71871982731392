import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import PageTitle from "../../../components/PageTitle";
import ElectoralRollComparsion from "./ElectoralRollComparison";
import ElectoralRollComparsionTable from "./ElectoralRollComparisonTable";
import axios from "axios";
import Dropdown from "../../../components/Dropdown";
import LoaderTable from "../../../components/loaders/LoaderTable";
import LoaderPageTitle from "../../../components/loaders/LoaderPageTitle";

function ElectoralRollComparsionPage() {
  const { t, i18n } = useTranslation();

  const [totalVoters, setTotalVoters] = useState([0, 0]);
  const [totalMaleVoters, setTotalMaleVoters] = useState([0, 0]);
  const [totalFemaleVoters, setTotalFemaleVoters] = useState([0, 0]);
  const [totalItems, setTotalItems] = useState([0, 0]);
  const [selectedItems, setSelectedItems] = useState([{}, {}]);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [pollingStation, setPollingStation] = useState('');
  const [populatedArea, setPopulatedArea] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [pollingStationList, setPollingStationList] = useState([]);
  const [populatedAreaList, setPopulatedAreaList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const electoralRollPollingStationsListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-polling-stations`;
  const electoralRollPopulatedAreasListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-populated-areas`;
  const electoralRollMunicipalitiesListUrl = `${process.env.REACT_APP_API_URL}/polling-station-information-municipalities`;

  useEffect(() => {
    axios
        .get(electoralRollMunicipalitiesListUrl, {
          params: {
            locale: i18n.language,
          },
        })
        .then((response) => {
          setMunicipalityList(response.data);
        })
        .catch((error) => {
          setMunicipalityList(null);
        });

    axios
        .get(electoralRollPopulatedAreasListUrl, {
          params: {
            locale: i18n.language,
            municipality: municipality,
          },
        })
        .then((response) => {
          setPopulatedAreaList(response.data);
        })
        .catch((error) => {
          setPopulatedAreaList(null);
        });

    axios
        .get(electoralRollPollingStationsListUrl, {
          params: {
            locale: i18n.language,
            municipality: municipality,
            populated_area: populatedArea,
          },
        })
        .then((response) => {
          setPollingStationList(response.data);
        })
        .catch((error) => {
          setPollingStationList(null);
        });
  }, [
    electoralRollMunicipalitiesListUrl,
    electoralRollPopulatedAreasListUrl,
    electoralRollPollingStationsListUrl,
    i18n.language,
    pollingStation,
    populatedArea,
    municipality,
    currentPage
  ]);

  let arr = [];
  selectedItems.forEach((item) => {
    if (Array.isArray(item)) {
      arr = arr.concat(item);
    }
  });

  const groupedByMunicipality = arr?.reduce(function (r, a) {
    r[a.name] = r[a.name] || [];
    r[a.name].push(a);
    return r;
  }, Object.create(null));

  const tableData = Object.keys(groupedByMunicipality).map((key) => [
    key,
    groupedByMunicipality[key],
  ]);

  function getDatesHeaders(headers, dates) {
    const arr = [];
    dates.forEach((date) =>
      headers.forEach((header) => arr.push(`${t(header)} (${date})`))
    );
    return arr;
  }

  function onSelectMunicipality(item) {
    setMunicipality(item);
    setPopulatedArea('');
    setPollingStation('')
    setCurrentPage(1);
  }

  function onSelectPopulatedArea(item) {
    setPopulatedArea(item);
    setPollingStation('')
    setCurrentPage(1);
  }

  function onSelectPollingStation(item) {
    setPollingStation(item);
    setCurrentPage(1);
  }

  function setClearFilter() {
    setMunicipality('');
    setPopulatedArea('');
    setPollingStation('');
  }

  return (
    <Container>
      <Helmet>
        <title>{t("header.electoral_roll")}</title>
      </Helmet>
      <PageTitle
        title={t("header.electoral_roll_comparison")}
        link={`/electoral-roll`}
      />
        <div className="grid grid-cols-12 my-8 gap-5 mt-16">
          {!municipalityList.municipalities ?
              <div className="col-span-3">
                <LoaderPageTitle/>
              </div>
              :
              <Dropdown
                  titleConcatenate={false}
                  type="municipality"
                  titleSection={t("search_by_municipality")}
                  items={municipalityList.municipalities ?? []}
                  onSelect={onSelectMunicipality}
                  selectedItem={municipality}
              />
          }
          {!populatedAreaList.populatedAreas ?
              <div className="col-span-3">
                <LoaderPageTitle/>
              </div>
              :
              <Dropdown
                  titleConcatenate={false}
                  type="populated_area"
                  titleSection={t("search_by_populated_area")}
                  items={populatedAreaList.populatedAreas ?? []}
                  onSelect={onSelectPopulatedArea}
                  selectedItem={populatedArea}
              />
          }
          {!pollingStationList.pollingStations ?
              <div className="col-span-3">
                <LoaderPageTitle/>
              </div>
              :
              <Dropdown
                  titleConcatenate={false}
                  type="polling_station"
                  titleSection={t("search_by_polling_station")}
                  items={pollingStationList.pollingStations ?? []}
                  onSelect={onSelectPollingStation}
                  selectedItem={pollingStation}
              />
          }
          {(municipality || populatedArea || pollingStation) &&
              <div className="col-span-12 md:col-span-3 relative md:my-4">
                <button className="bg-red-700 shadow-md text-white rounded-lg h-10 w-full md:w-10" onClick={setClearFilter}>X</button>
              </div>
          }
        </div>
        <div className="bg-white mt-10 w-full mx-auto mb-8 rounded-box rounded-xl p-5 flex flex-col">
          <div className="flex justify-around mx-auto w-3/4">
            {selectedItems.map((_, index) => {
              return (
                <ElectoralRollComparsion
                  selectedMunicipality={municipality}
                  selectedPopulatedArea={populatedArea}
                  selectedPollingStation={pollingStation}
                  setTotalVoters={setTotalVoters}
                  setTotalMaleVoters={setTotalMaleVoters}
                  setTotalFemaleVoters={setTotalFemaleVoters}
                  setTotalItems={setTotalItems}
                  setSelectedItems={setSelectedItems}
                  setSelectedDates={setSelectedDates}
                  currentPage={currentPage}
                  index={index}
                  key={index}
                />
              );
            })}
          </div>

          <div className="block ">
            {tableData.length === 0 &&
                <LoaderTable />
            }
            {tableData.length > 0 && (
              <ElectoralRollComparsionTable
                data={tableData}
                totalVoters={totalVoters}
                totalMaleVoters={totalMaleVoters}
                totalFemaleVoters={totalFemaleVoters}
                itemsPerPage={10}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={totalItems[0]}
                tableHead={[
                  t("polling_station"),
                  ...getDatesHeaders(
                    ["municipality", "populated_area", "female_voters", "male_voters", "total_registered_voters"],
                    selectedDates
                  ),
                  t("tendency"),
                ]}
              />
            )}
          </div>
        </div>
    </Container>
  );
}

export default ElectoralRollComparsionPage;
