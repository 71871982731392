import { FaChevronDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import ElectionBoardListPollinStationList from "./ElectionBoardListPollingStationList";

function ElectionBoardListMunicipalities({
  municipality,
  setMunicipalityOpen,
  index,
  municipalityOpen,
}) {
  const [pollingStationOpen, setPollingStationOpen] = useState(-1);

  const isOpen = index === municipalityOpen;
  const [shouldRender, setRender] = useState(isOpen);

  useEffect(() => {
    if (isOpen) setRender(true);
  }, [isOpen]);

  const onAnimationEnd = () => {
    if (!isOpen) setRender(false);
  };

  return (
    <div className="bg-slate-50 border bg-opacity-70 rounded-xl col-span-12">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <button
            onClick={() => {
              if (isOpen) {
                setMunicipalityOpen(-1);
                return;
              }
              setMunicipalityOpen(index);
            }}
            className="p-3 md:p-4 w-full text-sm md:text-base font-bold text-gray-600 flex items-center justify-between"
          >
            {municipality?.municipality_name}
            {!isOpen ? (
              <FaChevronDown className="transition duration-500 rotate-0" />
            ) : (
              <FaChevronDown className="transition duration-500 rotate-180" />
            )}
          </button>
        </div>
      </div>
      {shouldRender && (
        <div
          className={`${
            isOpen ? "animate-slideDown" : "animate-slideUp"
          } grid grid-cols-12 gap-5`}
          onAnimationEnd={onAnimationEnd}
        >
          {Object.keys(municipality?.members)?.sort((a, b) => a.slice(0, 4) - b.slice(0, 4)).map((pollingStation) => {
            return (
              <ElectionBoardListPollinStationList
                key={pollingStation}
                index={pollingStation}
                pollingStation={municipality.members[pollingStation]}
                isOpen={pollingStationOpen === pollingStation}
                setPollingStationOpen={setPollingStationOpen}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ElectionBoardListMunicipalities;
