import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { IoAlertCircleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Pagination from "../Pagination";
import SearchBar from "../SearchBar";

function AreasStatistics(props) {
  const { t } = useTranslation();
  const [itemsPerPage] = useState(() => 10);
  const [currentPage, setCurrentPage] = useState(() => 1);
  const [searchAreasStatistic, setSearchAreasStatistic] = useState(() => "");

  if (props.items.length > 1) {
    props.items.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = Array.from(
    props.items.filter((item) =>
      item.name?.toLowerCase().includes(searchAreasStatistic.toLowerCase())
    )
  ).slice(indexOfFirstItem, indexOfLastItem);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <SearchBar
        searchEvent={(event) => {
          setSearchAreasStatistic(event.target.value);
        }}
      />

      <div
        className={`grid ${
          props.municipality && props.dataType === "results"
            ? "grid-cols-4"
            : "grid-cols-5"
        } gap-5 text-gray-700 font-extrabold pb-4`}
      >
        {props.titles.map(
          (title, index) =>
            title && (
              <div className="col-span-1" key={index}>
                <p>{t(title)}</p>
              </div>
            )
        )}
      </div>

      {currentItems.length === 0 && (
        <div className="col-span-12 text-center py-8">
          <IoAlertCircleSharp className="scale-[2.5] mx-auto" />
          <p className="mt-4 text-stone-500">{t("no-data-found")}</p>
        </div>
      )}

      {currentItems.length > 0 &&
        currentItems.map((item, index) => (
          <div
            key={index}
            className={`grid ${
              props.municipality && props.dataType === "results"
                ? "grid-cols-4"
                : "grid-cols-5"
            }
            relative bg-gray-200 bg-opacity-30 text-gray-700 items-center gap-5 px-3 rounded-md py-3 my-3.5`}
          >
            <div className="col-span-1">
              <Link
                to={`${props.link}/${item.id}`}
                className="transition duration-500 hover:text-red-700 font-extrabold"
              >
                {item.name}
              </Link>
            </div>
            <div className="col-span-1">
              <p>
                {Number(item.total_registered_voters).toLocaleString("de-DE")}
              </p>
            </div>
            <div className="col-span-1">
              <p>{Number(item.total_votes_invalid).toLocaleString("de-DE")}</p>
            </div>
            <div className="col-span-1">
              <p>
                {Number(
                  props.dataType === "results"
                    ? props.municipality
                      ? item.total_votes_valid
                      : item.total_polling_stations
                    : item.total_turnout
                ).toLocaleString("de-DE")}
              </p>
            </div>
            {((props.dataType === "turnout" &&
              props.constituency &&
              props.municipality) ||
              (props.constituency && !props.municipality) ||
              (!props.constituency && !props.municipality) ||
              (props.dataType === "turnout" && props.municipality)) && (
              <div className="col-span-1 flex items-center">
                {props.dataType === "turnout" && (
                  <div className="relative w-16 h-4 rounded overflow-hidden mr-3 bg-gray-200">
                    <div
                      className="absolute bg-sky-700 h-full"
                      style={{ width: item.total_turnout_percentage + "%" }}
                    />
                  </div>
                )}
                <p>
                  {Number(
                    props.dataType === "results"
                      ? props.type === "parliamentary"
                        ? props.constituency
                          ? !props.municipality && item.total_populated_areas
                          : item.total_municipalities
                        : item.total_populated_areas
                      : item.total_turnout_percentage
                  ).toLocaleString("de-DE")}
                  {props.dataType === "turnout" && "%"}
                </p>
              </div>
            )}
            <div className="absolute right-3 h-full flex items-center hover:cursor-pointer hover:text-red-700 text-lg transition duration-500">
              <Link to={`${props.link}/${item.id}`}>
                <FaArrowRight />
              </Link>
            </div>
          </div>
        ))}

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={props.items.length}
        currentPage={currentPage}
        paginate={paginate}
        indexFirst={indexOfFirstItem}
        indexLast={indexOfLastItem}
      />
    </div>
  );
}

export default AreasStatistics;
