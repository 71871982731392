import React from "react";

import classes from "./ElectoralRollTable.module.css";
import Pagination from "../../../components/Pagination";
import { useTranslation } from "react-i18next";

function ElectoralRollTable({
  data,
  totalItems,
  totalVoters,
  totalMaleVoters,
  totalFemaleVoters,
  itemsPerPage,
  tableHead,
  page,
  currentPage,
  tour = false,
  tourClassName = "",
}) {
  const { t } = useTranslation();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  return (
    <div>
      <div className="overflow-x-scroll rounded-xl border border-gray-200">
        <table className="w-full text-sm md:text-base">
          <thead className={classes.tableRowHeader}>
            <tr>
              {tableHead.map((item, index) => {
                return (
                  <th className={classes.tableHeader} key={index}>
                    {t(item)}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th className={classes.tableTotalHeader}>{`${t("total")} :`}</th>
              <th/>
              <th/>
              <th className={classes.tableTotalHeader}>
                {totalMaleVoters}
              </th>
              <th className={classes.tableTotalHeader}>
                {totalFemaleVoters}
              </th>
              <th className={classes.tableTotalHeader}>
                {totalVoters}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((el, index) => (
              <tr className={classes.tableRowItems} key={index}>
                <td className={classes.tableCell}>{el.name}</td>
                <td className={classes.tableCell}>{el.populated_area}</td>
                <td className={classes.tableCell}>{el.municipality}</td>
                <td className={classes.tableCell}>{el.male}</td>
                <td className={classes.tableCell}>{el.female}</td>
                <td className={classes.tableCell}>{el.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {itemsPerPage ? (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          paginate={page}
          indexFirst={indexOfFirstItem}
          indexLast={indexOfLastItem}
        />
      ) : null}
    </div>
  );
}

export default ElectoralRollTable;
