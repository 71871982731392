import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function PageTitle(props) {
  return (
    <div className="grid grid-cols-12 gap-5 pt-4">
      <div className="font-extrabold text-gray-700 col-span-12 flex items-center">
        {props.link && (
          <Link
            to={props.link}
            className="text-xl md:text-4xl hover:text-red-700 transition duration-300 mr-3"
          >
            {props.icon || <FaArrowLeft />}
          </Link>
        )}
        <p className="text-xl sm:text-3xl md:text-4xl">{props.title}</p>
      </div>
    </div>
  );
}

export default PageTitle;
