import React from "react";
import { useTranslation } from "react-i18next";
import ElectoralBodiesTabItem from "./ElectoralBodiesTabItem";

function ElectoralBodiesTabs(props) {
  const { t } = useTranslation();

  const tabs = [
    "sec",
    "mec-municipality-election-commission",
    "eb-electoral-board",
  ];

  return (
    <div className="grid grid-cols-12 my-12">
      <div className="col-span-12 justify-center flex">
        <div className="tour_tabs inline-flex rounded-md p-0.5 bg-gray-700 bg-opacity-10">
          {tabs.map((tab, index) => (
            <ElectoralBodiesTabItem
              tour
              tourClassName={
                "tour_electoral_bodies_tab_" + tab.replaceAll("-", "_")
              }
              key={index}
              tab={tab}
              membersType={props.membersType}
              nameShort={t("electoral-bodies." + tab)}
              nameLong={t("electoral-bodies." + tab + "_name")}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ElectoralBodiesTabs;
