export const pages = [
    {
        page: '/election',
        steps: [
            {
                target: '.tour_elections_nav_link',
                content: 'interactive_tour.step_1',
                disableBeacon: true
            },
            {
                target: '.tour_election_type_section',
                content: 'interactive_tour.step_2',
            },
            {
                target: '.tour_election_subtype',
                content: 'interactive_tour.step_3'
            },
            {
                target: '.tour_elections_2021',
                content: 'interactive_tour.step_4'
            }
        ],
    },
    {
        page: '/election/results/electionType/local/electionYear/2021/electionRound/21',
        steps: [
            {
                target: '.tour_secondary_navbar_tabs',
                content: 'interactive_tour.step_5'
            },
            {
                target: '.tour_election_rounds',
                content: 'interactive_tour.step_6'
            },
            {
                target: '.tour_election_statistics',
                content: 'interactive_tour.step_7',
                placement: 'left'
            },
            {
                target: '.tour_export_excel',
                content: 'interactive_tour.step_8',
            }
        ]
    },
    {
        page: '/compare',
        steps: [
            {
                target: '.tour_compare_nav_link',
                content: 'interactive_tour.step_9',
            },
            {
                target: '.tour_compare_dropdown',
                content: 'interactive_tour.step_10',
            },
            {
                target: '.tour_compare_result',
                content: 'interactive_tour.step_11'
            },
        ]
    },
    {
        page: '/electoral-bodies/sec',
        steps: [
            {
                target: '.tour_electoral_bodies_nav_link',
                content: 'interactive_tour.step_12'
            },
            {
                target: '.tour_electoral_bodies_filter_dropdown',
                content: 'interactive_tour.step_13'
            },
            {
                target: '.tour_electoral_bodies_tab_sec',
                content: 'interactive_tour.step_14'
            }
        ]
    },
    {
        page: '/electoral-bodies/mec-municipality-election-commission',
        steps: [
            {
                target: '.tour_electoral_bodies_tab_mec_municipality_election_commission',
                content: 'interactive_tour.step_15'
            },
            {
                target: '.tour_electoral_bodies_mec_municipality_election_commission_search_bar',
                content: 'interactive_tour.step_16'
            },
        ]
    },
    {
        page: '/electoral-bodies/eb-electoral-board',
        steps: [
            {
                target: '.tour_electoral_bodies_tab_eb_electoral_board',
                content: 'interactive_tour.step_17'
            },
            {
                target: '.tour_electoral_bodies_eb_electoral_board_search_bar',
                content: 'interactive_tour.step_18'
            },
        ]
    },
    {
        page: '/pollingStations',
        steps: [
            {
                target: '.tour_polling_stations_nav_link',
                content: 'interactive_tour.step_19'
            },
            {
                target: '.tour_polling_stations_first',
                content: 'interactive_tour.step_20'
            },
        ]
    },
    {
        page: '/electoral-roll',
        steps: [
            {
                target: '.tour_electoral_roll_nav_link',
                content: 'interactive_tour.step_21'
            },
            {
                target: '.tour_electoral_roll_table',
                content: 'interactive_tour.step_22'
            },
            {
                target: '.tour_lang_nav_link',
                content: 'interactive_tour.step_23'
            }
        ]
    }
];