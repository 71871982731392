import ElectionComparisonTableHead from "./ElectionComparisonTableHead";
import ElectionComparisonTableBody from "./ElectionComparisonTableBody";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../components/SearchBar";
import Pagination from "../../../components/Pagination";

function ElectionComparisonTableSection(props) {
  const { t } = useTranslation();
  const [itemsPerPage] = useState(() => 5);
  const [currentPage, setCurrentPage] = useState(() => 1);
  const [searchAreasStatistic, setSearchAreasStatistic] = useState(() => "");
  const [currentPageItems, setCurrentPageItems] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
      <div className="tour_compare_result grid grid-cols-1 py-10">
      {currentPageItems && currentPageItems.length >= 1 && (
        <SearchBar
          searchEvent={(event) => {
            setSearchAreasStatistic(event.target.value);
          }}
        />
      )}
      <div
        style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)" }}
        className="overflow-auto bg-white rounded-2xl"
      >
        <table className="w-full text-sm divide-y divide-gray-300">
          {currentPageItems && currentPageItems.length >= 1 && (
            <thead>
              <tr className="divide-x divide-gray-300">
                <th className=" rounded-2xl text-left whitespace-nowrap p-4">
                  {t("name")}
                </th>
                {props.items.map((singleItem, index) => (
                  <ElectionComparisonTableHead
                    id={index}
                    key={index}
                    dataType={singleItem.dataType}
                    type={singleItem.electionType}
                    year={singleItem.electionYear}
                    electionRound={singleItem.electionRound}
                    constituency={singleItem.constituency}
                    municipality={singleItem.municipality}
                    city={singleItem.city}
                    items={props.items}
                    params={props.params}
                  />
                ))}
              </tr>
            </thead>
          )}
          <tbody className="divide-y divide-gray-300">
            {props.items?.map(
              (singleItem, index) =>
                index === 0 && (
                  <ElectionComparisonTableBody
                    id={index}
                    key={index}
                    dataType={singleItem.dataType}
                    type={singleItem.electionType}
                    year={singleItem.electionYear}
                    electionRound={singleItem.electionRound}
                    constituency={singleItem.constituency}
                    municipality={singleItem.municipality}
                    pollingStation={singleItem.pollingStation}
                    city={singleItem.city}
                    items={props.items}
                    params={props.params}
                    searchParam={searchAreasStatistic}
                    pageItemsNumber={setCurrentPageItems}
                    itemsNumber={setTotalItems}
                    indexFirst={indexOfFirstItem}
                    indexLast={indexOfLastItem}
                  />
                )
            )}
          </tbody>
        </table>
      </div>

      {currentPageItems && currentPageItems.length >= 1 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={totalItems.length}
          currentPage={currentPage}
          paginate={paginate}
          indexFirst={indexOfFirstItem}
          indexLast={indexOfLastItem}
        />
      )}
    </div>
  );
}

export default ElectionComparisonTableSection;
