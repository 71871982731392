import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../../hooks/useHttp";

function ElectionComparisonTableHead(props) {
  const { t, i18n } = useTranslation();
  const {
    dataType,
    id,
    params,
    type,
    year,
    electionRound,
    city,
    constituency,
    municipality,
  } = props;

  const { sendRequest, clearError } = useHttp();
  const [electionList, setElectionList] = useState();
  const [electionAreas, setElectionAreas] = useState();

  const electionUrl = `${process.env.REACT_APP_API_URL}/election`;
  const electionRoundAreasUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-areas`;

  useEffect(() => {
    const getElectionList = async () => {
      const electionListData = await sendRequest(
        electionUrl,
        i18n.language,
        params.has("electionType" + id)
          ? params.get("electionType" + id)
          : type,
        params.has("electionYear" + id) ? params.get("electionYear" + id) : year
      );
      setElectionList(electionListData);
    };

    const getElectionAreas = async () => {
      const electionAreasData = await sendRequest(
        electionRoundAreasUrl,
        i18n.language,
        params.has("electionType" + id)
          ? params.get("electionType" + id)
          : type,
        params.has("electionYear" + id)
          ? params.get("electionYear" + id)
          : year,
        params.has("electionRound" + id)
          ? params.get("electionRound" + id)
          : electionRound,
        params.has("constituency" + id)
          ? params.get("constituency" + id)
          : null,
        null,
        null,
        city
      );
      setElectionAreas(electionAreasData);
    };
    getElectionList();
    getElectionAreas();

    return () => {
      clearError();
    };
  }, [
    i18n.language,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    city,
    sendRequest,
    electionUrl,
    params,
    id,
    electionRoundAreasUrl,
    clearError,
  ]);

  if (!electionList) return null;
  if (!electionAreas) return null;

  return (
    <th className="text-left whitespace-nowrap p-4">
      <p>{`${electionList.name} - ${electionList.year} - ${electionList.election_rounds.find((round)=>round.id === +electionRound)?.name + (municipality ? " - " + electionAreas.filter((areaName) => areaName.id === +municipality)[0]?.name:'')} - ${t("results")}`}</p>
      <p>
        {type === "parliamentary"
          ? constituency && municipality
            ? electionAreas.filter(
                (areaName) => areaName.id === municipality
              )[0]?.name
            : electionAreas.filter(
                (areaName) => areaName.id === constituency
              )[0]?.name
          : municipality &&
            electionAreas.filter((areaName) => areaName.id === municipality)[0]
              ?.name}
      </p>
    </th>
  );
}
export default ElectionComparisonTableHead;
