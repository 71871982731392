import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import { useHttp } from "../../../hooks/useHttp";
import { usePrevious } from "../../../hooks/usePrevious";
import axios from "axios";
import LoaderPageTitle from "../../../components/loaders/LoaderPageTitle";

function ElectoralRollComparison({
  selectedMunicipality,
  selectedPopulatedArea,
  selectedPollingStation,
  setTotalVoters,
  setTotalMaleVoters,
  setTotalFemaleVoters,
  setSelectedItems,
  setTotalItems,
  index,
  setSelectedDates,
  currentPage
}) {
  const { i18n } = useTranslation();

  const [dropdownDates, setDropdownDates] = useState();
  const [selectedDate, setSelectedDate] = useState(
    dropdownDates ? dropdownDates[0] : ""
  );

  const prevSelectedDate = usePrevious(selectedDate);

  const electoralRollDatesUrl = `${process.env.REACT_APP_API_URL}/electoral-roll/dates`;
  const electoralRollDateUrl = `${process.env.REACT_APP_API_URL}/electoral-roll/${selectedDate}`;

  const {
    sendRequest: sendRequestDatesDropdown,
    clearError: clearErrorDatesDropdown,
  } = useHttp();

  useEffect(() => {
    if (selectedDate) {
    axios
        .get(electoralRollDateUrl, {
          params: {
            locale: i18n.language,
            municipality: selectedMunicipality,
            populated_area: selectedPopulatedArea,
            polling_station: selectedPollingStation,
            page: currentPage
          },
        })
        .then((response) => {
          setSelectedItems((currItems) => {
            const fullArr = [...currItems];
            fullArr.splice(index, 1, response.data.items);
            return fullArr;
          });
          setTotalItems((currItems) => {
            const fullArr = [...currItems];
            fullArr.splice(index, 1, response.data.totalItems);
            return fullArr;
          });
          setTotalVoters((currItems) => {
            const fullArr = [...currItems];
            fullArr.splice(index, 1, response.data.total);
            return fullArr;
          });
          setTotalMaleVoters((currItems) => {
            const fullArr = [...currItems];
            fullArr.splice(index, 1, response.data.total_male);
            return fullArr;
          });
          setTotalFemaleVoters((currItems) => {
            const fullArr = [...currItems];
            fullArr.splice(index, 1, response.data.total_female);
            return fullArr;
          });
          setSelectedDates((currItems) => {
            const fullArr = [...currItems];
            fullArr.splice(index, 1, selectedDate);
            return fullArr;
          });
        });
    }
  }, [
    electoralRollDateUrl,
    i18n.language,
    index,
    prevSelectedDate,
    selectedDate,
    setSelectedDates,
    setSelectedItems,
    selectedMunicipality,
    selectedPopulatedArea,
    selectedPollingStation,
    currentPage,
    setTotalVoters,
    setTotalFemaleVoters,
    setTotalMaleVoters
  ]);

  useEffect(() => {
    const getDropdownDates = async () => {
      const electoralRollItemsData = await sendRequestDatesDropdown(
        electoralRollDatesUrl,
        i18n.language
      );
      setDropdownDates(electoralRollItemsData?.dates);
      setSelectedDate(electoralRollItemsData?.dates[0]);
    };
    getDropdownDates();

    return () => {
      clearErrorDatesDropdown();
    };
  }, [
    clearErrorDatesDropdown,
    electoralRollDatesUrl,
    i18n.language,
    sendRequestDatesDropdown
  ]);

  function onSelect(item) {
    setSelectedDates((currItems) => {
      const fullArr = [...currItems];
      fullArr.splice(index, 1, item);
      return fullArr;
    });
    setSelectedDate(item);
  }

  return (
    <div className="w-full px-6 m-4">
      {!dropdownDates && (
        <LoaderPageTitle />
      )}
      {dropdownDates && (
        <Dropdown
          titleConcatenate={true}
          type="importance_data"
          titleSection={t("importance_of_data")}
          items={dropdownDates}
          onSelect={onSelect}
          selectedItem={selectedDate}
        />
      )}
    </div>
  );
}

export default ElectoralRollComparison;
