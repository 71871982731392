import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

function Pagination(props) {
    const pagesArray = [];

    for (var i = 1; i <= Math.ceil(props.totalItems / props.itemsPerPage); i++) {
        pagesArray.push(i);
    }

    return (
        pagesArray.length > 1 &&
        <div className="grid grid-cols-12 mt-10">
            <div className="col-span-12 flex justify-end">
                <div className="flex gap-1 items-center w-fit">
                    <p className="text-xs text-gray-500 mr-3">{props.indexFirst} - {pagesArray.length === props.currentPage ? props.totalItems : props.indexLast} of {props.totalItems}</p>

                    <button {...(1 === props.currentPage ? {disabled: true} : {})} onClick={() => props.paginate(1)}>
                        <div className={`${1 === props.currentPage ? "opacity-40 cursor-not-allowed" : "cursor-pointer hover:bg-red-700 hover:text-white"} bg-gray-100 text-red-700 transition duration-300 rounded-md`}>
                            <div className="w-9 h-9 flex items-center justify-center py-auto">
                                <FaAngleDoubleLeft/>
                            </div>
                        </div>
                    </button>
                    <button {...(1 === props.currentPage ? {disabled: true} : {})} onClick={() => props.paginate(props.currentPage - 1)}>
                        <div className={`${1 === props.currentPage ? "opacity-40 cursor-not-allowed" : "cursor-pointer hover:bg-red-700 hover:text-white"} bg-gray-100 text-red-700 transition duration-300 rounded-md`}>
                            <div className="w-9 h-9 flex items-center justify-center py-auto">
                                <FaAngleLeft/>
                            </div>
                        </div>
                    </button>
                    <button {...(pagesArray.length === props.currentPage ? {disabled: true} : {})} onClick={() => props.paginate(props.currentPage + 1)}>
                        <div className={`${pagesArray.length === props.currentPage ? "opacity-40 cursor-not-allowed" : "cursor-pointer hover:bg-red-700 hover:text-white"} bg-gray-100 text-red-700 transition duration-300 rounded-md`}>
                            <div className="w-9 h-9 flex items-center justify-center py-auto">
                                <FaAngleRight/>
                            </div>
                        </div>
                    </button>
                    <button {...(pagesArray.length === props.currentPage ? {disabled: true} : {})} onClick={() => props.paginate(pagesArray.length)}>
                        <div className={`${pagesArray.length === props.currentPage ? "opacity-40 cursor-not-allowed" : "cursor-pointer hover:bg-red-700 hover:text-white"} bg-gray-100 text-red-700 transition duration-300 rounded-md`}>
                            <div className="w-9 h-9 flex items-center justify-center py-auto">
                                <FaAngleDoubleRight/>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Pagination;
