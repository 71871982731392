import {HiCheck, HiX} from "react-icons/hi";
import {t} from "i18next";
import RoundedBox from "../RoundedBox";

function ReferendumResults(props) {
    return(
        <div className="grid md:grid-cols-2 gap-5">
            {props.items.map(
                item => (
                <div key={item.id} className="col-span-2 md:col-span-1">
                    <RoundedBox>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-3 sm:col-span-2 md:col-span-3 flex items-center justify-center">
                                <div className={`${item.candidate_name === t('yes') ? "bg-emerald-500" : "bg-red-500"} w-fit h-fit p-2 text-white text-5xl sm:text-6xl lg:text-7xl rounded-full`}>
                                    {item.candidate_name === t('yes') ?
                                        <HiCheck/>
                                        :
                                        <HiX/>
                                    }
                                </div>
                            </div>
                            <div className="col-span-9 sm:col-span-10 md:col-span-9">
                                <div className="grid grid-cols-1">
                                    <div className="col-span-1">
                                        <p className={`${item.candidate_name === t('yes') ? "text-emerald-500" : "text-red-500"} font-black text-2xl`}>{item.candidate_name}</p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-gray-700 font-extrabold text-xl">{item.percentage}%</p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-gray-500 font-bold text-xl">{Math.floor(item.total_party_votes).toLocaleString('de-DE')} {t('votes')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RoundedBox>
                </div>
                )
            )}
        </div>
    )
}

export default ReferendumResults