import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {HashLink as Link} from "react-router-hash-link";
import Pagination from "../../Pagination";
import SearchBar from "../../SearchBar";

function MobileAreasStatistics(props) {
    const {t} = useTranslation();
    const [itemsPerPage] = useState(() => 10)
    const [currentPage, setCurrentPage] = useState(() => 1)
    const [searchAreasStatistic, setSearchAreasStatistic] = useState(() => '')

    if (props.items.length > 1) {
        props.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.from(props.items.filter(item =>
        item.name?.toLowerCase().includes(searchAreasStatistic.toLowerCase())
    )).slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div>
            <SearchBar
                searchEvent={event => {setSearchAreasStatistic(event.target.value)}}
            />

            {currentItems.map(
                (item, itemIndex) => (
                    <div key={itemIndex} className="hover:bg-opacity-70 rounded-xl bg-gray-200 bg-opacity-40 p-5 transition duration-500 mb-4">
                        <Link to={`${props.link}/${item.id}`}>

                                <div className={`grid grid-cols-3 relative text-gray-700 items-center gap-3 mb-4`}>
                                    <div className="col-span-3 mx-auto">
                                        <p className="hover:text-red-700 hover:underline font-extrabold">{item.name}</p>
                                    </div>
                                </div>
                                <div className={`grid grid-cols-3 relative text-sm text-gray-700 items-center gap-3 my-2`}>
                                    <div className="col-span-2">
                                        <p>{t(props.titles[1])}:</p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-right font-bold">{Number(item.total_registered_voters).toLocaleString('de-DE')}</p>
                                    </div>
                                </div>
                                <div className={`grid grid-cols-3 relative text-sm text-gray-700 items-center gap-3 my-2`}>
                                    <div className="col-span-2">
                                        <p>{t(props.titles[2])}:</p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-right font-bold">{Number(item.total_votes_invalid).toLocaleString('de-DE')}</p>
                                    </div>
                                </div>
                                <div className={`grid grid-cols-3 relative text-sm text-gray-700 items-center gap-3 my-2`}>
                                    <div className="col-span-2">
                                        <p>{t(props.titles[3])}:</p>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="text-right font-bold">{Number(props.dataType === 'results' ? props.municipality ? item.total_votes_valid : item.total_polling_stations : item.total_turnout).toLocaleString('de-DE')}</p>
                                    </div>
                                </div>
                                {(props.dataType === "turnout" && props.constituency && props.municipality || props.constituency && !props.municipality || !props.constituency && !props.municipality || props.dataType === "turnout" && props.municipality) &&
                                <div className={`grid grid-cols-3 relative text-sm text-gray-700 items-center gap-3 my-2`}>
                                    <div className={`${props.dataType === "results" ? "col-span-2" : "col-span-1"}`}>
                                        <p>{t(props.titles[4])}:</p>
                                    </div>
                                    <div className={`${props.dataType === "results" ? "col-span-1" : "col-span-2"} flex items-center justify-end`}>
                                        {props.dataType === 'turnout' &&
                                        <div className="relative w-16 h-4 rounded overflow-hidden mr-3 bg-gray-200">
                                            <div className="absolute bg-sky-700 h-full"
                                                 style={{width: item.total_turnout_percentage + "%"}}/>
                                        </div>}
                                        <p className="font-bold">{Number(
                                            props.dataType === 'results'
                                                ? props.type === 'parliamentary'
                                                    ? props.constituency
                                                        ? !props.municipality
                                                        && item.total_populated_areas
                                                        : item.total_municipalities
                                                    : item.total_populated_areas
                                                : item.total_turnout_percentage
                                        ).toLocaleString('de-DE')}{props.dataType === "turnout" && "%"}</p>
                                    </div>
                                </div>
                                }
                        </Link>
                    </div>
                )
            )}

            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={props.items.length}
                currentPage={currentPage}
                paginate={paginate}
                indexFirst={indexOfFirstItem}
                indexLast={indexOfLastItem}
            />
        </div>
    )
}

export default MobileAreasStatistics;