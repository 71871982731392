import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../hooks/useHttp";
import LoaderTable from "../loaders/LoaderTable";

function ColumnChart(props) {
  const { t } = useTranslation();
  const {locale,type,year,electionRound,constituency,municipality,pollingStation,city,dataType} = props;

  const { isLoading, error, sendRequest, clearError } = useHttp();

  const [electionPoliticalParties, setElectionPoliticalParties] = useState();

  const electionRoundPoliticalPartiesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-political-parties`;

  useEffect(() => {
    const getElectionPoliticalParties = async () => {
      const electionPoliticalPartiesData = await sendRequest(
        electionRoundPoliticalPartiesUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        pollingStation ?? null,
        city ?? null
      );
      setElectionPoliticalParties(electionPoliticalPartiesData);
    };
    dataType === "results" && getElectionPoliticalParties();
    return () => {
      clearError();
    };
  }, [
    clearError,
    electionRoundPoliticalPartiesUrl,
    city,
    constituency,
    dataType,
    electionRound,
    locale,
    municipality,
    pollingStation,
    type,
    year,
    sendRequest,
  ]);

  // Once we got the detailed data for presidential election 2019 we can remove this code
  const presidentialElection2019FirstRound = [
    {
      "candidate_name": "Стево Пендаровски",
      "type": "person",
      "party_color": "#4f6196",
      "total_party_votes": "322581",
      "percentage": "42.81",
      "political_party_name": "СДСМ"
    },
    {
      "candidate_name": "Гордана Силјановска-Давкова",
      "type": "person",
      "party_color": "#e74c3c",
      "total_party_votes": "318341",
      "percentage": "42.25",
      "political_party_name": "ВМРО-ДПМНЕ"
    },
    {
      "candidate_name": "Блерим Река",
      "type": "person",
      "party_color": "#9b59b6",
      "total_party_votes": "79888",
      "percentage": "10.60",
      "political_party_name": "АЛИЈАНСА ЗА АЛБАНЦИТЕ И ДВИЖЕЊЕ БЕСА"
    }
  ]

  const presidentialElection2019SecondRound = [
    {
      "candidate_name": "Стево Пендаровски",
      "type": "person",
      "party_color": "#4f6196",
      "total_party_votes": "435656",
      "percentage": "51.65",
      "political_party_name": "СДСМ"
    },
    {
      "candidate_name": "Гордана Силјановска-Давкова",
      "type": "person",
      "party_color": "#e74c3c",
      "total_party_votes": "377346",
      "percentage": "44.75",
      "political_party_name": "ВМРО-ДПМНЕ"
    }
  ]

  if (dataType === "results" && !electionPoliticalParties && !presidentialElection2019FirstRound && !presidentialElection2019SecondRound) return null;

  electionPoliticalParties?.forEach((el) => {
    if (type === "referendum") {
      el.name = el.candidate_name;
      el.color = el.candidate_name === t("yes") ? "#10b981" : "#ef4444";
    } else {
      if (type === "presidential") {
        el.name = el.political_party_name + " - " + el.candidate_name;
      } else if (type === "local") {
        if (municipality) {
          el.name = el.political_party_name + " - " + el.candidate_name;
        } else {
          el.name = el.political_party_name;
        }
      } else {
        el.name = el.political_party_name;
      }
      el.color = el.party_color;
    }
    el.y = Number(el.total_party_votes);
  });

  const politicalPartiesName = [];

  electionPoliticalParties?.forEach((el) => {
    if (type === "referendum") {
      politicalPartiesName.push(el.candidate_name);
    } else {
      if (type === "presidential") {
        politicalPartiesName.push(
          el.political_party_name + " - " + el.candidate_name
        );
      } else if (type === "local") {
        if (municipality) {
          politicalPartiesName.push(
            el.political_party_name + " - " + el.candidate_name
          );
        } else {
          politicalPartiesName.push(el.political_party_name);
        }
      } else {
        politicalPartiesName.push(el.political_party_name);
      }
    }
  });

  // Once we got the detailed data for presidential election 2019 we can remove this code
  presidentialElection2019FirstRound.forEach((el) => {
    el.name = el.political_party_name + " - " + el.candidate_name;
    el.color = el.party_color;
    el.y = Number(el.total_party_votes);
  });

  const presidentialElection2019PoliticalPartiesNameFirstRound = [];

  presidentialElection2019FirstRound.forEach((el) => {
    if (type === "presidential") {
      presidentialElection2019PoliticalPartiesNameFirstRound.push(
          el.political_party_name + " - " + el.candidate_name
      );
    }
  });

  presidentialElection2019SecondRound.forEach((el) => {
    el.name = el.political_party_name + " - " + el.candidate_name;
    el.color = el.party_color;
    el.y = Number(el.total_party_votes);
  });

  const presidentialElection2019PoliticalPartiesNameSecondRound = [];

  presidentialElection2019SecondRound.forEach((el) => {
    if (type === "presidential") {
      presidentialElection2019PoliticalPartiesNameSecondRound.push(
          el.political_party_name + " - " + el.candidate_name
      );
    }
  });

  const barOptions = {
    chart: {
      type: "bar",
      backgroundColor: "transparent",
    },
    title: {
      text: t("column_chart_results_title"),
      style: {
        fontWeight: "bold",
        fontSize: 25,
      },
    },
    xAxis: {
      categories: type === "presidential" && year === "2019" ? electionRound === "27" ? presidentialElection2019PoliticalPartiesNameFirstRound : presidentialElection2019PoliticalPartiesNameSecondRound : politicalPartiesName,
      title: {
        text: t("political_party"),
        align: "middle",
      },
    },
    yAxis: {
      title: {
        text: t("votes"),
        align: "middle",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      headerFormat: "",
      borderRadius: 10,
      backgroundColor: "white",
      pointFormat: "{point.name}: <b>{point.y}</b>",
      shadow: false,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.y}",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: type === "presidential" && year === "2019" ? electionRound === "27" ? presidentialElection2019FirstRound : presidentialElection2019SecondRound : electionPoliticalParties,
      },
    ],
  };

  return isLoading && !error ? (
    <LoaderTable />
  ) : (
    <HighchartsReact options={barOptions} highcharts={Highcharts} />
  );
}

export default ColumnChart;
