import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function useSearchQueryParam(param, value) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (value) {
      setSearchParams({ [param]: value });
    } else {
      searchParams.delete(param);
      navigate({ search: searchParams.toString() });
    }
  }, [param, value, searchParams, setSearchParams, navigate]);
}

export default useSearchQueryParam;
