import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ElectionComparisonElectionList from "./ElectionComparisonElectionList";
import { useNavigate } from "react-router-dom";
import ElectionComparisonTabs from "./ElectionComparisonTabs";
import ElectionComparisonMap from "./ElectionComparisonMap";
import { useHttp } from "../../../hooks/useHttp";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function ElectionComparisonTopSection(props) {
  const { i18n } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttp();

  const {
    sendRequest: sendRequestElectionName,
    clearError: clearErrorElectionName,
  } = useHttp();

  const [electionAreaNames, setElectionAreaNames] = useState();
  const AreasUrl = `${process.env.REACT_APP_API_URL}/${props.dataType}/areas`;

  const [electionPoliticalParties, setElectionPoliticalParties] = useState();
  const [selectedElection, setSelectedElection] = useState();
  const [selectedElectionRound, setSelectedElectionRound] = useState();
  const navigate = useNavigate();

  const [municipalityPollingStations, setMunicipalityPollingStations] =
    useState();

  const electionRoundPoliticalPartiesUrl = `${process.env.REACT_APP_API_URL}/${props.dataType}/election-round-political-parties`;

  const { setShowAddElectionCompare } = props;

  const getElectionPoliticalParties = async () => {
    const electionPoliticalPartiesData = await sendRequest(
      electionRoundPoliticalPartiesUrl,
      i18n.language,
      selectedElection != null ? selectedElection.type : props.type,
      selectedElection != null ? selectedElection.year : props.year,
      selectedElectionRound != null
        ? selectedElectionRound.id
        : props.electionRound,
      selectedElection != null
        ? props.params.get("constituency" + props.id)
        : props.constituency,
      selectedElection != null
        ? props.params.get("municipality" + props.id)
        : props.municipality,
      props.pollingStation,
      props.city
    );
    setElectionPoliticalParties(electionPoliticalPartiesData);
  };

  const electionSelectHandler = () => {
    if (selectedElection) {
      props.params.set("electionType" + props.id, selectedElection.type);
      props.params.set("electionYear" + props.id, selectedElection.year);
      props.params.set("electionRound" + props.id, selectedElectionRound.id);

      if (props.type !== "parliamentary") {
        if (props.params.has("municipality" + props.id)) {
          props.params.delete("municipality" + props.id);
        }
      } else {
        if (
          props.params.has("constituency" + props.id) &&
          props.params.has("municipality" + props.id)
        ) {
          props.params.delete("municipality" + props.id);
        } else if (
          props.params.has("constituency" + props.id) &&
          !props.params.has("municipality" + props.id)
        ) {
          props.params.delete("constituency" + props.id);
        }
      }

      navigate("/compare?" + props.params.toString());
    }
  };

  const mapBackHandler = (id) => {
    if (props.type !== "parliamentary") {
      if (props.params.has("municipality" + id)) {
        props.params.delete("municipality" + id);
      }
    } else {
      if (
        props.params.has("constituency" + id) &&
        props.params.has("municipality" + id)
      ) {
        props.params.delete("municipality" + id);
      } else if (
        props.params.has("constituency" + id) &&
        !props.params.has("municipality" + id)
      ) {
        props.params.delete("constituency" + id);
      }
    }

    navigate("/compare?" + props.params.toString());
  };

  useEffect(() => {
    getElectionPoliticalParties();
    electionSelectHandler();
    mapBackHandler();

    return () => {
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    i18n.language,
    props.type,
    props.year,
    props.electionRound,
    props.constituency,
    props.municipality,
    props.city,
    selectedElection,
    selectedElectionRound,
    navigate,
    clearError,
  ]);

  useEffect(() => {
    if (!props.electionList || !electionPoliticalParties) {
      setShowAddElectionCompare(false);
    } else {
      setShowAddElectionCompare(true);
    }
  }, [props.electionList, electionPoliticalParties, setShowAddElectionCompare]);

  useEffect(() => {
    const getElectionNameArea = async () => {
      const electionAreasData = await sendRequestElectionName(
        AreasUrl,
        i18n.language,
        props.type,
        props.year,
        props.electionRound
      );
      setElectionAreaNames(
        electionAreasData.map((electionArea) => {
          return { id: electionArea.id, name: electionArea.name };
        })
      );
    };

    getElectionNameArea();

    return () => {
      clearErrorElectionName();
    };
  }, [
    AreasUrl,
    clearErrorElectionName,
    i18n.language,
    props.electionRound,
    props.type,
    props.year,
    sendRequestElectionName,
  ]);

  const pollingTableShowed =
    (props.type === "parliamentary" && props.constituency) ||
    (props.type !== "parliamentary" &&
      props.params.has("municipality" + props.id));

  function displayMunicipalityName() {
    const municipalityName = electionAreaNames?.find(
      (area) => area.id === +props.municipality
    )?.name;
    if (!pollingTableShowed || !municipalityName) return;
    return municipalityName;
  }

  if (!props.electionList) return null;
  if (!electionPoliticalParties) return null;

  const electionTypes = [];
  Array.from(props.electionList).forEach((item) => {
    if (electionTypes.indexOf(item.type) === -1) {
      electionTypes.push(item.type);
    }
  });

  return (
    <div
      className={`${
        props.id === 0 ? "mt-16" : ""
      } grid grid-cols-12 gap-3 mb-16`}
    >
      {!isLoading && props.electionList && !municipalityPollingStations && (
        <ElectionComparisonElectionList
          id={props.id}
          type={props.type}
          year={props.year}
          electionRound={props.electionRound}
          remove={props.remove}
          electionList={props.electionList}
          electionTypes={electionTypes}
          selectedElection={selectedElection}
          selectedElectionRound={selectedElectionRound}
          electionParam={setSelectedElection}
          electionRoundParam={setSelectedElectionRound}
          removeElectionButton={props.removeElectionButton}
          displayMunicipalityName={displayMunicipalityName}
        />
      )}
      {!isLoading &&
        !error &&
        props.electionList &&
        electionPoliticalParties &&
        !municipalityPollingStations && (
          <ElectionComparisonTabs
            id={props.id}
            params={props.params}
            electionList={props.electionList}
            selectedElection={selectedElection}
            selectedElectionRound={selectedElectionRound}
            electionPoliticalParties={electionPoliticalParties}
            mapBackHandler={mapBackHandler}
          />
        )}
      {!isLoading && !error && !municipalityPollingStations && (
        <ElectionComparisonMap
          id={props.id}
          locale={i18n.language}
          dataType={props.dataType}
          type={selectedElection != null ? selectedElection.type : props.type}
          year={selectedElection != null ? selectedElection.year : props.year}
          electionRound={
            selectedElectionRound != null
              ? selectedElectionRound.id
              : props.electionRound
          }
          constituency={
            props.params.has("constituency" + props.id)
              ? props.params.get("constituency" + props.id)
              : props.constituency
          }
          municipality={
            props.params.has("municipality" + props.id)
              ? props.params.get("municipality" + props.id)
              : props.municipality
          }
          pollingStation={
            props.params.has("pollingStation" + props.id)
              ? props.params.get("pollingStation" + props.id)
              : props.pollingStation
          }
          displayMunicipalityName={displayMunicipalityName}
          city={props.city}
          items={props.items}
          params={props.params}
          selectedElection={selectedElection}
          mapBackHandler={mapBackHandler}
          municipalityPollingStations={municipalityPollingStations}
          setMunicipalityPollingStations={setMunicipalityPollingStations}
        />
      )}
      {isLoading && !props.electionList && !electionPoliticalParties && (
        <AiOutlineLoading3Quarters className="mx-auto my-4 animate-spin w-8 h-8" />
      )}
    </div>
  );
}

export default ElectionComparisonTopSection;
