import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Homepage from "./pages/homepage/Homepage";
import Election from "./pages/election/Election";
import ElectoralBodiesDashboard from "./pages/electoralBodies/ElectoralBodiesDashboard";
import ElectionBoard from "./pages/electoralBodies/ElectionBoard/ElectionBoardList";
import ElectionTypes from "./pages/electionTypes/ElectionTypes";
import ElectionCapital from "./pages/electionCapital/ElectionCapital";
import ElectionMunicipality from "./pages/electionMunicipality/ElectionMunicipality";
import ElectionConstituency from "./pages/electionConstituency/ElectionConstituency";
import ElectionCapitalMunicipality from "./pages/electionCapitalMunicipality/ElectionCapitalMunicipality";
import ElectionConstituencyMunicipality from "./pages/electionConstituencyMunicipality/ElectionConstituencyMunicipality";
import ElectionMunicipalityPollingStation from "./pages/electionMunicipalityPollingStation/ElectionMunicipalityPollingStation";
import ElectionCapitalMunicipalityPollingStation from "./pages/electionCapitalMunicipalityPollingStation/ElectionCapitalMunicipalityPollingStation";
import ElectionConstituencyMunicipalityPollingStation from "./pages/electionConstituencyMunicipalityPollingStation/ElectionConstituencyMunicipalityPollingStation";
import LoaderTable from "./components/loaders/LoaderTable";
import "./App.css";
import PollingStations from "./pages/pollingStationsPage/PollingStationsPage";
import PollingStation from "./pages/pollingStationsPage/PollingStation/PollingStation";
import ElectoralRollPage from "./pages/electoralRollPage/ElectoralRollPage";
import ElectionCandidatesPage from "./pages/electionCandidates/ElectionCandidatesPage";
import ElectoralRollComparsionPage from "./pages/electoralRollPage/electoralRollComparison/ElectoralRollComparisonPage";
import ElectionComparison from "./pages/electionComparison/ElectionComparison";
import MunicipalityElectionCommisionList from "./pages/electoralBodies/MunicipalityElectionCommission/MunicipalityElectionCommision";
import StateElectionCommision from "./pages/electoralBodies/StateElectionCommision/StateElectionCommision";
import NotFound from "./pages/notfound/NotFound";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="election" element={<ElectionTypes />} />
        <Route
          path="election/candidates/electionType/:type/electionYear/:year/electionRound/:electionRound"
          element={<ElectionCandidatesPage />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound"
          element={<Election />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/municipality/:municipality"
          element={<ElectionMunicipality />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/municipality/:municipality/pollingStation/:pollingStation"
          element={<ElectionMunicipalityPollingStation />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/city/:city"
          element={<ElectionCapital />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/city/:city/municipality/:municipality"
          element={<ElectionCapitalMunicipality />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/city/:city/municipality/:municipality/pollingStation/:pollingStation"
          element={<ElectionCapitalMunicipalityPollingStation />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/constituency/:constituency"
          element={<ElectionConstituency />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/constituency/:constituency/municipality/:municipality"
          element={<ElectionConstituencyMunicipality />}
        />
        <Route
          path="election/:dataType/electionType/:type/electionYear/:year/electionRound/:electionRound/constituency/:constituency/municipality/:municipality/pollingStation/:pollingStation"
          element={<ElectionConstituencyMunicipalityPollingStation />}
        />

        <Route path="electoral-bodies" element={<ElectoralBodiesDashboard />}>
          <Route path="sec" element={<StateElectionCommision />} />
          <Route
            path="mec-municipality-election-commission"
            element={<MunicipalityElectionCommisionList />}
          />
          <Route path="eb-electoral-board" element={<ElectionBoard />} />
        </Route>
        <Route path="/pollingStations" element={<PollingStations />} />
        <Route
          path="/pollingStations/:pollingStationId"
          element={<PollingStation />}
        />

        <Route path="/compare" element={<ElectionComparison />} />

        <Route path="electoral-roll" element={<ElectoralRollPage />} />
        <Route
          path="electoral-roll/comparsion"
          element={<ElectoralRollComparsionPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback={<LoaderTable />}>
      <App />
    </Suspense>
  );
}
