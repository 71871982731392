import {useTranslation} from "react-i18next";
import React, {useState} from 'react';
import Pagination from "../Pagination";
import SearchBar from "../SearchBar";

function PoliticalParties(props) {
    const { t } = useTranslation();
    const [itemsPerPage] = useState(() => 10)
    const [currentPage, setCurrentPage] = useState(() => 1)
    const [searchAreasStatistic, setSearchAreasStatistic] = useState(() => '')

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.from(props.type === 'presidential' ? props.items.filter(item => item + '.political_party_name_' + props.locale.toLowerCase().includes(searchAreasStatistic.toLowerCase())
    ) : props.items.filter(item => item.political_party_name.toLowerCase().includes(searchAreasStatistic.toLowerCase())
    )).slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return(
        <div className='tour_results_political_parties'>
            <SearchBar
                searchEvent={(event) => {
                    setSearchAreasStatistic(event.target.value);
                }}
            />

            <div className="grid grid-cols-12 gap-5 text-gray-700 text-sm md:text-base font-extrabold pb-4">
                <div className="col-span-9 md:col-span-8">
                    <p>{ t('election_participants') }</p>
                </div>
                <div className="col-span-3 md:col-span-4">
                    <p>{ t('percentage') }</p>
                </div>
            </div>

            {currentItems.map(
                (item, index) => (
                <div className={`grid grid-cols-12 ${index !== currentItems.length - 1 ? "" : "pt-4 pb-0"} bg-gray-200 bg-opacity-30 px-3 py-2 my-3.5 rounded-md text-gray-700 text-sm md:text-base items-center gap-5 py-3 md:py-4`} key={index}>
                    <div className="col-span-9 md:col-span-8">
                        <div className="grid grid-cols-1 gap-5 items-center">
                            <div className="col-span-1 flex items-center">
                                <img src={item.img_path}
                                     alt={props.type === "presidential" ? item['political_party_name_' + props.locale] : item.political_party_name}
                                     className="mr-3 md:mr-4 w-6 h-6 md:w-8 md:h-8 object-fit"
                                />
                                <div className="grid grid-cols-1">
                                    <div className="col-span-1">
                                        <p className="font-bold">
                                            {props.type === "presidential" && item['candidate_name_' + props.locale]
                                                ?
                                                item['candidate_name_' + props.locale] + " - " + item['political_party_name_' + props.locale]
                                                :
                                                (props.type === "local" && props.municipality !== null && item.candidate_name)
                                                    ?
                                                    item.candidate_name + " - " + item.political_party_name
                                                    :
                                                    item.political_party_name
                                            }
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <p>{Number(item.total_party_votes).toLocaleString('de-DE')} - { t('votes') }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 md:col-span-4">
                        <div className="grid grid-cols-3 items-center">
                            <div className="col-span-3 md:col-span-1">
                                <p>{item.percentage}%</p>
                            </div>
                            <div className="hidden md:block col-span-2">
                                <div className="relative h-4 w-full bg-gray-200 rounded overflow-hidden">
                                    <div className="absolute h-full left-0 bg-red-500" style={{backgroundColor: item.party_color, width: item.percentage + "%"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            )}

            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={props.items.length}
                currentPage={currentPage}
                paginate={paginate}
                indexFirst={indexOfFirstItem}
                indexLast={indexOfLastItem}
            />
        </div>
    )
}

export default PoliticalParties;