import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Table.module.css";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";
import {FaArrowRight} from "react-icons/fa";

const TableDataTruncate = (props) => {
    const [truncateString, setTruncateString] = useState(true);

    const toggleTruncateString = () => {
        setTruncateString((prevState) => !prevState);
    };

    const truncate = (str, n) => {
        return truncateString ? str.substr(0, n - 1) + "..." : str;
    };

    return (
        <td className={props.className} onClick={toggleTruncateString}>
            {props.content.length < props.truncateLength
                ? props.content
                : truncate(props.content, props.truncateLength)}
        </td>
    );
};

const Table = ({ data, itemsPerPage, currentPage, page, tableHead, tour = false, tourClassName = '', tourIndex = 0 }) => {
    const { t } = useTranslation();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const handleClick = (value) => {
        localStorage.setItem("municipality", value);
    }

    return (
        <div>
            <div className="overflow-x-scroll rounded-xl border border-gray-200">
                <table className="w-full text-sm md:text-base">
                    <thead className={classes.tableRowHeader}>
                    <tr>
                        {tableHead.map((item,index) => {
                            return (
                                <th className={classes.tableHeader} key={index}>
                                    {t(item)}
                                </th>
                            )
                        })}
                        <th className={classes.tableHeader}/>
                    </tr>
                    </thead>
                    <tbody>
                    {data.items?.map((el, tableItemIndex) => (
                        <tr className={`${tour && tourIndex === tableItemIndex && tourClassName !== '' ? tourClassName : null} ` + classes.tableRowItems} key={el.id}>
                            <td className={classes.tableCell}><Link onClick={() => handleClick(el.municipality)} className="hover:text-red-700 underline font-bold transition duration-500" to={`/pollingStations/${el.id}`}>{el.name}</Link></td>
                            {el.municipality ? <td className={classes.tableCell}>{el.municipality}</td> : null}
                            {el.populated_area ? <td className={classes.tableCell}>{el.populated_area}</td> : null}
                            <td className={`pr-10 ${classes.tableCell}`}>{el.location && el.location}</td>
                            <td className={classes.tableCell}>{el.address && el.address}</td>
                            {el.registered_voters ? <td className={classes.tableCell}>{el.registered_voters}</td> : null}
                            {tableHead.includes("availability_information")?<TableDataTruncate
                                className={classes.tableCell}
                                content={el.availability_information || ""}
                                truncateLength={30}
                            />: null}
                            {el.total_registered_voters ? <td className={classes.tableCell}>{el.total_registered_voters}</td> : null}
                            {el.total_votes_valid ? <td className={classes.tableCell}>{el.total_votes_valid}</td> : null}
                            {el.total_votes_invalid ? <td className={classes.tableCell}>{el.total_votes_invalid}</td> : null}
                            <td>
                                <Link onClick={() => handleClick(el.municipality)} className="hover:cursor-pointer hover:text-red-700 text-lg transition duration-500" to={`/pollingStations/${el.id}`}>
                                    <FaArrowRight />
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {itemsPerPage ? <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={data.totalItems}
                currentPage={currentPage}
                paginate={page}
                indexFirst={indexOfFirstItem}
                indexLast={indexOfLastItem}
            /> : null}
        </div>
    );
};

export default Table;
