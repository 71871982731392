import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import Survey from "../components/Survey";
import InteractiveTour from "../components/interactiveTour/InteractiveTour";
import "./Layout.css";

const locales = {
  mk: { nativeName: "Македонски" },
  sq: { nativeName: "Shqip" },
};

function Layout(props) {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar locales={locales} />
      <main>
        {props.children}
        <Survey />
        <InteractiveTour />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
