import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp, FaSearch } from "react-icons/fa";
import { useParams } from "react-router";
import axios from "axios";
import Container from "../../components/Container";
import LoaderPageTitle from "../../components/loaders/LoaderPageTitle";
import LoaderTable from "../../components/loaders/LoaderTable";
import PageTitle from "../../components/PageTitle";
import RoundedBox from "../../components/RoundedBox";
import { useHttp } from "../../hooks/useHttp";
import SecondaryNavbarTabs from "../../layouts/navbar/SecondaryNavbarTabs";
import ElectionCandidatesTable from "./ElectionCandidatesTable";
import ExportExcel from '../../components/ExportExcel';

function ElectionCandidatesPage() {
  let { type, year, electionRound } = useParams();
  const { t, i18n } = useTranslation();

  const ref = useRef(null);

  const [toggleDropdown, setToggleDropdown] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState({
    candidate_name: "",
    candidate_list: "",
    municipality_id: "",
    constituency_id: ""
  });

  const {
    isLoading: isLoadingElection,
    error: errorElection,
    sendRequest: sendRequestElection,
    clearError: clearErrorElection,
  } = useHttp();

  const [election, setElection] = useState();
  const [candidates, setCandidates] = useState();
  const [municipalities, setMunicipalities] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const electionUrl = `${process.env.REACT_APP_API_URL}/election`;
  const electionRoundCandidatesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-list-mandates/list`;
  const electionRoundCandidatesExportUrl = `${process.env.REACT_APP_API_URL}/results/election-round-list-mandates/export`;
  const electionRoundCandidatesMunicipalitiesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-list-mandates/municipalities-list`;
  const electionRoundCandidatesConstituenciesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-list-mandates/constituencies-list`;

  useEffect(() => {
    const getMunicipalities = async () => {
      const municipalitiesData = await axios.get(electionRoundCandidatesMunicipalitiesUrl, {
        params: {
          locale: i18n.language,
          election_type: type,
          election_year: year
        }}
      );
      setMunicipalities(municipalitiesData.data.municipalities);
    };

    const getConstituencies = async () => {
      const constituenciesData = await axios.get(electionRoundCandidatesConstituenciesUrl, {
        params: {
          locale: i18n.language,
          election_type: type,
          election_year: year
        }}
      );
      setConstituencies(constituenciesData.data.constituencies);
    };

    if(type === 'parliamentary') {
      getConstituencies();
    } else {
      getMunicipalities();
    }
  }, [
    electionRoundCandidatesMunicipalitiesUrl,
    electionRoundCandidatesConstituenciesUrl,
    i18n.language,
    type,
    year
  ]);

  useEffect(() => {
    const getElection = async () => {
      const electionData = await sendRequestElection(
        electionUrl,
        i18n.language,
        type,
        year
      );
      setElection(electionData);
    };
    getElection();

    const getCandidates = async () => {
      const electionCandidatesData = await axios.get(
        electionRoundCandidatesUrl, {params: {
          locale: i18n.language,
          election_type: type,
          election_year: year,
          election_round_id: electionRound,
          candidate_name: search.candidate_name,
          candidate_list: search.candidate_list,
          ...(type === "parliamentary" ? { constituency_id: search.constituency_id } : { municipality_id: search.municipality_id }),
          page: currentPage
        }}
      );

      setCandidates(electionCandidatesData.data);
    };

    getCandidates();

    return () => {
      clearErrorElection();
    };
  }, [
    clearErrorElection,
    electionRound,
    electionRoundCandidatesUrl,
    electionUrl,
    i18n.language,
    sendRequestElection,
    type,
    year,
    search,
    currentPage
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        setToggleDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const tableHeaders = ["order_by_candidate_list", "name", "political_party"];
  if (type === "local" || type === "local-council") {
    tableHeaders.splice(2, 0, "municipality");
  } else if (type === "parliamentary") {
    tableHeaders.splice(2, 0, "constituency");
  } else if (type === "presidential") {
    tableHeaders.splice(0, 1);
  }

  const header =
    election && !isLoadingElection && !errorElection ? (
      <>
        <Helmet>
          <title>{`${t("candidates")} - ${election.name} - ${year}`}</title>
        </Helmet>
        <SecondaryNavbarTabs
          link={`electionType/${type}/electionYear/${year}${
            election.election_rounds &&
            `/electionRound/${election.election_rounds[0]?.id}`
          }`}
          dataType="candidates"
          type={type}
          year={year}
          renderCandidatesTab={true}
        />
        <Container>
          <PageTitle
            title={`${election.name} - ${year}`}
            link={`/electionType/${type}`}
          />
        </Container>
      </>
    ) : isLoadingElection && !candidates && !errorElection ? (
      <div className="mb-12">
        <Container>
          <LoaderPageTitle />
        </Container>
      </div>
    ) : null;

  const mainContent =
    candidates ? (
      <div className="my-12">
        <Container>
          {type === "local" || type === "local-council" ? (
              <div ref={ref} className="w-full md:w-1/2 lg:w-1/4 relative mb-8">
                <label className="w-full md:w-1/2 lg:w-1/4 mr-3">
                  {t("search_by_municipality")}
                </label>
                <select value={search.municipality_id} id="items" className="text-gray-700 font-bold text-sm shadow-md w-full bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 rounded-md px-1 py-2" onChange={(event) => {
                  setSearch((searchArray) => {
                    return { ...searchArray, municipality_id: event.target.value };
                  })
                }}>
                  {municipalities?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                  ))}
                </select>
              </div>
          ) : (type === "parliamentary" && (
              <div ref={ref} className="w-full md:w-1/2 lg:w-1/4 relative mb-8">
                <label className="w-full md:w-1/2 lg:w-1/4 mr-3">
                  {t("search_by_constituency")}
                </label>
                <select value={search.constituency_id} id="items" className="text-gray-700 font-bold text-sm shadow-md w-full bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 rounded-md px-1 py-2" onChange={(event) => {
                  setSearch((searchArray) => {
                    return { ...searchArray, constituency_id: event.target.value };
                  })
                }}>
                  {constituencies?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                  ))}
                </select>
              </div>
              )
          )}

          <RoundedBox tour tourClassName={"tour_election_candidates_page"}>
            {candidates.data && (<div className="flex justify-end mb-6">
              <ExportExcel
                  url={electionRoundCandidatesExportUrl}
                  locale={i18n.language}
                  type={type}
                  year={year}
                  electionRound={electionRound}
              />
            </div>)}
            <div className="flex items-end text-sm w-full mb-6">
              <label className="w-full md:w-1/2 lg:w-1/4 mr-3">
                {t("search_by_name")}
                <div
                  className={`h-8 mt-3 w-full transition-width duration-500 flex items-center rounded-full overflow-hidden bg-gray-100`}
                >
                  <FaSearch className="bg-gray-100 mx-3" />
                  <input
                    type="text"
                    placeholder={t("search_placeholder")}
                    onChange={(e) =>
                      setSearch((currState) => {
                        return { ...currState, candidate_name: e.target.value };
                      })
                    }
                    className={`w-full bg-inherit focus:outline-none`}
                  />
                </div>
              </label>

              <label htmlFor="search" className="w-full md:w-1/2 lg:w-1/4 mr-3">
                {t("search_by_political_party")}
                <div
                  className={`h-8 mt-3 w-full transition-width duration-500 flex items-center rounded-full overflow-hidden bg-gray-100`}
                >
                  <FaSearch className="bg-gray-100 mx-3" />
                  <input
                    type="text"
                    placeholder={t("search_placeholder")}
                    onChange={(e) =>
                      setSearch((currState) => {
                        return { ...currState, candidate_list: e.target.value };
                      })
                    }
                    className={`w-full bg-inherit focus:outline-none`}
                  />
                </div>
              </label>

              <div ref={ref} className="relative z-50 ml-auto">
                <button
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                  className="inline-flex items-center justify-between rounded-full bg-gray-100 px-3 py-1"
                >
                  {itemsPerPage}
                  {!toggleDropdown ? (
                    <FaChevronDown className="ml-2" />
                  ) : (
                    <FaChevronUp className="ml-2" />
                  )}
                </button>
                <ul
                  className={`${
                    toggleDropdown
                      ? "max-h-36 h-fit border border-gray-200 rounded-xl"
                      : "max-h-0 h-36"
                  } absolute transition-max-height duration-500 bg-white top-9 overflow-hidden`}
                >
                  <li
                    onClick={() => {
                      setItemsPerPage(10);
                      setToggleDropdown(false);
                    }}
                    className="hover:bg-red-700 hover:text-white transition duration-300 cursor-pointer px-3 py-1"
                  >
                    10
                  </li>
                  <li
                    onClick={() => {
                      setItemsPerPage(20);
                      setToggleDropdown(false);
                    }}
                    className="hover:bg-red-700 hover:text-white transition duration-300 cursor-pointer px-3 py-1"
                  >
                    20
                  </li>
                  <li
                    onClick={() => {
                      setItemsPerPage(50);
                      setToggleDropdown(false);
                    }}
                    className="hover:bg-red-700 hover:text-white transition duration-300 cursor-pointer px-3 py-1"
                  >
                    50
                  </li>
                  <li
                    onClick={() => {
                      setItemsPerPage(100);
                      setToggleDropdown(false);
                    }}
                    className="hover:bg-red-700 hover:text-white transition duration-300 cursor-pointer px-3 py-1"
                  >
                    100
                  </li>
                </ul>
              </div>
            </div>
            <ElectionCandidatesTable
              items={candidates.items}
              totalItems={candidates.totalItems}
              currentPage={candidates.currentPage}
              page={paginate}
              itemsPerPage={itemsPerPage}
              tableHead={tableHeaders}
            />
          </RoundedBox>
        </Container>
      </div>
    ) : !candidates ? (
        <div className="mb-12">
          <Container>
            <LoaderTable />
          </Container>
        </div>
    ) : null;

  return (
    <>
      {header}
      {mainContent}
    </>
  );
}

export default ElectionCandidatesPage;
