import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryNavbarTabs from "../../layouts/navbar/SecondaryNavbarTabs";
import Charts from "../../components/Charts";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import ExportExcel from "../../components/ExportExcel";
import SectionTitle from "../../components/SectionTitle";
import LoaderTabs from "../../components/loaders/LoaderTabs";
import LoaderButton from "../../components/loaders/LoaderButton";
import ElectionRoundsTabs from "../../components/ElectionRoundsTabs";
import LoaderPageTitle from "../../components/loaders/LoaderPageTitle";
import CapitalElectionLink from "../../components/CapitalElectionLink";
import StatisticsSection from "../../components/statistics/StatisticsSection";
import CandidatesListMandates from "../../components/candidatesLists/CandidatesListMandates";
import AreasStatisticsSection from "../../components/areasStatistics/AreasStatisticsSection";
import PoliticalPartiesResultsSection from "../../components/politicalParties/PoliticalPartiesResultsSection";
import { useHttp } from "../../hooks/useHttp";

function ElectionCapital() {
  let { dataType, type, year, electionRound, city } = useParams();
  const { t, i18n } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [election, setElection] = useState();
  const [electionPoliticalParties, setElectionPoliticalParties] = useState();

  const electionUrl = `${process.env.REACT_APP_API_URL}/election`;
  const electionRoundPoliticalPartiesUrl = `${process.env.REACT_APP_API_URL}/results/election-round-political-parties`;
  const electionExportUrl = `${process.env.REACT_APP_API_URL}/election-export`;

  useEffect(() => {
    const getElection = async () => {
      const electionData = await sendRequest(
        electionUrl,
        i18n.language,
        type,
        year
      );
      setElection(electionData);
    };

    const getElectionPoliticalParties = async () => {
      const electionPoliticalPartiesData = await sendRequest(
        electionRoundPoliticalPartiesUrl,
        i18n.language,
        type,
        year,
        electionRound,
        null,
        null,
        null,
        city
      );
      setElectionPoliticalParties(electionPoliticalPartiesData);
    };

    getElection();
    dataType === "results" && getElectionPoliticalParties();

    return () => {
      clearError();
    };
  }, [
    i18n.language,
    dataType,
    type,
    year,
    electionRound,
    city,
    sendRequest,
    electionUrl,
    electionRoundPoliticalPartiesUrl,
    clearError,
  ]);

  if (!election) return null;
  if (dataType === "results" && !electionPoliticalParties) return null;

  const statisticColumnsTitles = [
    "total_registered_voters",
    "total_turnout",
    "total_votes_valid",
    "total_votes_invalid",
    "total_municipalities",
    "total_polling_stations",
    dataType === "turnout" && "total_turnout_percentage",
  ];

  const areasStatisticColumnsTitles = [
    type === "parliamentary" ? "constituency" : "municipality",
    "total_registered_voters",
    "total_votes_invalid",
    dataType === "results" ? "total_polling_stations" : "total_turnout",
    type === "parliamentary"
      ? dataType === "results"
        ? "total_municipalities"
        : "total_turnout_percentage"
      : dataType === "results"
      ? "total_populated_areas"
      : "total_turnout_percentage",
  ];

  return (
    <div>
      <Helmet>
        <title>{`${t(dataType)} - ${election.name} - ${year} - ${t(
          "capital_city"
        )}`}</title>
      </Helmet>
      <SecondaryNavbarTabs
        link={`electionType/${type}/electionYear/${year}/electionRound/${electionRound}/city/${city}`}
        dataType={dataType}
        type={type}
        year={year}
        electionRound={electionRound}
        city={city}
      />
      <CapitalElectionLink
        dataType={dataType}
        type={type}
        year={year}
        electionRound={electionRound}
      />
      <Container>
        {isLoading && !error ? (
          <LoaderPageTitle />
        ) : (
          <PageTitle
            title={`${election.name} - ${year} - ${t("capital_city")}`}
            link={`/election/electionType/${type}`}
          />
        )}

        {type !== "parliamentary" && type !== "referendum" ? (
          isLoading && !error ? (
            <LoaderTabs />
          ) : (
            <ElectionRoundsTabs
              dataType={dataType}
              type={type}
              year={year}
              electionRound={electionRound}
              city={city}
              items={election.election_rounds}
            />
          )
        ) : null}

        <Charts
          locale={i18n.language}
          dataType={dataType}
          type={type}
          year={year}
          electionRound={electionRound}
          city={city}
        />

        {dataType === "results" && (
          <div className="my-8">
            {isLoading && !error ? (
              <div className="flex justify-end">
                <LoaderButton />
              </div>
            ) : (
              <ExportExcel
                url={electionExportUrl}
                locale={i18n.language}
                type={type}
                year={year}
                electionRound={electionRound}
                city={city}
              />
            )}
          </div>
        )}

        {dataType === "results" && type === "referendum" && (
          <div className="mt-8 mb-16">
            {isLoading && !error ? (
              <LoaderPageTitle />
            ) : (
              <SectionTitle title={`"${election.referendum_question}"`} />
            )}
          </div>
        )}

        {dataType === "results" && (
          <PoliticalPartiesResultsSection
            dataType={dataType}
            locale={i18n.language}
            type={type}
            year={year}
            electionRound={electionRound}
            city={city}
          />
        )}

        {dataType === "results" && type === "parliamentary" && (
          <CandidatesListMandates />
        )}

        {(dataType === "results" || dataType === "turnout") && (
          <StatisticsSection
            dataType={dataType}
            locale={i18n.language}
            type={type}
            year={year}
            electionRound={electionRound}
            city={city}
            titles={statisticColumnsTitles}
          />
        )}

        {(dataType === "results" || dataType === "turnout") && (
          <AreasStatisticsSection
            dataType={dataType}
            locale={i18n.language}
            type={type}
            year={year}
            electionRound={electionRound}
            city={city}
            link={`/election/${dataType}/electionType/${type}/electionYear/${year}/electionRound/${electionRound}${
              type === "parliamentary"
                ? "/constituency"
                : `/city/${city}/municipality`
            }`}
            titles={areasStatisticColumnsTitles}
          />
        )}
      </Container>
    </div>
  );
}

export default ElectionCapital;
