import { NavLink } from "react-router-dom";

function ElectoralBodiesTabItem({
  tourClassName,
  tour,
  tab,
  nameShort,
  nameLong,
}) {
  return (
    <div className={`${tour ? tourClassName : null} relative`}>
      <NavLink
        className={
          "group font-bold px-2 md:px-3 py-1 duration-300 z-20  transform -translate-x-1/2 mb-3 whitespace-nowrap rounded-md text-red-700"
        }
        to={`/electoral-bodies/${tab}`}
        style={({ isActive }) => {
          return {
            backgroundColor: isActive ? "#B91C1C" : "",
            borderRadius: isActive ? "4px" : "",
            color: isActive ? "#fff" : "",
          };
        }}
      >
        {nameShort}
        <p
          className={
            "group-hover:block hidden duration-300 z-20 left-1/2 transform -translate-x-1/2 mb-3 absolute bottom-full px-2 py-1 whitespace-nowrap rounded-md text-white bg-gray-700 text-xs md:text-sm"
          }
        >
          {nameLong}
        </p>
        <div
          className={
            "group-hover:block hidden duration-300 z-10 rotate-45 rounded-sm left-1/2 transform -translate-x-1/2 mb-2 absolute w-4 h-4 bottom-full bg-gray-700"
          }
        />
      </NavLink>
    </div>
  );
}

export default ElectoralBodiesTabItem;
