import {useEffect, useState} from "react";
import Joyride from 'react-joyride';
import {useLocation, useNavigate} from 'react-router-dom';
import {pages} from './Pages';
import {useTranslation} from "react-i18next";

function InteractiveTour(props) {

    const {t, i18n} = useTranslation();
    const [run, setRun] = useState(false);
    const [isTourActive, setIsTourActive] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const [steps, setSteps] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setSteps(pages.map((page, pageIndex) => {
            return page.steps.map(step => ({
                ...step,
                content: t(step.content),
                redirect_next: pageIndex + 1 in pages ? pages[pageIndex + 1].page : null,
                redirect_prev: pageIndex > 0 ? pages[pageIndex - 1].page : null
            }));
        }).flat());
    }, [i18n.language]);

    const handleCallback = data => {

        if (stepIndex === 0 && location.pathname !== pages[0].page) {
            navigate(pages[0].page);
            return;
        }

        if (data.status === 'skipped' || data.status === 'finished') {
            setRun(false);
            setIsTourActive(false);
            setStepIndex(0);
            return;
        }

        if (data.type === 'step:after' || data.type === 'error:target_not_found') {

            let currentStep = data.index;
            let nextStep = currentStep + (data.action === 'prev' ? -1 : 1);
            let pageToRedirect = null;

            setStepIndex(nextStep);

            if (steps.hasOwnProperty(nextStep)) {

                if (data.action === 'next' && steps[currentStep].redirect_next !== steps[nextStep].redirect_next) {
                    pageToRedirect = data.step.redirect_next;
                }

                if (data.action === 'prev' && steps[currentStep].redirect_prev !== steps[nextStep].redirect_prev) {
                    pageToRedirect = data.step.redirect_prev;
                }

                if (pageToRedirect !== null) {
                    setRun(false);
                    navigate(pageToRedirect);
                }

                if (!document.querySelectorAll(steps[nextStep].target).length) {
                    setRun(false);
                    let targetInterval = setInterval(() => {
                        if (document.querySelectorAll(steps[nextStep].target).length) {
                            setRun(true);
                            clearInterval(targetInterval);
                        }
                    }, 600);
                } else {
                    setTimeout(() => {
                        setRun(true);
                    }, 600);
                }
            }
        }
    }

    return (
        <>
            {!isTourActive &&
                <button
                    className='interactiveTourBtn'
                    onClick={() => {
                        setRun(true);
                        setIsTourActive(true);
                    }}
                    data-label={t('interactive_tour.title')}
                >
                    <img src={'/help.png'}/>
                </button>
            }

            <Joyride
                callback={handleCallback}
                continuous
                run={run}
                steps={steps}
                stepIndex={stepIndex}
                scrollOffset={100}
                showSkipButton
                disableCloseOnEsc
                disableOverlayClose
                hideCloseButton
                styles={{
                    options: {
                        primaryColor: '#b91c1c',
                    }
                }}
                locale={{
                    back: t('interactive_tour.back'),
                    last: t('interactive_tour.last'),
                    skip: t('interactive_tour.skip'),
                    next: t('interactive_tour.next'),
                    close: t('interactive_tour.close'),
                }}
            />
        </>
    );
}

export default InteractiveTour;
