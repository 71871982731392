import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useHttp } from "../../../hooks/useHttp";

function MapChartResults(props) {
  const {
    locale,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    city,
    dataType,
  } = props;

  const { sendRequest, clearError } = useHttp();

  const [areas, setAreas] = useState();
  const AreasUrl = `${process.env.REACT_APP_API_URL}/${dataType}/areas`;

  useEffect(() => {
    const getAreas = async () => {
      const areasData = await sendRequest(
        AreasUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        null,
        city ?? null
      );
      setAreas(areasData);
    };
    getAreas();
    return () => {
      clearError();
    };
  }, [
    locale,
    dataType,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    city,
    sendRequest,
    AreasUrl,
    clearError,
  ]);

  var data = areas;

  data?.forEach((el) => {
    el.path = el.border_path;
    el.value = el.id;
    el.color = el.party_color ?? "#e1e1e1";
    el.opacity = el.percentage / 100;
  });

  const options = {
    chart: {
      height: 80 + "%",
    },

    title: {
      text: "",
    },

    legend: {
      enabled: false,
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        theme: {
          fill: "#f1f1f1",
          "stroke-width": 0,
          stroke: "silver",
          r: 8,
          states: {
            hover: {
              fill: "#e5e5e5",
            },
            select: {
              stroke: "#039",
              fill: "red",
            },
          },
        },
        verticalAlign: "left",
      },
    },

    tooltip: {
      useHTML: true,
      whiteSpace: "normal",
      backgroundColor: "white",
      borderRadius: 10,
      formatter: function () {
        return [
          '<div class="text-center"><p class="px-3 my-2" style="font-size: 16px;"><u><b>' +
            this.point.name +
            "</b></u></p></div>",
        ].concat(
          this.point.political_parties
            ? this.point.political_parties.map(function (politicalParty) {
                if (type === "parliamentary") {
                  return (
                    '<div class="flex items-center px-3 my-2">' +
                    politicalParty.political_party +
                    ": <b>" +
                    Number(politicalParty.votes).toLocaleString("de-DE") +
                    "</b><br></div>"
                  );
                } else if (type === "referendum") {
                  return (
                    '<div class="flex items-center px-3 my-2">' +
                    politicalParty.candidate_name +
                    ": <b>" +
                    Number(politicalParty.votes).toLocaleString("de-DE") +
                    "</b><br></div>"
                  );
                } else {
                  if (type === "local") {
                    if (municipality) {
                      return (
                        '<div class="flex items-center px-3 my-2">' +
                        politicalParty.political_party +
                        " - " +
                        politicalParty.candidate_name +
                        ": <b>" +
                        Number(politicalParty.votes).toLocaleString("de-DE") +
                        "</b><br></div>"
                      );
                    }
                    return (
                      '<div class="flex items-center px-3 my-2">' +
                      politicalParty.political_party +
                      ": <b>" +
                      Number(politicalParty.votes).toLocaleString("de-DE") +
                      "</b><br></div>"
                    );
                  } else {
                    return (
                      '<div class="flex items-center px-3 my-2">' +
                      politicalParty.political_party +
                      " - " +
                      politicalParty.candidate_name +
                      ": <b>" +
                      Number(politicalParty.votes).toLocaleString("de-DE") +
                      "</b><br></div>"
                    );
                  }
                }
              })
            : ""
        );
      },
      shared: true,
    },

    series: [
      {
        type: "map",
        name: "North Macedonia",
        joinBy: "id",
        data: data,
        cursor: "pointer",
        borderColor: "silver",
        states: {
          hover: {
            borderColor: "gray",
          },
        },
        events: {
          click: function (e) {
            if (type === "parliamentary") {
              if (constituency) {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/election/" +
                  dataType +
                  "/electionType/" +
                  type +
                  "/electionYear/" +
                  year +
                  "/electionRound/" +
                  electionRound +
                  "/constituency/" +
                  constituency +
                  "/municipality/" +
                  e.point.id);
              }
              return (document.location.href =
                process.env.REACT_APP_HOST +
                "/election/" +
                dataType +
                "/electionType/" +
                type +
                "/electionYear/" +
                year +
                "/electionRound/" +
                electionRound +
                "/constituency/" +
                e.point.id);
            } else {
              if (city) {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/election/" +
                  dataType +
                  "/electionType/" +
                  type +
                  "/electionYear/" +
                  year +
                  "/electionRound/" +
                  electionRound +
                  "/city/" +
                  city +
                  "/municipality/" +
                  e.point.id);
              } else {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/election/" +
                  dataType +
                  "/electionType/" +
                  type +
                  "/electionYear/" +
                  year +
                  "/electionRound/" +
                  electionRound +
                  "/municipality/" +
                  e.point.id);
              }
            }
          },
        },
      },
    ],

    credits: {
      enabled: false,
    },
  };

  if (!areas) return null;

  return (
    <HighchartsReact
      options={options}
      constructorType={"mapChart"}
      highcharts={Highcharts}
    />
  );
}

export default MapChartResults;
