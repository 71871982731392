import React from "react";
import {FaFileExcel} from "react-icons/fa";
import {useTranslation} from "react-i18next";

function ExportExcel(props) {
    const {t} = useTranslation();

    return (
        <div className="grid grid-cols-1">
            <div className="col-span-1 flex justify-end">
                {props.constituency ?
                    <a className="tour_export_excel text-xs md:text-base text-white hover:text-green-600 bg-green-600 hover:bg-white transition duration-300 font-medium border border-green-600 px-3 py-2 md:px-2 md:py-1 w-full md:w-fit flex items-center justify-center rounded-lg"
                       href={`${props.url}?locale=${props.locale}&election_type=${props.type}&election_year=${props.year}&election_round_id=${props.electionRound}&constituency_id=${props.constituency ?? ''}&municipality_id=${props.municipality ?? ''}&polling_station_id=${props.pollingStation ?? ''}`}
                    >
                        <FaFileExcel className="text-xl mr-2"/>
                        {t('export_excel')}
                    </a>
                    :
                    props.city ?
                        <a className="tour_export_excel text-xs md:text-base text-white hover:text-green-600 bg-green-600 hover:bg-white transition duration-300 font-medium border border-green-600 px-3 py-2 md:px-2 md:py-1 w-full md:w-fit flex items-center justify-center rounded-lg"
                           href={`${props.url}?locale=${props.locale}&election_type=${props.type}&election_year=${props.year}&election_round_id=${props.electionRound}&city_id=${props.city ?? ''}&municipality_id=${props.municipality ?? ''}&polling_station_id=${props.pollingStation ?? ''}`}
                        >
                            <FaFileExcel className="text-xl mr-2"/>
                            {t('export_excel')}
                        </a>
                        :
                        <a className="tour_export_excel text-xs md:text-base text-white hover:text-green-600 bg-green-600 hover:bg-white transition duration-300 font-medium border border-green-600 px-3 py-2 md:px-2 md:py-1 w-full md:w-fit flex items-center justify-center rounded-lg"
                           href={`${props.url}?locale=${props.locale}&election_type=${props.type}&election_year=${props.year}&election_round_id=${props.electionRound}&municipality_id=${props.municipality ?? ''}&polling_station_id=${props.pollingStation ?? ''}`}
                        >
                            <FaFileExcel className="text-xl mr-2"/>
                            {t('export_excel')}
                        </a>
                }
            </div>
        </div>
    )
}

export default ExportExcel;