import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../../hooks/useHttp";

function MapChartTurnout(props) {
  const { t } = useTranslation();

  const {
    locale,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    city,
    dataType,
  } = props;

  const [areas, setAreas] = useState();
  const AreasUrl = `${process.env.REACT_APP_API_URL}/${dataType}/areas`;

  const { sendRequest, clearError } = useHttp();

  useEffect(() => {
    const getAreas = async () => {
      const areasData = await sendRequest(
        AreasUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        null,
        city ?? null
      );
      setAreas(areasData);
    };
    getAreas();

    return () => {
      clearError();
    };
  }, [
    locale,
    dataType,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    city,
    sendRequest,
    AreasUrl,
    clearError,
  ]);

  if (!areas) return null;

  var data = areas;

  data?.forEach((el) => {
    el.path = el.border_path;
    el.value = el.total_turnout_percentage;
  });

  const options = {
    chart: {
      height: 80 + "%",
    },

    title: {
      text: "",
    },

    colorAxis: {
      min: 0,
      max: 100,
      minColor: "#E6E7E8",
      maxColor: "#4f7388",
      width: "70%",
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        theme: {
          fill: "#f1f1f1",
          "stroke-width": 0,
          stroke: "silver",
          r: 8,
          states: {
            hover: {
              fill: "#e5e5e5",
            },
            select: {
              stroke: "#039",
              fill: "red",
            },
          },
        },
        verticalAlign: "left",
      },
    },

    tooltip: {
      useHTML: true,
      whiteSpace: "normal",
      backgroundColor: "white",
      borderRadius: 10,
      formatter: function () {
        return (
          '<div class="text-center"><p class="px-3 my-2" style="font-size: 16px;"><u><b>' +
          this.point.name +
          "</b></u></p></div>" +
          '<div class="flex items-center px-3 my-2">' +
          t("total_turnout_percentage") +
          ": <b>" +
          this.point.value +
          "%</b><br></div>" +
          '<div class="flex items-center px-3 my-2">' +
          t("total_turnout") +
          ": <b>" +
          this.point.total_turnout.toLocaleString("de-DE") +
          "</b><br></div>" +
          '<div class="flex items-center px-3 my-2">' +
          t("total_votes_valid") +
          ": <b>" +
          this.point.total_votes_valid.toLocaleString("de-DE") +
          "</b><br></div>" +
          '<div class="flex items-center px-3 my-2">' +
          t("total_votes_invalid") +
          ": <b>" +
          this.point.total_votes_invalid.toLocaleString("de-DE") +
          "</b><br></div>"
        );
      },
      shared: true,
    },

    series: [
      {
        type: "map",
        name: "North Macedonia",
        joinBy: "id",
        data: data,
        cursor: "pointer",
        borderColor: "silver",
        states: {
          hover: {
            borderColor: "gray",
          },
        },
        events: {
          click: function (e) {
            if (type === "parliamentary") {
              if (constituency) {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/election/" +
                  dataType +
                  "/electionType/" +
                  type +
                  "/electionYear/" +
                  year +
                  "/electionRound/" +
                  electionRound +
                  "/constituency/" +
                  constituency +
                  "/municipality/" +
                  e.point.id);
              }
              return (document.location.href =
                process.env.REACT_APP_HOST +
                "/election/" +
                dataType +
                "/electionType/" +
                type +
                "/electionYear/" +
                year +
                "/electionRound/" +
                electionRound +
                "/constituency/" +
                e.point.id);
            } else {
              if (city) {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/election/" +
                  dataType +
                  "/electionType/" +
                  type +
                  "/electionYear/" +
                  year +
                  "/electionRound/" +
                  electionRound +
                  "/city/" +
                  city +
                  "/municipality/" +
                  e.point.id);
              } else {
                return (document.location.href =
                  process.env.REACT_APP_HOST +
                  "/election/" +
                  dataType +
                  "/electionType/" +
                  type +
                  "/electionYear/" +
                  year +
                  "/electionRound/" +
                  electionRound +
                  "/municipality/" +
                  e.point.id);
              }
            }
          },
        },
      },
    ],

    credits: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      options={options}
      constructorType={"mapChart"}
      highcharts={Highcharts}
    />
  );
}

export default MapChartTurnout;
