import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";

import classes from "../../utils/helpers/Table.module.css";

function ElectionCandidatesTable({
  items,
  totalItems,
  currentPage,
  page,
  itemsPerPage,
  tableHead
}) {
  const { t } = useTranslation();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  return (
    <div>
      <table className={classes.table}>
        <thead className={classes.tableRowHeader}>
          <tr>
            {tableHead.map((item, index) => {
              return (
                <th className={classes.tableHeader} key={index}>
                  {t(item)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((el) => (
            <tr className={classes.tableRowItems} key={el.id}>
              {el.order && <td className={classes.tableCell}>{el.order}</td>}
              <td className={classes.tableCell}>{el.candidate_name}</td>
              {el.municipality && (
                <td className={classes.tableCell}>{el.municipality}</td>
              )}
              {el.constituency && (
                <td className={classes.tableCell}>{el.constituency}</td>
              )}
              <td className={classes.tableCell}>{el.candidate_list}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {itemsPerPage ? (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          paginate={page}
          indexFirst={indexOfFirstItem}
          indexLast={indexOfLastItem}
        />
      ) : null}
    </div>
  );
}

export default ElectionCandidatesTable;
