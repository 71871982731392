import Container from "./Container";
import {Link, useParams} from "react-router-dom";
import {FaArrowRight} from "react-icons/fa";
import {useTranslation} from "react-i18next";

function CapitalElectionLink(props) {
    let { city } = useParams();
    const { t } = useTranslation();

    return (
        props.type === 'local' &&
        <div className="bg-red-800 py-2">
            <Container>
                <div className="grid grid-cols-1">
                    <div className="col-span-1">
                        <Link
                            className="flex items-center justify-center md:justify-start text-sm md:text-base text-white hover:underline font-bold"
                            to={`/election/${props.dataType}/electionType/${props.type}/electionYear/${props.year}/electionRound/${props.electionRound}${!city ? "/city/1" : ""}`}
                        >
                            {!city ? t('capital_city') : t('mayors')}
                            <FaArrowRight className="ml-2"/>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default CapitalElectionLink;