import React from "react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

function ItemsPerPageDropdown(props) {
    const numbers = [10, 20, 50, 100];

    return (
        <div className="grid grid-cols-1">
            <div className="col-span-1 flex justify-end relative z-10">
                <button
                    onClick={() => props.setToggleDropdown(!props.toggleDropdown)}
                    className="inline-flex items-center justify-between rounded-md bg-gray-100 px-3 py-1"
                >
                    {props.itemsPerPage}
                    {!props.toggleDropdown ? (
                        <FaChevronDown className="ml-2" />
                    ) : (
                        <FaChevronUp className="ml-2" />
                    )}
                </button>
                <ul
                    className={`${
                        props.toggleDropdown
                            ? "max-h-36 h-fit border border-gray-200 rounded-md"
                            : "max-h-0 h-36"
                    } absolute transition-max-height duration-500 bg-white top-9 overflow-hidden`}
                >
                    {numbers.map((item, index) =>
                        <li
                            key={index}
                            onClick={() => {
                                props.setItemsPerPage(item);
                                props.setToggleDropdown(false);
                            }}
                            className="hover:bg-red-700 hover:text-white transition duration-300 cursor-pointer px-3 py-1"
                        >{item}</li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default ItemsPerPageDropdown