import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import LoaderTable from "../loaders/LoaderTable";
import SectionTitle from "../SectionTitle";
import RoundedBox from "../RoundedBox";
import { useHttp } from "../../hooks/useHttp";

function StatisticsSection(props) {
  const { t } = useTranslation();

  const {
    locale,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    dataType,
    titles
  } = props;

  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [electionStatistic, setElectionStatistic] = useState();

  const electionRoundStatisticsUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-statistic`;

  useEffect(() => {
    const getElectionStatistics = async () => {
      const electionStatisticsData = await sendRequest(
        electionRoundStatisticsUrl,
        locale,
        type,
        year,
        electionRound,
        constituency ?? null,
        municipality ?? null,
        pollingStation ?? null,
        city ?? null
      );
      setElectionStatistic(electionStatisticsData);
    };
    (dataType === "results" || dataType === "turnout") &&
      getElectionStatistics();

    return ()=>{
        clearError();
    }  
  }, [locale, dataType, type, year, electionRound, constituency, municipality, pollingStation, city, sendRequest, electionRoundStatisticsUrl, clearError]);

  // Once we got the detailed data for presidential election 2019 we can remove this code
  const statisticColumnsTitles = [
    "total_registered_voters",
    "total_turnout",
    "total_votes_valid",
    "total_votes_invalid",
    "total_municipalities",
    "total_polling_stations",
    dataType === "turnout" && "total_turnout_percentage",
  ];

  const statisticColumnsValuesFirstRound = [
    "1.808.131",
    "753.520",
    "720.810",
    "32.696",
    "80",
    "3.480",
    dataType === "turnout" && "41.67",
  ];

  const statisticColumnsValuesSecondRound = [
    "1.808.131",
    "843.508",
    "813.101",
    "30.407",
    "80",
    "3.480",
    dataType === "turnout" && "46.65",
  ];

  if ((dataType === "results" || dataType === "turnout") && !electionStatistic)
    return (
      (type === 'presidential' && year === '2019') ?
      <div className="mb-20">
        <SectionTitle title={t("results_statistics")} />
        <RoundedBox>
          <div className="grid grid-cols-2 gap-3.5 text-sm md:text-base">
            {statisticColumnsTitles.map(
                (title, index) =>
                    title && (
                        <div
                            key={index}
                            className="col-span-2 md:col-span-1 px-3 py-2 text-gray-700 bg-gray-200 bg-opacity-30 rounded-md"
                        >
                          <div className="grid grid-cols-3 gap-5">
                            <div className="col-span-2">
                              <p>{t(title)}</p>
                            </div>
                            <div className="col-span-1 flex justify-end">
                              <p className="font-extrabold">
                                {title === "total_turnout_percentage"
                                    ? electionRound === "27" ? statisticColumnsValuesFirstRound[index] : statisticColumnsValuesSecondRound[index] + "%"
                                    : electionRound === "27" ? statisticColumnsValuesFirstRound[index] : statisticColumnsValuesSecondRound[index] &&
                                      electionRound === "27" ? statisticColumnsValuesFirstRound[index].toLocaleString : statisticColumnsValuesSecondRound[index].toLocaleString(
                                        "de-DE"
                                    )}
                              </p>
                            </div>
                          </div>
                        </div>
                    )
            )}
          </div>
        </RoundedBox>
      </div>
        :
        null
  );

  return (
    <div className="mt-8 mb-16">
      {isLoading && !error ? (
        <LoaderTable />
      ) : (
        <div>
          <SectionTitle title={t("results_statistics")} />
          <RoundedBox>
            <div className="grid grid-cols-2 gap-3.5 text-sm md:text-base">
              {titles.map(
                (title, index) =>
                  title && (
                    <div
                      key={index}
                      className="col-span-2 md:col-span-1 px-3 py-2 text-gray-700 bg-gray-200 bg-opacity-30 rounded-md"
                    >
                      <div className="grid grid-cols-3 gap-5">
                        <div className="col-span-2">
                          <p>{t(title)}</p>
                        </div>
                        <div className="col-span-1 flex justify-end">
                          <p className="font-extrabold">
                            {title === "total_turnout_percentage"
                              ? electionStatistic[title] + "%"
                              : electionStatistic[title] &&
                                electionStatistic[title].toLocaleString(
                                  "de-DE"
                                )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </RoundedBox>
        </div>
      )}
    </div>
  );
}

export default StatisticsSection;
