import RoundedBox from "../RoundedBox";

function LoaderTable() {
    return (
        <div>
            <div className="animate-pulse h-6 bg-slate-200 rounded-xl w-1/3 mt-8 mb-4 md:my-8"/>
            <RoundedBox>
            <div className="w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="grid grid-cols-4 bg-slate-100 p-3 rounded-xl gap-4">
                            <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                        </div>
                        <div className="space-y-5 bg-slate-100 p-3 rounded-xl">
                            <div className="grid grid-cols-4 gap-4">
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                                <div className="h-5 bg-slate-200 rounded-xl col-span-1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </RoundedBox>
        </div>
    )
}

export default LoaderTable;