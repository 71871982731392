import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useParams } from "react-router";
import Container from "../../../components/Container";
import RoundedBox from "../../../components/RoundedBox";
import { useHttp } from "../../../hooks/useHttp";
import PageTitle from "../../../components/PageTitle";

import styles from "./PollingStation.module.css";
import ReactMap from "../Map/ReactMap";

const PollingStation = () => {
  const pollingStationId = useParams().pollingStationId;
  const pollingStationsUrl =
    `${process.env.REACT_APP_API_URL}/polling-station-information/` + pollingStationId;
  const { i18n, t } = useTranslation();

  const [pollingStation, setPollingStation] = useState(null);

  const { isLoading, error, sendRequest, clearError } = useHttp();

  useEffect(() => {
    const getPollingStation = async () => {
      const pollingStationsData = await sendRequest(
        pollingStationsUrl,
        i18n.language
      );
      setPollingStation(pollingStationsData);
    };

    getPollingStation();
    return () => {
      clearError();
    };
  }, [
    clearError,
    i18n.language,
    pollingStationId,
    pollingStationsUrl,
    sendRequest,
  ]);

  const renderedItems = [];

  for (let i = 1; i <= 39; i++) {
    // Push a JSX element for each item into the array
    renderedItems.push(
        <div className="col-span-1 text-xs md:text-base text-gray-700 font-normal mb-6" key={i}>
          <p className="mb-2 font-bold">{t("polling_station_availability_information.information_" + i)}</p>
          <p>- {pollingStation?.availability_information['information' + i]}</p>
        </div>
    )
  }

  return (
    <React.Fragment>
      <Container>
        {isLoading && !error && !pollingStation && (
          <div className="col-span-12 text-center py-8">
            <AiOutlineLoading3Quarters className="mx-auto animate-spin w-8 h-8" />
          </div>
        )}
        {!isLoading && error && !pollingStation && <p>{error}</p>}
        {!isLoading && !error && pollingStation && (
          <div>
            <PageTitle
              title={`${t("polling_station")} : ${pollingStation.name}`}
              link={`/pollingStations`}
            />
            <div className="grid grid-cols-12 gap-3 mt-12">
              <div
                className={`${
                  pollingStation.address ? "col-span-12 md:col-span-6 " : "col-span-12"
                }  bg-white text-xs md:text-base`}
              >
                <RoundedBox>
                  <h6 className="text-gray-900 pb-2">
                    {t("municipality") + ": "}
                    <span className="font-bold">
                      {pollingStation.municipality}
                    </span>
                  </h6>
                  <h6 className="text-gray-900 py-2">
                    {t("populated_area") + ": "}
                    <span className="font-bold">
                      {pollingStation.populated_area}
                    </span>
                  </h6>
                  <h6 className="text-gray-900 py-2">
                    {t("location") + ": "}
                    <span className="font-bold">
                      {pollingStation.location && pollingStation.location}
                    </span>
                  </h6>
                  <h6 className="text-gray-900 py-2">
                    {t("address") + ": "}
                    <span className="font-bold">
                      {pollingStation.address && pollingStation.address + " " + pollingStation.address_number + " " + pollingStation.address_addition}
                    </span>
                  </h6>
                  {(pollingStation.letter_from && pollingStation.letter_to) &&
                  <h6 className="text-gray-900 pt-2">
                    {t("voters_from_to") + ": "}
                    <span className="font-bold">
                      {pollingStation.letter_from} - {pollingStation.letter_to}
                    </span>
                  </h6>
                  }
                </RoundedBox>
              </div>

              {pollingStation.address && (
                <div className="col-span-12 md:col-span-6 border overflow-hidden bg-white shadow-lg rounded-xl relative">
                  <ReactMap
                    municipality={pollingStation.municipality}
                    address={pollingStation.address}
                    location={pollingStation.location}
                    addressNumber={pollingStation.address_number}
                  />
                </div>
              )}

              {pollingStation.covered_regions && (
                <div className="col-span-12 py-8">
                  <RoundedBox>
                    <div className="grid grid-cols-12 mb-3 text-xs md:text-sm">
                      <div className="col-span-2">
                        <b>{t("populated_area")}</b>
                      </div>
                      <div className="col-span-3">
                        <b>{t("street")}</b>
                      </div>
                      <div className="col-span-3">
                        <b>{t("description")}</b>
                      </div>
                      <div className="col-span-2">
                        <b>{t("number_from")}</b>
                      </div>
                      <div className="col-span-2">
                        <b>{t("number_to")}</b>
                      </div>
                    </div>
                    {pollingStation.covered_regions.map(region =>
                        <div className="grid grid-cols-12 text-xs md:text-sm" key={region.id}>
                          <div className={`col-span-2 text-gray-700 font-normal [&>*]:w-full ${styles.description}`}>
                            {region.street_populated_area && region.street_populated_area}
                          </div>
                          <div className={`col-span-3 text-gray-700 font-normal [&>*]:w-full ${styles.description}`}>
                            {region.street && region.street}
                          </div>
                          <div className={`col-span-3 text-gray-700 font-normal [&>*]:w-full ${styles.description}`}>
                            {region.description && region.description}
                          </div>
                          <div className={`col-span-2 text-gray-700 font-normal [&>*]:w-full ${styles.description}`}>
                            {region.number_from && region.number_from}
                          </div>
                          <div className={`col-span-2 text-gray-700 font-normal [&>*]:w-full ${styles.description}`}>
                            {region.number_to && region.number_to === '9999' ? t("end") : region.number_to}
                          </div>
                        </div>
                    )}
                  </RoundedBox>
                </div>
              )}

              {pollingStation.availability_information &&
                  <div className="col-span-12 mb-16">
                    <RoundedBox>
                      <h4 className="sm:text-2xl xl:text-3xl font-extrabold leading-tight mb-10">
                        {t("availability_information")}
                      </h4>
                      <div className="grid grid-cols-1">
                        {renderedItems}
                      </div>
                    </RoundedBox>
                  </div>
              }
            </div>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default PollingStation;
