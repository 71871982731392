function LoaderElectionList() {
    return (
        <div className="p-5 mb-8 bg-slate-100 rounded-xl">
            <div className="animate-pulse h-6 bg-slate-200 rounded-xl w-1/2 md:w-1/3 mb-8"/>
            <div className="animate-pulse grid grid-cols-12 rounded-xl mx-auto text-center gap-5">
                <div className="bg-slate-200 px-5 md:px-5 md:py-8 lg:py-12 rounded-xl col-span-12 sm:col-span-6 md:col-span-4"/>
                <div className="bg-slate-200 px-5 md:px-5 md:py-8 lg:py-12 rounded-xl col-span-12 sm:col-span-6 md:col-span-4"/>
                <div className="bg-slate-200 px-5 md:px-5 md:py-8 lg:py-12 rounded-xl col-span-12 sm:col-span-6 md:col-span-4"/>
                <div className="bg-slate-200 px-5 md:px-5 md:py-8 lg:py-12 rounded-xl col-span-12 sm:col-span-6 md:col-span-4"/>
                <div className="bg-slate-200 px-5 md:px-5 md:py-8 lg:py-12 rounded-xl col-span-12 sm:col-span-6 md:col-span-4"/>
                <div className="bg-slate-200 px-5 md:px-5 md:py-8 lg:py-12 rounded-xl col-span-12 sm:col-span-6 md:col-span-4"/>
            </div>
        </div>
    )
}

export default LoaderElectionList;