import React  from "react";
import classes from "../electoralRollTable/ElectoralRollTable.module.css";
import Pagination from "../../../components/Pagination";
import { useTranslation } from "react-i18next";
import {
  FaLongArrowAltDown,
  FaLongArrowAltRight,
  FaLongArrowAltUp,
} from "react-icons/fa";

function ElectoralRollComparisonTable({
  data,
  totalVoters,
  totalMaleVoters,
  totalFemaleVoters,
  itemsPerPage,
  totalItems,
  tableHead,
  currentPage,
  setCurrentPage
}) {
  const { t } = useTranslation();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function calcPc(n1, n2) {
    const percentageChange = Math.abs((n2 - n1) / n1 * 100);
    return (
        percentageChange.toFixed(2) + "%"
    );
  }

  function getIcon(number) {
    const convertedNumber = +number;

    if (Math.sign(convertedNumber) === 1) {
      return <FaLongArrowAltUp />;
    } else if (Math.sign(convertedNumber) === -1) {
      return <FaLongArrowAltDown />;
    } else if (Math.sign(convertedNumber) === 0) {
      return <FaLongArrowAltRight />;
    }
  }

  return (
    <div>
      <div className="overflow-x-scroll rounded-xl border border-gray-200">
        <table className="w-full text-sm md:text-base">
          <thead className={classes.tableRowHeader}>
            <tr>
              {tableHead.map((item, index) => {
                return (
                  <th
                    className={`${classes.tableHeader} ${
                      item.includes(t("female_voters"))
                        ? classes.tableCellSeparatorLeft
                        : item.includes(t("total_registered_voters"))
                        ? classes.tableCellSeparatorRight
                        : ""
                    }`}
                    key={index}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th className={classes.tableTotalHeader}>{t("total")} : </th>
              <th className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorLeft}`}/>
              <th className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorLeft}`}/>
              <th
                className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorLeft}`}
              >
                {totalFemaleVoters[0]}
              </th>
              <th className={classes.tableTotalHeader}>
                {totalMaleVoters[0]}
              </th>
              <th
                className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorRight}`}
              >
                {totalVoters[0]}
              </th>
              <th className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorLeft}`}/>
              <th className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorLeft}`}/>
              <th
                className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorLeft}`}
              >
                {totalFemaleVoters[1]}
              </th>
              <th className={classes.tableTotalHeader}>
                {totalMaleVoters[1]}
              </th>
              <th
                className={`${classes.tableTotalHeader} ${classes.tableCellSeparatorRight}`}
              >
                {totalVoters[1]}
              </th>
              <th className={classes.tableCell}>
                <span className="flex items-center justify-around">
                  {getIcon(
                    calcPc(
                        totalVoters[0],
                        totalVoters[1]
                    ).replace("%", "")
                  )}
                  {calcPc(totalVoters[0], totalVoters[1])}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((el, index) => {
              return (
                <tr className={classes.tableRowItems} key={index}>
                  {<td className={`${classes.tableCell} ${classes.tableTotalHeader}`}>{el[0]}</td>}
                  {el[1].map((item) => {
                    return (
                      <>
                        <td className={`${classes.tableCell} ${classes.tableCellSeparatorLeft}`}>{item.municipality}</td>
                        <td className={`${classes.tableCell} ${classes.tableCellSeparatorLeft}`}>{item.populated_area}</td>
                        <td className={`${classes.tableCell} ${classes.tableCellSeparatorLeft}`}>{item.female}</td>
                        <td className={`${classes.tableCell} ${classes.tableCellSeparatorLeft}`}>{item.male}</td>
                        <td className={`${classes.tableCell} ${classes.tableCellSeparatorLeft} ${classes.tableCellSeparatorRight}`}>{item.total}</td>
                      </>
                    );
                  })}
                  {
                    <td className={classes.tableCell}>
                      <span className="flex items-center justify-around">
                        {getIcon(
                          calcPc(...el[1].map((item) => item.total)).replace(
                            "%",
                            ""
                          )
                        )}
                        {calcPc(...el[1].map((item) => item.total))}
                      </span>
                    </td>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {itemsPerPage ? (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          paginate={paginate}
          indexFirst={indexOfFirstItem}
          indexLast={indexOfLastItem}
        />
      ) : null}
    </div>
  );
}

export default ElectoralRollComparisonTable;
