import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RoundedBox from "../../components/RoundedBox";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <RoundedBox>
      <div className="flex justify-center items-start p-12 h-screen bg-white-600">
        <div className="col-span-1 rounded-box rounded-xl p-14">
          <h1 className="lg:text-4xl font-bold text-2xl text-red-700">
            {t("not_found.oops")}
          </h1>
          <p className="text-xl text-red-700 mb-8">
            {t("not_found.page_not_found_description")}
          </p>

          <div className="mt-4">
            <Link
              to="/"
              className="px-5 py-2 bg-red-700 text-white rounded-md hover:bg-red-900"
            >
              {t("not_found.go_home_link")}
            </Link>
          </div>
        </div>
      </div>
    </RoundedBox>
  );
}
