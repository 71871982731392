import React from "react";
import { useTranslation } from "react-i18next";

const ElectionComparisonResultTab = (props) => {
  const { t } = useTranslation();

  return (
    <div className="col-span-12 pb-5">
      <div className="flex items-center justify-between font-bold gap-3 mb-4">
        <p>{t("political_party")}</p>
        <p>{t("percentage")}</p>
      </div>
      {props.electionPoliticalParties?.map((item) => (
        <div key={item.id} className="grid grid-cols-1">
          <div className="col-span-1 flex justify-between items-center gap-3 my-3">
            <div className="flex items-center">
              {props.selectedElection?.type !== "referendum" && (
                <img
                  className="h-5 w-5 mr-2 object-contain"
                  src={item.img_path}
                  alt={item.political_party_name}
                />
              )}
              <div>
                {props.selectedElection?.type === "referendum" ? (
                  <p>{item.candidate_name}</p>
                ) : props.selectedElection?.type === "presidential" ? (
                  <p>
                    {item.political_party_name} - {item.candidate_name}
                  </p>
                ) : (
                  <p>{item.political_party_name}</p>
                )}
                <p>{Number(item.total_party_votes).toLocaleString("de-DE")}</p>
              </div>
            </div>
            <p>{item.percentage}%</p>
          </div>
          <div className="col-span-1">
            <div className="relative h-4 w-full bg-gray-200 rounded overflow-hidden">
              <div
                className={`${
                  item.candidate_name === t("yes")
                    ? "bg-emerald-500"
                    : "bg-red-500"
                } absolute h-full left-0`}
                style={{
                  backgroundColor: item.party_color,
                  width: item.percentage + "%",
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ElectionComparisonResultTab;
