import {Link} from "react-router-dom";

function ElectionRoundsTabs(props) {
    const linkPrefix = `/election/${props.dataType}/electionType/${props.type}/electionYear/${props.year}/electionRound`;

    const tabLink = (itemId) => {
        if(props.city) {
            if(props.municipality) {
                if(props.pollingStation) {
                    return `${linkPrefix}/${itemId}/city/${props.city}/municipality/${props.municipality}/pollingStation/${props.pollingStation}`
                } else {
                    return `${linkPrefix}/${itemId}/city/${props.city}/municipality/${props.municipality}`
                }
            } else {
                return `${linkPrefix}/${itemId}/city/${props.city}`
            }
        } else {
            if(props.municipality) {
                if(props.pollingStation) {
                    return `${linkPrefix}/${itemId}/municipality/${props.municipality}/pollingStation/${props.pollingStation}`
                } else {
                    return `${linkPrefix}/${itemId}/municipality/${props.municipality}`
                }
            } else {
                return `${linkPrefix}/${itemId}`
            }
        }
    }

    return(
        <div className="grid grid-cols-12 mt-8">
            <div className="col-span-12 justify-center flex">
                <ul className="tour_election_rounds inline-flex rounded-md overflow-hidden p-0.5 bg-gray-700 bg-opacity-10">
                    {props.items?.map(
                        (item, index) => (
                            <li key={index}>
                                <Link to={tabLink(item.id)} className={`${props.electionRound == item.id ? "bg-red-700 text-white rounded" : "bg-transparent text-red-700"} font-bold px-3 py-1 text-sm md:text-base`}>
                                    {item.name}
                                </Link>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </div>
    )
}

export default ElectionRoundsTabs;