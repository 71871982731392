import { useTranslation } from "react-i18next";
import { IoAlertCircleSharp } from "react-icons/io5";
import React, { useEffect, useState, useRef } from "react";
import ElectionComparisonTableCells from "./ElectionComparisonTableCells";
import { usePrevious } from "../../../hooks/usePrevious";
import { useHttp } from "../../../hooks/useHttp";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function ElectionComparisonTableBody(props) {
  const { i18n, t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttp();
  const [electionAreas, setElectionAreas] = useState();
  const firstRender = useRef(true);

  const {
    id,
    items,
    params,
    dataType,
    type,
    year,
    electionRound,
    constituency,
    municipality,
    pollingStation,
    city,
    pageItemsNumber,
    itemsNumber,
    indexFirst,
    indexLast,
    searchParam,
  } = props;

  const electionRoundAreasUrl = `${process.env.REACT_APP_API_URL}/${dataType}/election-round-areas`;

  electionAreas?.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

  const currentItems = electionAreas
    ?.filter((item) =>
      item.name?.toLowerCase().includes(searchParam.toLowerCase())
    )
    .slice(indexFirst, indexLast);

  const prevElectionAreas = usePrevious(electionAreas);

  useEffect(() => {
    const getElectionAreas = async () => {
      const electionAreasData = await sendRequest(
        electionRoundAreasUrl,
        i18n.language,
        params.has("electionType" + id)
          ? params.get("electionType" + id)
          : type,
        params.has("electionYear" + id)
          ? params.get("electionYear" + id)
          : year,
        params.has("electionRound" + id)
          ? params.get("electionRound" + id)
          : electionRound,
        params.has("constituency" + id)
          ? params.get("constituency" + id)
          : constituency,
        params.has("municipality" + id)
          ? params.get("municipality" + id)
          : municipality,
        pollingStation,
        city
      );
      setElectionAreas(electionAreasData);
    };

    getElectionAreas();
    if (firstRender.current) {
      firstRender.current = false;
    }

    return () => {
      clearError();
    };
  }, [
    city,
    clearError,
    constituency,
    electionRound,
    electionRoundAreasUrl,
    i18n.language,
    id,
    municipality,
    params,
    pollingStation,
    sendRequest,
    type,
    year,
  ]);

  useEffect(() => {
    if (!firstRender.current) {
      if (prevElectionAreas === electionAreas) {
        return;
      }
    }
    pageItemsNumber(currentItems);
    itemsNumber(electionAreas);
  }, [
    currentItems,
    electionAreas,
    itemsNumber,
    pageItemsNumber,
    prevElectionAreas,
  ]);

  const content = currentItems?.map((area) => (
    <tr key={area.id} className="divide-x divide-gray-300">
      <td className="font-bold whitespace-nowrap p-4">{area.name}</td>
      {items.map((singleItem, index) => (
        <ElectionComparisonTableCells
          id={index}
          key={index}
          dataType={singleItem.dataType}
          items={items}
          areaId={area.id}
        />
      ))}
    </tr>
  ));
  
    
  if (error) return null;

  return (
    <React.Fragment>
      {!isLoading && !error && (currentItems && currentItems.length > 0) && content}
      {isLoading && !error && (!currentItems || currentItems.length === 0) && (
        <tr className="divide-x divide-gray-300">
          <td colSpan={props.items.length + 1}>
            <AiOutlineLoading3Quarters className="mx-auto my-4 animate-spin w-8 h-8" />
          </td>
        </tr>
      )}
      {/* {here lies error checking logic} */}
      {!isLoading && !error && (!currentItems || currentItems.length === 0) && (
        <tr className="col-span-12 text-center py-8">
          <td colSpan={12}>
            <IoAlertCircleSharp className="scale-[2.5] mx-auto" />
            <p className="mt-4 text-stone-500">{t("no-data-found")}</p>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
export default ElectionComparisonTableBody;
