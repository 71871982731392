import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";
import { useHttp } from "../../hooks/useHttp";

const PollingStationsMap = (props) => {
    const [data, setData] = useState();

    const {isLoading, error, sendRequest,clearError } = useHttp();

    const municipalitiesUrl = `${process.env.REACT_APP_API_URL}/municipality/list`;
    const { i18n } = useTranslation();

    useEffect(() => {
        const fetchMunicipalities = async()=>{
            const municipalityData = await sendRequest(municipalitiesUrl,i18n.language);
            setData(municipalityData);
        }
        fetchMunicipalities();
        return ()=>{
            clearError();
        }
    }, [clearError, i18n.language, municipalitiesUrl, sendRequest]);

    let mapData = [];

    if (data) {
        mapData = [...data];
    }

    mapData?.map((municipality) => {
        return (
            (municipality.path = municipality.border_path),
                (props.municipalityClicked && props.municipalityClicked === municipality.name ? municipality.color = "#B91C1C" : municipality.color = "#e1e1e1")
        );
    });

    const options = {
        chart: {
            type: "map",
            backgroundColor: "transparent",
        },

        title: {
            text: "",
        },

        legend: {
            enabled: false,
        },

        tooltip: {
            useHTML: true,
            whiteSpace: "normal",
            backgroundColor: "white",
            borderRadius: 10,
        },
        series: [
            {
                type: "map",
                name: "North Macedonia",
                joinBy: "id",
                data: mapData,
                cursor: "pointer",
                borderColor: "silver",
                states: {
                    hover: {
                        borderColor: "black",
                        color: "#B91C1C",
                    },
                },
                events: {
                    click: (e) => {
                        localStorage.setItem("municipality", e.target.point.name)
                        props.setMunicipalityClicked(localStorage.getItem("municipality"));
                    },
                },
            },
        ],
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                theme: {
                    fill: "#f1f1f1",
                    "stroke-width": 0,
                    stroke: "silver",
                    r: 8,
                    states: {
                        hover: {
                            fill: "#e5e5e5",
                        },
                        select: {
                            stroke: "#039",
                            fill: "red",
                        },
                    },
                },
                verticalAlign: "left",
            },
        },
        credits: {
            enabled: false,
        },
    };

    const onBackClickHandler = () => {
        mapData = [...data];
        localStorage.removeItem("municipality");
        props.setMunicipalityClicked(null);
    };

    return (
        <React.Fragment>
            {isLoading && !error && !data && (
                <div className="relative col-span-12 h-80 animate-pulse bg-slate-200 md:col-span-8 border overflow-hidden shadow-md rounded-xl mt-8"/>
            )}
            {!isLoading && !error && data && (
                <div className="relative col-span-12 md:col-span-8 border overflow-hidden bg-white shadow-md rounded-xl mt-8">
                    <HighchartsReact
                        options={options}
                        constructorType={"mapChart"}
                        highcharts={Highcharts}
                    />
                    <div className="absolute top-0 right-0">
                        <button
                            onClick={onBackClickHandler}
                            className="bg-gray-200 px-3 py-2"
                        >
                            <FaChevronLeft />
                        </button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default PollingStationsMap;
