import Container from "../../components/Container";
import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../hooks/useHttp";

function SecondaryNavbarTabs(props) {
  const { t, i18n } = useTranslation();

  const { type, year, electionRound } = useParams();

  const {
    error: errorElectionDocumentsUrl,
    isLoading: isLoadingElectionDocumentsUrl,
    sendRequest: sendRequestElectionDocumentsUrl,
    clearError: clearErrorElectionDocumentsUrl,
  } = useHttp();

  const {
    error: errorCandidatesListCheck,
    isLoading: isLoadingCandidatesListCheck,
    sendRequest: sendRequestCandidatesListCheck,
    clearError: clearErrorCandidatesListCheck,
  } = useHttp();

  const [electionDocumentsUrl, setElectionDocumentsUrl] = useState();
  const [totalCandidatesCheck, setTotalCandidatesCheck] = useState();

  const electionDocumentsCheckUrl = `${process.env.REACT_APP_API_URL}/election-documents-url`;
  const candidateListCheckUrl = `${process.env.REACT_APP_API_URL}/results/election-round-list-mandates/list`;

  useEffect(() => {
    const getElectionDocumentsUrl = async () => {
      const electionDocumentsCheckUrlData = await sendRequestElectionDocumentsUrl(
          electionDocumentsCheckUrl,
          i18n.language,
          type,
          year
      );
      setElectionDocumentsUrl(electionDocumentsCheckUrlData.documents_url);
    };

    const getCandidatesListCheck = async () => {
      const candidatesListCheckData = await sendRequestCandidatesListCheck(
        candidateListCheckUrl,
        i18n.language,
        type,
        year,
        electionRound
      );
      setTotalCandidatesCheck(candidatesListCheckData.totalItems);
    };

    getElectionDocumentsUrl();
    getCandidatesListCheck();

    return () => {
      clearErrorElectionDocumentsUrl();
      clearErrorCandidatesListCheck();
    };
  }, [
    electionDocumentsCheckUrl,
    candidateListCheckUrl,
    clearErrorElectionDocumentsUrl,
    clearErrorCandidatesListCheck,
    i18n.language,
    electionRound,
    type,
    year,
    sendRequestElectionDocumentsUrl,
    sendRequestCandidatesListCheck
  ]);

  return (
    !(
      isLoadingCandidatesListCheck &&
      isLoadingElectionDocumentsUrl
    ) && (
      <div className="bg-gray-100">
        <Container>
          <ul className="tour_secondary_navbar_tabs flex justify-around sm:justify-start space-x-4 text-red-700 text-sm leading-4 md:leading-5 md:text-base font-bold">
            <li
              className={`py-2 ${
                props.dataType === "results"
                  ? "border-red-700"
                  : "border-transparent"
              } border-b-2`}
            >
              <Link to={`/election/results/${props.link}`}>{t("results")}</Link>
            </li>
            <li
              className={`py-2 ${
                props.dataType === "turnout"
                  ? "border-red-700"
                  : "border-transparent"
              } border-b-2`}
            >
              <Link to={`/election/turnout/${props.link}`}>{t("turnout")}</Link>
            </li>
            {(electionDocumentsUrl && !errorElectionDocumentsUrl) && (
              <li
                className={`py-2 ${
                  props.dataType === "documents"
                    ? "border-red-700"
                    : "border-transparent"
                } border-b-2`}
              >
                <a
                  href={electionDocumentsUrl}
                  target="_blank"
                >
                  {t("documents")}
                </a>
              </li>
            )}
            {totalCandidatesCheck > 0 && !errorCandidatesListCheck && (
              <li
                className={`py-2 ${
                  props.dataType === "candidates"
                    ? "border-red-700"
                    : "border-transparent"
                } border-b-2`}
              >
                <Link to={`/election/candidates/${props.link}`}>
                  {t("candidates_list")}
                </Link>
              </li>
            )}
          </ul>
        </Container>
      </div>
    )
  );
}

export default SecondaryNavbarTabs;
