import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {FaMinus, FaPlus} from "react-icons/fa";
import ElectionTypeListItem from "./ElectionTypeListItem";

function ElectionLocalTypeItem(props) {
    const [toggleElections, setToggleElections] = useState(true);

    const [listItemHeight, setListItemHeight] = useState(null);
    const listItemRef = useRef(null);
    const localTypes = ["local", "local-council"];
    const { t } = useTranslation();

    useEffect(() => {
        setListItemHeight(listItemRef.current.clientHeight);
    }, [props.elections]);

    const onToggleElectionsButton = () => {
        setToggleElections((prevState) => !prevState);
    };

    const localElections = props.elections?.filter((item) => item.type === localTypes[0]);
    const localCouncilElections = props.elections?.filter((item) => item.type === localTypes[1]);

    function compare(a, b) {
        if (a.year < b.year) {
            return 1;
        }
        if (a.year > b.year) {
            return -1;
        }
        return 0;
    }

    localElections?.sort(compare);
    localCouncilElections?.sort(compare);

    let isEmptyLocal;
    let isEmptyLocalCouncil;
    isEmptyLocal = localElections?.length === 0;
    isEmptyLocalCouncil = localCouncilElections?.length === 0;

    return (
        <div
            className={`tour_election_type_section rounded-xl border bg-gray-50 border-gray-100 overflow-hidden w-full my-8 ${(isEmptyLocal && isEmptyLocalCouncil) && "hidden"}`}
        >
            <button
                className="px-4 md:px-5 flex items-center text-gray-500 justify-between w-full py-3 md:py-4 font-medium"
                onClick={onToggleElectionsButton}
            >
                <span className="font-extrabold text-left xs-text-lg sm:text-xl md:text-2xl mr-4">{t('local')}</span>
                {toggleElections ? (
                    <div className="top-3 left-4">
                        <FaMinus className={"animate-rotateMinus"} />
                    </div>
                ) : (
                    <div className=" top-3 left-4">
                        <FaPlus className={`animate-rotatePlus`} />
                    </div>
                )}
            </button>

            <div
                style={
                    !props.loading
                        ? { height: toggleElections ? listItemHeight : 0 }
                        : { height: listItemHeight }
                }
                className="transition-height px-4 md:px-5 h-fit duration-500"
            >
                <div className="pt-4" ref={listItemRef}>
                    <p className="tour_election_subtype font-bold text-gray-500 text-lg md:text-xl">{t('local-major')}</p>
                    <ul
                        className={`grid gap-4 pt-3 pb-4 md:pb-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3`}
                    >
                        {localElections?.map((item) => {
                            return (
                                <li key={item.id} className={item.year === 2021 ? 'tour_elections_2021' : null}>
                                    <ElectionTypeListItem
                                        type={item.type}
                                        name={item.name}
                                        year={item.year}
                                        dataType="results"
                                        electionRound={
                                            item.election_rounds.length > 0
                                                ? item.election_rounds[0].id
                                                : null
                                        }
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    <p className="font-bold text-gray-500 text-lg md:text-xl">{t('local-council')}</p>
                    <ul
                        className={`grid gap-4 pt-3 pb-4 md:pb-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3`}
                    >
                        {localCouncilElections?.map((item) => {
                            return (
                                <li key={item.id}>
                                    <ElectionTypeListItem
                                        type={item.type}
                                        name={item.name}
                                        year={item.year}
                                        dataType="results"
                                        electionRound={
                                            item.election_rounds.length > 0
                                                ? item.election_rounds[0].id
                                                : null
                                        }
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ElectionLocalTypeItem;
