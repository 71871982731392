import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function ElectoralBodiesFilter(props) {
  const ref = useRef();
  const { t } = useTranslation();
  const [toggleDropdown, setToggleDropdown] = useState(() => false);

  const [listHeight, setListHeight] = useState(null);
  const listRef = useRef(null);

  useLayoutEffect(() => {
    setListHeight(listRef.current.clientHeight);
  }, [toggleDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        setToggleDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="grid grid-cols-12 gap-5">
      <div
        ref={ref}
        className="tour_electoral_bodies_filter_dropdown col-span-12 md:col-span-4 flex items-center relative"
      >
        <button
          onClick={() => {
            setToggleDropdown((prev) => !prev);
          }}
          className="text-gray-700 font-bold shadow-md w-full bg-white hover:bg-gray-50 hover:border-gray-200 focus:ring-4 focus:ring-gray-300 rounded-md px-4 py-2.5 inline-flex items-center justify-between"
        >
          {t("filter_by_mandate")}
          {!toggleDropdown ? <FaChevronDown /> : <FaChevronUp />}
        </button>
        <div
          style={{ height: toggleDropdown ? listHeight : 0 }}
          className={`absolute transition-all duration-100 top-full mt-1 z-10 w-full bg-white rounded-lg shadow-lg ${
            toggleDropdown ? "opacity-100" : "opacity-0"
          }`}
        >
          <ul ref={listRef}>
            {
              props.mandatesList?.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="px-4 py-1 bg-transparent">
                      <div
                        onClick={() => props.setMandate(item.mandate_valid_from)}
                        className="cursor-pointer font-bold text-gray-700 hover:bg-gray-100 hover:rounded-lg pl-2"
                      >
                        {t("mandate")}:{" "}
                        {new Date(item.mandate_valid_from).toLocaleDateString("en-US")} -{" "}
                        {new Date(item.mandate_valid_to).toLocaleDateString("en-US")}
                      </div>
                    </div>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ElectoralBodiesFilter;
