import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./ElectionTypes.css";
import ElectionTypeList from "./ElectionTypeList";
import ElectionLocalTypeList from "./ElectionLocalTypeList";
import Container from "../../components/Container";
import { useHttp } from "../../hooks/useHttp";
import LoaderElectionList from "../../components/loaders/LoaderElectionList";

function ElectionTypes() {
    const { t } = useTranslation();

    const [elections, setElections] = useState();
    const { isLoading, sendRequest, clearError } = useHttp();
    const { i18n } = useTranslation();

    useEffect(() => {
        const getElectionList = async() => {
            const electionListData = await sendRequest(`${process.env.REACT_APP_API_URL}/election`,i18n.language)
            setElections(electionListData)
        };
        getElectionList();
        return ()=>{
            clearError();
        }
    }, [clearError, i18n.language, sendRequest]);

    const types = ["presidential", "parliamentary", "referendum"];

    return (
        <div>
            <Helmet>
                <title>{t("state_election_commission")}</title>
            </Helmet>
            <Container>
                <div className="pt-14">
                    {isLoading ?
                        <LoaderElectionList/>
                        :
                        <ElectionLocalTypeList
                            elections={elections}
                        />
                    }
                </div>
                <div className="pb-14">
                    {types.map((type) => {
                        return (
                            isLoading ?
                                <LoaderElectionList/>
                                :
                                <ElectionTypeList
                                    key={type}
                                    elections={elections}
                                    loading={isLoading}
                                    type={type}

                                />
                        );
                    })}
                </div>
            </Container>
        </div>
    );
}

export default ElectionTypes;
