function SectionTitle(props) {
    return (
        <div className="grid grid-cols-12 gap-5 mt-8 mb-4 md:my-8">
            <div className="col-span-12">
                <p className="tour_results_political_parties text-lg md:text-2xl font-bold text-gray-700">{props.title}</p>
            </div>
        </div>
    )
}

export default SectionTitle;