import {Helmet} from "react-helmet";
import { useParams } from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Charts from "../../components/Charts";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import ExportExcel from "../../components/ExportExcel";
import LoaderButton from "../../components/loaders/LoaderButton";
import LoaderPageTitle from "../../components/loaders/LoaderPageTitle";
import SecondaryNavbarTabs from "../../layouts/navbar/SecondaryNavbarTabs";
import StatisticsSection from "../../components/statistics/StatisticsSection";
import CandidatesListMandates from "../../components/candidatesLists/CandidatesListMandates";
import PoliticalPartiesResultsSection from "../../components/politicalParties/PoliticalPartiesResultsSection";
import { useHttp } from "../../hooks/useHttp";

function ElectionConstituencyMunicipalityPollingStation() {
    let { dataType, type, year, electionRound, constituency, municipality, pollingStation } = useParams();
    const { i18n } = useTranslation();
    const { isLoading, error, sendRequest, clearError } = useHttp();
    const [election, setElection] = useState()
    const [electionAreasConstituencyMunicipalityStatistic, setElectionAreasConstituencyMunicipalityStatistic] = useState()

    const electionUrl = `${process.env.REACT_APP_API_URL}/election`;
    const electionRoundAreasConstituencyMunicipalityStatisticsUrl = `${process.env.REACT_APP_API_URL}/results/election-round-areas`;
    const electionExportUrl = `${process.env.REACT_APP_API_URL}/election-export`;

    useEffect(() => {
        const getElection = async() => {
            const electionData = await sendRequest(electionUrl,i18n.language,
                type,
                year);
            setElection(electionData);
        }
    
        const getElectionAreasConstituencyMunicipalityStatistics = async() => {
            const electionAreasConstituencyMunicipalityStatisticsData = await sendRequest(electionRoundAreasConstituencyMunicipalityStatisticsUrl,i18n.language,
                type,
                year,
                electionRound,
                constituency,
                municipality)
            setElectionAreasConstituencyMunicipalityStatistic(electionAreasConstituencyMunicipalityStatisticsData);
        }

        getElection();
        getElectionAreasConstituencyMunicipalityStatistics();
        return ()=>{
            clearError();
        }

    }, [i18n.language, dataType, type, year, electionRound, constituency, municipality, sendRequest, electionUrl, electionRoundAreasConstituencyMunicipalityStatisticsUrl, clearError])

    if (!election) return null;
    if (!electionAreasConstituencyMunicipalityStatistic) return null;

    const statisticColumnsTitles = [
        "total_registered_voters",
        "total_turnout",
        "total_votes_valid",
        "total_votes_invalid",
        dataType === "turnout" && "total_turnout_percentage"
    ]

    const filteredArea = electionAreasConstituencyMunicipalityStatistic.filter(area => area.id === Number(pollingStation))

    return (
        <div>
            <Helmet>
                <title>{ filteredArea[0] && filteredArea[0].name }</title>
            </Helmet>
            <SecondaryNavbarTabs
                link={`electionType/${type}/electionYear/${year}/electionRound/${electionRound}/constituency/${constituency}/municipality/${municipality}/pollingStation/${pollingStation}`}
                dataType={dataType}
                type={type}
                year={year}
                electionRound={electionRound}
            />
            <Container>
                {isLoading && !error ? <LoaderPageTitle/> :
                    <PageTitle
                        title={filteredArea[0] && filteredArea[0].name}
                        link={`/election/${dataType}/electionType/${type}/electionYear/${year}/electionRound/${electionRound}/constituency/${constituency}/municipality/${municipality}`}
                    />
                }

                <Charts
                    locale={i18n.language}
                    dataType={dataType}
                    type={type}
                    year={year}
                    electionRound={electionRound}
                    constituency={constituency}
                    municipality={municipality}
                    pollingStation={pollingStation}
                />

                {dataType === 'results' &&
                <div className="my-8">
                    {isLoading && !error ?
                        <div className="flex justify-end">
                            <LoaderButton />
                        </div>
                        :
                        <ExportExcel
                            url={electionExportUrl}
                            locale={i18n.language}
                            type={type}
                            year={year}
                            electionRound={electionRound}
                            constituency={constituency}
                            municipality={municipality}
                            pollingStation={pollingStation}
                        />
                    }
                </div>
                }

                {dataType === 'results' &&
                <PoliticalPartiesResultsSection
                    dataType={dataType}
                    locale={i18n.language}
                    type={type}
                    year={year}
                    electionRound={electionRound}
                    constituency={constituency}
                    municipality={municipality}
                    pollingStation={pollingStation}
                />
                }

                {dataType === 'results' &&
                <CandidatesListMandates/>
                }

                {(dataType === 'results' || dataType === 'turnout') &&
                <StatisticsSection
                    dataType={dataType}
                    locale={i18n.language}
                    type={type}
                    year={year}
                    electionRound={electionRound}
                    constituency={constituency}
                    municipality={municipality}
                    pollingStation={pollingStation}
                    titles={statisticColumnsTitles}
                />
                }
            </Container>
        </div>
    )
}

export default ElectionConstituencyMunicipalityPollingStation;